
import client from '../plugins/apiClient'

export default {
  API: {
    Actions: {
      options: () => {
        client.login()
        return client.get('estado-options')
      },
      find: (id) => {
        client.login()
        return client.get('estado/' + id)
      }
    }
  }
}
