import index from './views/index'
import form from './views/form'

export default [
  {
    path: 'contratos',
    name: 'Contratos',
    component: index,
    meta: {
      permissoes: ['Admin'],
      breadcrumbs: [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Base'
          }
        },
        {
          text: 'Contratos de Clientes',
          exact: true,
          to: {
            name: 'Contratos'
          }
        }
      ]
    }
  },
  {
    path: 'contratos/formulario',
    name: 'contratosForm',
    component: form,
    meta: {
      permissoes: ['Admin'],
      breadcrumbs: [{
        text: 'Home',
        exact: true,
        to: {
          name: 'Base'
        }
      },
      {
        text: 'Contratos de Clientes',
        exact: true,
        to: {
          name: 'Contratos'
        }
      },
      {
        text: 'Cadastro de Contratos',
        exact: true,
        to: {
          name: 'contratosForm'
        }
      }
      ]
    }
  },
  {
    path: 'contratos/formulario/:id',
    name: 'contratosFormEdit',
    component: form,
    meta: {
      permissoes: ['Admin'],
      breadcrumbs: [{
        text: 'Home',
        exact: true,
        to: {
          name: 'Base'
        }
      },
      {
        text: 'Contratos de Clientes',
        exact: true,
        to: {
          name: 'Contratos'
        }
      },
      {
        text: 'Edição de Contratos',
        exact: true,
        to: {
          name: 'contratosFormEdit'
        }
      }
      ]
    }
  }
]
