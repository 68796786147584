<template>
  <v-container style="display: grid">
    <!-- Cabeçalho -->
    <v-row>
      <v-col>
        <v-card>
          <v-row>
            <v-col class="pa-4" cols="12">
              <v-text-field
                v-model="filter.buscar"
                append-icon="mdi-magnify"
                label="Buscar"
                hint="Informe algum dos campos: Validade, Contrato, Razão Social, Nome Fantasia, Logradouro, Bairro, Complemento ou Cidade."
                persistent-hint
                class="ml-3"
              >
              </v-text-field>
            </v-col>
            <v-col
              class="px-0 py-0"
              cols="6"
              md="2"
              v-if="$store.getters.userType === 'Admin'"
            >
              <v-autocomplete
                v-model="filter.trade"
                label="Empresa de Trade"
                class="pr-4 pl-4"
                @input="listarResponsaveis(filter.trade)"
                :items="tradeItems"
                outlined
                :loading="loadingTrade"
                :disabled="disabledTrade"
              ></v-autocomplete>
            </v-col>
            <v-col class="px-0 py-0" cols="6" md="2">
              <v-autocomplete
                v-model="filter.marca"
                label="Marca"
                class="pr-4 pl-4"
                :items="marcaItems"
                outlined
                :loading="loadingMarca"
                :disabled="disabledMarca"
              ></v-autocomplete>
            </v-col>
            <v-col class="px-0 py-0" cols="6" md="2">
              <v-autocomplete
                v-model="filter.acaoTradeStatus"
                label="Ação de trade"
                :items="['Ativa', 'Inativa']"
                class="pr-4 pl-4"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col class="px-0 py-0" cols="6" md="2">
              <v-autocomplete
                v-model="filter.mes"
                label="Mês da Visita"
                class="pr-4 pl-4"
                :items="mesesItems"
                outlined
              >
              </v-autocomplete>
            </v-col>
            <v-col class="px-0 py-0" cols="6" md="2">
              <v-autocomplete
                v-model="filter.status"
                label="Status"
                class="pr-4 pl-4"
                :items="statusList"
                outlined
                :loading="loadingStatus"
                :disabled="disabledStatus"
              ></v-autocomplete>
            </v-col>
            <v-col class="px-0 py-0" cols="6" md="2">
              <v-autocomplete
                v-model="filter.disponibilidade"
                label="Disponibilidade"
                class="pr-4 pl-4"
                :items="disponibilidadeList"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col class="px-0 py-0" cols="6" md="2">
              <v-autocomplete
                v-model="filter.analista"
                label="Analista"
                class="pr-4 pl-4"
                :items="analistaFiltroList"
                outlined
                :loading="loadingAnalista"
                :disabled="disabledAnalista"
              ></v-autocomplete>
            </v-col>
            <v-col class="px-0 py-0" cols="12" md="12">
              <v-btn
                v-if="enableNewVisit"
                v-show="(mostrarVisita, filter.acaoTradeStatus == 'Ativa')"
                class="pa-4 mb-4 ml-4"
                color="success"
                @click="abrirIncluirVisita()"
                >Incluir Visita Extra</v-btn
              >
              <v-btn
                class="pa-4 mb-4 ml-4"
                color="warning"
                @click="abrirDescartarSelecionados()"
                v-if="
                  (selecionadosTabela.length != 0 &&
                    (!selecaoProibida || $store.getters.userType == 'Admin')) ||
                  ($store.getters.userType != 'Admin' && todosPendentes)
                "
                >Descartar Selecionados</v-btn
              >
              <!-- </v-col>
            <v-col cols="6" md="3"> -->
              <v-btn
                class="pa-4 mb-4 ml-4"
                color="success"
                @click="abrirAtribuirResponsaveisSelecionados()"
                v-if="selecionadosTabela.length != 0"
                >Definir Analista</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- Conteúdo -->
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            @change="selecaoProibida()"
            :server-items-length="totalRegisters"
            :headers="headers"
            :show-select="filter.acaoTradeStatus == 'Ativa'"
            :items="clientes"
            :options.sync="options"
            :loading="$store.getters.loading"
            v-model="selecionadosTabela"
          >
            <template
              v-if="filter.acaoTradeStatus == 'Ativa'"
              v-slot:[`item.actions`]="{ item }"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <!-- Responsável -->
                  <v-btn
                    @click="selecionarResponsavel(item)"
                    icon
                    v-show="item.status == 'Pendente'"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon> mdi-account-plus </v-icon>
                  </v-btn>
                  <v-btn
                    @click="selecionarResponsavel(item)"
                    icon
                    v-show="
                      item.status == 'Em andamento' || item.status == 'Reaberta'
                    "
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon> mdi-account-plus </v-icon>
                  </v-btn>
                </template>
                <span>Definir Analista</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <!-- Descarte -->
                  <v-btn
                    @click="descarteDialogfuncao(item)"
                    icon
                    v-show="item.status == 'Pendente'"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon> mdi-close-thick </v-icon>
                  </v-btn>
                  <v-btn
                    @click="descarteDialogfuncao(item)"
                    icon
                    v-show="
                      (item.status == 'Em andamento' ||
                        item.status == 'Reaberta') &&
                      $store.getters.userType == 'Admin'
                    "
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon> mdi-close-thick </v-icon>
                  </v-btn>
                </template>
                <span>Descartar ação de trade da marca</span>
              </v-tooltip>
              <!-- Descartar ação de trade da marca -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <!-- Responsável -->
                  <v-btn
                    @click="reativarAcaoDialog(item)"
                    icon
                    v-show="item.status == 'Concluída'"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon> mdi-arrow-u-left-bottom </v-icon>
                  </v-btn>
                </template>
                <span>Reabrir Ação</span>
              </v-tooltip>
              <!-- Reabrir Ação -->
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <!-- Responsável -->
    <v-dialog
      v-model="dialog"
      width="500"
      :retain-focus="false"
      @click:outside="(dialog = false), (responsavel = ''), (dataApp = '')"
      @keydown.esc="(responsavel = ''), (dataApp = '')"
    >
      <v-card>
        <v-card-title> Selecionar Analista </v-card-title>
        <v-autocomplete
          :items="responsavelList"
          v-model="responsavel"
          class="obrigatorio pa-6"
          label="Analista"
          outlined
          :loading="loadingResponsavel"
          :disabled="disabledResponsavel"
        ></v-autocomplete>
        <v-menu
          v-model="menuResponsavel"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateFormatado"
              label="Liberar no App:"
              prepend-icon="mdi-calendar"
              readonly
              @click="menuResponsavel = true"
              v-bind="attrs"
              v-on="on"
              outlined
              class="pl-6 pr-8"
              append-icon="mdi-close"
              @click:append="dataApp = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dataApp"
            @input="menuResponsavel = false"
          ></v-date-picker>
        </v-menu>
        <v-divider></v-divider>
        <v-card-actions class="d-flex flex-column flex-md-row">
          <v-btn
            class="text-md -left"
            color="warning"
            text
            @click="removerResponsavel(), (loadingSelecionarBtn = true)"
            :loading="loadingSelecionarBtn"
          >
            Remover Analista
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="cancelModal(), (responsavel = ''), (dataApp = '')"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            @click="
              enviarResponsavel(), (dataApp = ''), (loadingSelecionarBtn = true)
            "
            :loading="loadingSelecionarBtn"
            :disabled="disabledResponsavelBotao"
          >
            <!-- :disabled="disabledResponsavelBotao" -->
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Descarte único -->
    <v-dialog
      v-model="descarteDialog"
      width="500"
      :retain-focus="false"
      @click:outside="(descarteDialog = false), (descartes.justificativa = '')"
      @keydown.esc="descartes.justificativa = ''"
    >
      <v-card>
        <v-card-title> Motivo do Descarte </v-card-title>
        <v-text-field
          label="Motivo"
          outlined
          class="pa-6"
          v-model="descartes.justificativa"
          @input="tamanhoMinimoDescarte"
        ></v-text-field>
        <v-divider></v-divider>
        <v-card-actions class="d-flex flex-column flex-md-row">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="(descarteDialog = false), (descartes.justificativa = '')"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            @click="descartarAtlac(), (loadingBotaoDescarte = true)"
            :disabled="disabledBotaoDescarte"
            :loading="loadingBotaoDescarte"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Reativar único -->

    <v-dialog
      v-model="reativarDialog"
      width="750"
      :retain-focus="false"
      @click:outside="reativarDialog = false"
    >
      <v-card>
        <v-card-title> Reabertura de Ação de Trade </v-card-title>
        <v-card-text v-if="reativacao.responsavel">
          Deseja reabrir a ação de trade para o cliente
          {{ reativacao.nome_fantasia }}, Marca {{ reativacao.marca }}, Analista
          {{ reativacao.responsavel }}?
        </v-card-text>
        <v-card-text v-else>
          Deseja reabrir a ação de trade para o cliente
          {{ reativacao.nome_fantasia }}, Marca {{ reativacao.marca }}, Sem
          Analista?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex flex-column flex-md-row">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="reativarDialog = false">
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            @click="reativarAcao(reativacao)"
            :loading="loadingBotaoReativar"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Descarte múltiplo -->
    <v-dialog
      v-model="multiDescarteDialog"
      width="500"
      :retain-focus="false"
      @click:outside="
        (multiDescarteDialog = false), (descartes.justificativa = '')
      "
      @keydown.esc="descartes.justificativa = ''"
    >
      <v-card>
        <v-card-title> Motivo do Descarte </v-card-title>
        <v-text-field
          label="Motivo"
          outlined
          class="pa-6"
          v-model="descartes.justificativa"
          @input="tamanhoMinimoDescarte"
        ></v-text-field>
        <v-divider></v-divider>
        <v-card-actions class="d-flex flex-column flex-md-row">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="
              (multiDescarteDialog = false), (descartes.justificativa = '')
            "
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            @click="descartarSelecionados(), (loadingBotaoDescarte = true)"
            :disabled="disabledBotaoDescarte"
            :loading="loadingBotaoDescarte"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Responsável múltiplo -->

    <v-dialog
      v-model="multiResponsavelDialog"
      width="500"
      :retain-focus="false"
      @click:outside="
        (multiResponsavelDialog = false),
          (multiResponsavel = ''),
          (dataApp = '')
      "
      @keydown.esc="(multiResponsavel = ''), (dataApp = '')"
    >
      <v-card>
        <v-card-title> Selecionar Analista </v-card-title>
        <v-autocomplete
          :items="multiResponsavelList"
          v-model="multiResponsavel"
          class="pa-6"
          label="Analista"
          outlined
          :loading="loadingMultiResponsavel"
          @input="disabledMultiResponsavel = false"
        ></v-autocomplete>
        <v-menu
          v-model="menuResponsavelMulti"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateFormatado"
              label="Liberar no App:"
              prepend-icon="mdi-calendar"
              readonly
              @click="menuResponsavelMulti = true"
              v-bind="attrs"
              v-on="on"
              outlined
              clearable
              class="pl-6 pr-6"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dataApp"
            @input="menuResponsavelMulti = false"
          ></v-date-picker>
        </v-menu>
        <v-divider></v-divider>
        <v-card-actions class="d-flex flex-column flex-md-row">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="
              (multiResponsavelDialog = false),
                (multiResponsavel = ''),
                (dataApp = '')
            "
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            @click="
              atribuirResponsaveisSelecionados(),
                (dataApp = ''),
                (loadingSelecionarBtn = true)
            "
            :loading="loadingSelecionarBtn"
            :disabled="disabledMultiResponsavelBotao"
          >
            <!-- :disabled='disabledMultiResponsavelBotao' -->
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Incluir Visita -->

    <v-dialog
      v-model="dialogVisita"
      width="500"
      :retain-focus="false"
      @click:outside="(dialog = false), (responsavel = ''), (dataApp = '')"
      @keydown.esc="(responsavel = ''), (dataApp = '')"
    >
      <v-card>
        <v-card-title> Incluir Visita </v-card-title>
        <v-autocomplete
          v-if="this.$store.getters.userType == 'Admin'"
          :items="visitaItems.trade"
          v-model="visitaSelecao.trade"
          @change="listarContratos(), listarAnalistas()"
          class="obrigatorio px-6"
          label="Empresa de trade"
          outlined
          :loading="visitaLoading.trade"
          :disabled="visitaDisabled.trade"
        ></v-autocomplete>

        <v-autocomplete
          :items="visitaItems.contrato"
          v-model="visitaSelecao.contrato"
          @change="listarClientes(), listarMarcasAcao()"
          class="obrigatorio px-6"
          label="Contrato"
          outlined
          :loading="visitaLoading.contrato"
          :disabled="visitaDisabled.contrato"
        ></v-autocomplete>

        <v-autocomplete
          :items="visitaItems.mes"
          v-model="visitaSelecao.mes"
          class="obrigatorio px-6"
          label="Mês"
          outlined
        ></v-autocomplete>

        <v-autocomplete
          :items="visitaItems.razao"
          v-model="visitaSelecao.razao"
          class="obrigatorio px-6"
          label="Razão Social"
          outlined
          :loading="visitaLoading.razao"
          :disabled="visitaDisabled.razao"
        ></v-autocomplete>

        <v-autocomplete
          :items="visitaItems.marca"
          v-model="visitaSelecao.marca"
          class="obrigatorio px-6"
          label="Marca"
          multiple
          chips
          small-chips
          outlined
          :loading="visitaLoading.marca"
          :disabled="visitaDisabled.marca"
        ></v-autocomplete>

        <v-autocomplete
          :items="visitaItems.analista"
          v-model="visitaSelecao.analista"
          class="px-6"
          label="Analista"
          outlined
          :loading="visitaLoading.analista"
          :disabled="visitaDisabled.analista"
          @input="disabledMultiResponsavel = false"
        ></v-autocomplete>

        <v-menu
          v-model="menuResponsavel"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateFormatadoVisitas"
              label="Liberar no App:"
              prepend-icon="mdi-calendar"
              readonly
              @click="menuResponsavel = true"
              v-bind="attrs"
              v-on="on"
              outlined
              class="pl-6 pr-8"
              append-icon="mdi-close"
              @click:append="visitaSelecao.liberar = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="visitaSelecao.liberar"
            @input="menuResponsavel = false"
          ></v-date-picker>
        </v-menu>

        <v-divider></v-divider>
        <v-card-actions class="d-flex flex-column flex-md-row">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialogVisita = false">
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            @click="submitVisita(), (dataApp = '')"
            :loading="visitaLoading.botao"
          >
            <!-- :disabled="disabledResponsavelBotao" -->
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style>
.dialog-responsavel {
  width: 50%;
}
</style>
<script>
import clientesService from "../../../services/clientesService";
import relatorioService from "../../../services/relatorioService";
import contratoService from "../../../services/contratoService";
import acoesService from "../../../services/acoesService";
import empresaService from "../../../services/empresaService";
import moment from "moment";

export default {
  data: () => ({
    filter: {
      buscar: null,
      marca: "Todos",
      status: "Todos",
      acaoTradeStatus: "Ativa",
      trade: "Todos",
      disponibilidade: "Todos",
      analista: "Todos",
      mes: "Todos",
    },
    descartes: {
      acoes_trade_logins_app_clientes_id: "",
      justificativa: "",
      geolocalizacao: "",
    },
    mostrarVisita: true,
    selecaoTemp: [],
    selecaoProibida: false,
    todosPendentes: false,
    multiResponsavel: "",
    multiEmpresaSelecionada: "",
    multiResponsavelList: [],
    dialogVisita: false,
    descarteDialog: false,
    multiDescarteDialog: false,
    multiResponsavelDialog: false,
    reativarDialog: false,
    totalRegisters: 0,
    clientes: [],
    sortBy: "validade_final",
    disponibilidadeList: ["Todos", "Sem Analista", "Com Analista"],
    mesesItems: [
      "Todos",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ],
    statusList: ["Todos"],
    marcaItems: ["Todos"],
    tradeItems: ["Todos"],
    analistaFiltroList: ["Todos"],
    visitaItems: {
      trade: [],
      contrato: [],
      mes: [],
      razao: [],
      marca: [],
      analista: [],
    },
    visitaSelecao: {
      trade: "",
      contrato: "",
      mes: "",
      razao: "",
      marca: "",
      analista: "",
      liberar: "",
    },
    visitaLoading: {
      trade: true,
      contrato: false,
      razao: false,
      marca: false,
      analista: false,
      botao: false,
    },
    visitaDisabled: {
      trade: true,
      contrato: true,
      mes: true,
      razao: true,
      marca: true,
      analista: true,
    },
    dialog: false,
    service: "",
    acao: null,
    responsavel: "",
    responsavelList: [],
    loadingTrade: true,
    disabledTrade: true,
    loadingMarca: true,
    disabledMarca: true,
    loadingStatus: true,
    disabledStatus: true,
    loadingResponsavel: true,
    disabledResponsavel: true,
    loadingMultiResponsavel: true,
    disabledMultiResponsavel: true,
    loadingAnalista: true,
    disabledAnalista: true,
    loadingSelecionarBtn: false,
    selecionadosTabela: [],
    disabledBotaoDescarte: true,
    loadingBotaoDescarte: false,
    loadingBotaoReativar: false,
    dataApp: "",
    menuResponsavel: false,
    menuResponsavelMulti: false,
    options: {
      itemsPerPage: 10,
    },
    /* É importante lembrar que o headers pode mudar no mounted, pois depende da permissão do usuário */
    headers: [
      {
        text: "Fim da Validade/Contrato",
        align: "start",
        sortable: false,
        value: "validade_final",
      },
      {
        text: "Razão Social",
        align: "start",
        sortable: false,
        value: "cliente",
      },
      {
        text: "Nome Fantasia",
        align: "start",
        sortable: false,
        value: "nome_fantasia",
      },
      {
        text: "Endereço",
        align: "start",
        sortable: false,
        value: "endereco",
      },
      {
        text: "Marca",
        align: "start",
        sortable: false,
        value: "marca",
      },
      {
        text: "Mês da Visita",
        align: "end",
        sortable: false,
        value: "mesDataF",
      },
      {
        text: "Status",
        align: "start",
        sortable: false,
        value: "status",
      },
      {
        text: "Analista",
        align: "start",
        sortable: false,
        value: "responsavel",
      },
      {
        text: "Lib no App",
        align: "start",
        sortable: false,
        value: "liberar_data",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
      },
    ],
    reativacao: {},
  }),
  watch: {
    selecionadosTabela: {
      handler() {
        this.selecaoTemp = [];
        this.selecionadosTabela.forEach((element) => {
          //['Pendente', 'Em Andamento', 'Reaberta'].includes(element?.status)
          if (
            element?.status == "Pendente" ||
            element?.status == "Em andamento" ||
            element?.status == "Reaberta"
          ) {
            this.selecaoTemp.push(element.status);
          }
        });

        var unique = [...new Set(this.selecaoTemp)];

        if (unique.length == 2) {
          this.selecaoProibida = true;
        } else {
          this.selecaoProibida = false;
        }

        if (unique.length == 1 && unique[0] == "Pendente") {
          this.todosPendentes = true;
        } else {
          this.todosPendentes = false;
        }

        if (this.selecionadosTabela.length > 0) {
          this.mostrarVisita = false;
        } else {
          this.mostrarVisita = true;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        this.listagem();
      },
      deep: true,
    },
    filter: {
      handler() {
        this.options.page = 1;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.listagem();
        }, 600);
      },
      deep: true,
    },
    dialogVisita: {
      handler(newVal) {
        if (!newVal) {
          this.visitaSelecao = {
            trade: "",
            contrato: "",
            mes: "",
            razao: "",
            marca: "",
            analista: "",
            liberar: "",
          };
        }
      },
    },
  },
  methods: {
    selecionarResponsavel(acao) {
      this.acao = acao;
      const diff = moment()
        .startOf("month")
        .diff(moment(acao.mes_data).startOf("month"));

      if (diff > 0) {
        this.$store.dispatch(
          "error",
          "Não é permitido definir Analista para mês da visita já finalizado."
        );
        return false;
      }
      this.responsavelList = [];
      if (this.acao.liberar_data != null) {
        this.dataApp =
          this.acao.liberar_data.split("/")[2] +
          "-" +
          this.acao.liberar_data.split("/")[1] +
          "-" +
          this.acao.liberar_data.split("/")[0];
      } else {
        this.dataApp = this.acao.liberar_data;
      }
      clientesService.API.Actions.request(acao.empresa_id)
        .then((response) => {
          this.$store.dispatch("stop_load");
          response.data.forEach((element) => {
            this.responsavelList.push(element);
            this.visitaItems.analista.push(element);
            this.visitaLoading.analista = false;
            if (this.acao.responsavel) {
              if (element.text == this.acao.responsavel) {
                this.responsavel = element.value;
              }
            }
          });

          this.loadingResponsavel = false;
          this.disabledResponsavel = false;
        })
        .catch((error) => {
          this.$store.dispatch("stop_load");
          const errors = error;
          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
      this.dialog = true;
    },

    listarResponsaveis(trade) {
      this.filter.analista = "Todos";
      this.analistaFiltroList = ["Todos"];
      clientesService.API.Actions.analista_options(trade)
        .then((response) => {
          response.data.forEach((element) => {
            this.analistaFiltroList.push(element);
          });
          this.loadingAnalista = false;
          this.disabledAnalista = false;
        })
        .catch((error) => {
          this.loadingAnalista = false;
          this.disabledAnalista = false;
          const errors = error;
          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
    },

    removerResponsavel() {
      this.responsavel = "";
      this.listagem();
      const form = {
        acao_id: this.acao.id,
        responsavel_id: this.responsavel,
      };
      clientesService.API.Actions.update(form)
        .then((response) => {
          this.dialog = false;
          this.$store.dispatch("stop_submit");
          this.$store.dispatch("success", response.data.message);
          this.$store.dispatch("start_load");
          this.listagem();
          this.listarResponsaveis(this.filter.trade);
          this.loadingSelecionarBtn = false;
        })
        .catch((error) => {
          this.loadingSelecionarBtn = false;
          this.$store.dispatch("stop_submit");
          this.$store.dispatch("stop_load");
          const errors = error.response.data.errors;
          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
    },
    descartarAtlac() {
      clientesService.API.Actions.descarte(this.descartes)
        .then((response) => {
          this.$store.dispatch("success", response.data.message);
          this.descarteDialog = false;
          this.descartes = {
            acoes_trade_logins_app_clientes_id: "",
            justificativa: "",
            geolocalizacao: "",
          };
          this.listagem();
          this.disabledBotaoDescarte = true;
          this.loadingBotaoDescarte = false;
        })
        .catch((error) => {
          this.$store.dispatch("stop_submit");
          this.$store.dispatch("stop_load");
          this.disabledBotaoDescarte = true;
          this.loadingBotaoDescarte = false;
          const errors = error;

          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
    },
    abrirDescartarSelecionados() {
      let possuiConcluida = false;
      this.selecionadosTabela.forEach((element) => {
        if (element.status == "Concluída") {
          possuiConcluida = true;
        }
      });
      if (possuiConcluida) {
        this.$store.dispatch(
          "error",
          "Não é possível descartar clientes concluídos"
        );
      } else {
        this.multiDescarteDialog = true;
      }
    },
    descartarSelecionados() {
      let multiplosDescartes = [];
      this.selecionadosTabela.forEach((element) => {
        let descarte = {
          acoes_trade_logins_app_clientes_id: element.id,
          geolocalizacao: "",
          justificativa: this.descartes.justificativa,
        };
        multiplosDescartes.push(descarte);
        this.multiDescarteDialog = false;
      });
      clientesService.API.Actions.descarteMultiplo(multiplosDescartes)
        .then((response) => {
          this.$store.dispatch("success", response.data.message);
          this.descartes = {
            acoes_trade_logins_app_clientes_id: "",
            justificativa: "",
            geolocalizacao: "",
          };
          this.listagem();
          this.selecionadosTabela = [];
          this.disabledBotaoDescarte = true;
          this.loadingBotaoDescarte = false;
        })
        .catch((error) => {
          this.disabledBotaoDescarte = true;
          this.loadingBotaoDescarte = false;
          this.$store.dispatch("stop_submit");
          this.$store.dispatch("stop_load");
          const errors = error.response.data.error;
          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
    },
    abrirAtribuirResponsaveisSelecionados() {
      let possuiConcluida = false;
      let empresasSelecionadas = [];
      let responsavelUnico = "";
      let possuiMesPassado = false;
      this.selecionadosTabela.forEach((element) => {
        if (this.selecionadosTabela.length == 1) {
          responsavelUnico = element.responsavel;
        }
        if (element.status == "Concluída") {
          possuiConcluida = true;
        }
        const diff = moment()
          .startOf("month")
          .diff(moment(element.mes_data).startOf("month"));

        if (diff > 0) {
          possuiMesPassado = true;
        }
        empresasSelecionadas.push(element.empresa_id);
      });
      let uniqueEmpresasSelecionadas = [...new Set(empresasSelecionadas)];
      if (possuiConcluida) {
        this.$store.dispatch(
          "error",
          "Não é possível editar clientes concluídos"
        );
      } else if (possuiMesPassado) {
        this.$store.dispatch(
          "error",
          "Não é permitido definir Analista para mês da visita já finalizado."
        );
        return false;
      } else {
        if (uniqueEmpresasSelecionadas.length > 1) {
          this.$store.dispatch(
            "error",
            "Não é possível editar o analista de mais de uma empresa de trade"
          );
        } else {
          this.multiResponsavelList = [];
          this.multiEmpresaSelecionada = uniqueEmpresasSelecionadas[0];
          this.loadingMultiResponsavel = true;
          clientesService.API.Actions.request(this.multiEmpresaSelecionada)
            .then((response) => {
              response.data.forEach((element) => {
                this.multiResponsavelList.push(element);
                if (
                  this.selecionadosTabela.length == 1 &&
                  element.text == responsavelUnico
                ) {
                  this.multiResponsavel = element.value;
                }
              });
            }, (this.loadingMultiResponsavel = false))
            .catch((error) => {
              this.loadingMultiResponsavel = false;
              const errors = error;
              if (typeof errors === "string") {
                this.$store.dispatch("error", errors);
              } else {
                for (const index in errors) {
                  this.$store.dispatch("error", errors[index][0]);
                }
              }
            });
          this.multiResponsavelDialog = true;
          this.dataApp = "";
        }
      }
    },
    atribuirResponsaveisSelecionados() {
      // multiResponsavel
      // idzao

      let multiplosResponsaveis = [];
      this.selecionadosTabela.forEach((element) => {
        let responsaveis = {
          acoes_trade_logins_app_clientes_id: element.id,
          responsavel_id: this.multiResponsavel,
          empresa_id: element.empresa_id,
          data_app: this.dataApp,
        };
        multiplosResponsaveis.push(responsaveis);
        this.multiResponsavelDialog = false;
      });
      clientesService.API.Actions.responsavelMultiplo(multiplosResponsaveis)
        .then((response) => {
          this.$store.dispatch("success", response.data.message);
          this.listagem();
          this.listarResponsaveis(this.filter.trade);
          this.loadingSelecionarBtn = false;
        })
        .catch((error) => {
          this.loadingSelecionarBtn = false;
          this.$store.dispatch("stop_submit");
          this.$store.dispatch("stop_load");
          const errors = error.response.data.error;

          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
    },
    descarteDialogfuncao(item) {
      this.descarteDialog = true;
      this.descartes.acoes_trade_logins_app_clientes_id = item.id;
      this.descartes.geolocalizacao = "";
    },

    cancelModal() {
      this.acao = null;
      this.responsavel = "";
      this.dialog = false;
    },

    enviarResponsavel() {
      const form = {
        acao_id: this.acao.id,
        responsavel_id: this.responsavel,
        data_app: this.dataApp,
      };
      clientesService.API.Actions.update(form)
        .then((response) => {
          this.dialog = false;
          this.$store.dispatch("stop_submit");
          this.$store.dispatch("success", response.data.message);
          this.$store.dispatch("start_load");
          this.listagem();
          this.listarResponsaveis(this.filter.trade);
          this.loadingSelecionarBtn = false;
        })
        .catch((error) => {
          this.loadingSelecionarBtn = false;
          this.$store.dispatch("stop_submit");
          this.$store.dispatch("stop_load");
          const errors = error.response.data.error;
          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
    },
    listagem() {
      this.$store.dispatch("start_load");
      this.selecionadosTabela = [];
      clientesService.API.Actions.list(this.options, this.filter)
        .then((response) => {
          this.$store.dispatch("stop_load");
          this.clientes = response.data.data;

          this.clientes.forEach((element, key) => {
            if (this.clientes[key].liberar_data) {
              this.clientes[key].liberar_data = moment(
                this.clientes[key].liberar_data
              ).format("DD/MM/YYYY");
            }

            element.cliente = `${element.cliente_id} - ${element.cliente}`;
            if (
              this.$store.getters.userLogin.substr(0, 2).toLowerCase() === "kw"
            ) {
              element.cliente += ` [${element.id}]`;
            }

            /* ADD MES_DATA */
            const mes_data = moment(element.mes_data);
            this.clientes[key].mesDataF =
              mes_data.format("MM") + " - " + element.visitas;

            this.clientes[key].validade_final =
              element.validade_final + " - " + element.numero_contrato;

            if (element.complemento.replace(/\s+/g, "") == "") {
              this.clientes[key].endereco =
                element.logradouro +
                " - " +
                element.numero +
                " - " +
                element.bairro +
                " - " +
                element.cidade +
                " - " +
                element.estado;
            } else {
              this.clientes[key].endereco =
                element.logradouro +
                " - " +
                element.numero +
                " - " +
                element.complemento +
                " - " +
                element.bairro +
                " - " +
                element.cidade +
                " - " +
                element.estado;
            }
          });
          this.totalRegisters = response.data.total;
        })
        .catch((error) => {
          this.$store.dispatch("stop_load");
          const errors = error;

          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
    },
    async listarEmpresas() {
      await empresaService.API.Actions.options()
        .then((response) => {
          response.data.forEach((element) => {
            this.tradeItems.push(element);
            this.visitaItems.trade.push(element);
          });
        })
        .finally(() => {
          this.visitaLoading.trade = false;
          this.visitaDisabled.trade = false;
          this.loadingTrade = false;
          this.disabledTrade = false;
        });
    },
    async listarMarcas() {
      await acoesService.API.Actions.marcas().then((response) => {
        response.data.marcas.forEach((element) => {
          this.marcaItems.push(element);
          // this.visitaItems.marca.push(element)
          // this.visitaLoading.marca = false
        });
        this.loadingMarca = false;
        this.disabledMarca = false;
      });
    },
    async listarMarcasAcao() {
      this.visitaItems.marca = [];
      this.visitaSelecao.marca = [];
      this.visitaLoading.marca = true;
      this.visitaDisabled.marca = true;
      await acoesService.API.Actions.marcasAcao(
        this.visitaSelecao.contrato
      ).then((response) => {
        response.data.marcas.forEach((element) => {
          this.visitaItems.marca.push(element);
          this.visitaLoading.marca = false;
          this.visitaDisabled.marca = false;
        });
      });
    },
    async listarStatus() {
      await clientesService.API.Actions.status().then((response) => {
        response.data.status.forEach((element) => {
          this.statusList.push(element);
        });
        this.loadingStatus = false;
        this.disabledStatus = false;
      });
    },
    reativarAcaoDialog(item) {
      this.reativacao = item;
      this.reativarDialog = true;
    },
    async reativarAcao() {
      this.loadingBotaoReativar = true;
      await clientesService.API.Actions.reabrir(this.reativacao)
        .then(() => {
          this.$store.dispatch("success", response.data.message);
        })
        .catch((error) => {
          this.$store.dispatch("stop_load");
          const errors = error;
          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        })
        .finally(() => {
          this.listagem();
          this.loadingBotaoReativar = false;
          this.reativarDialog = false;
        });
    },
    abrirIncluirVisita() {
      this.dialogVisita = true;
      if (!this.visitaItems.mes.length) {
        /* Subtração de mês para simplificar o retorno do array de meses com menos código */
        const now = moment().subtract(1, "month");
        this.visitaItems.mes = [...Array(12).keys()].map(() =>
          now.add(1, "month").format("MM/YYYY")
        );
      }
    },
    listarClientes() {
      this.visitaLoading.razao = true;
      this.visitaDisabled.razao = true;
      this.visitaItems.razao = [];
      relatorioService.API.Actions.getClientes(this.visitaSelecao.contrato)
        .then((response) => {
          response.data.clientes.forEach((element) => {
            this.visitaItems.razao.push(element);
          });
        })
        .finally(() => {
          this.visitaLoading.razao = false;
          this.visitaDisabled.razao = false;
        });
    },
    listarContratos() {
      this.visitaItems.marca = [];
      this.visitaSelecao.marca = [];
      this.visitaDisabled.marca = true;
      this.visitaLoading.contrato = true;
      this.visitaDisabled.contrato = true;
      this.visitaItems.contrato = [];
      contratoService.API.Actions.options(this.visitaSelecao.trade)
        .then((response) => {
          response.data.contratos.forEach((element) => {
            let contrato = { value: element.value, text: element.text };
            this.visitaItems.contrato.push(contrato);
          });
        })
        .finally(() => {
          this.visitaLoading.contrato = false;
          this.visitaDisabled.contrato = false;
        });
    },
    listarAnalistas() {
      this.visitaLoading.analista = true;
      this.visitaDisabled.analista = true;
      this.visitaItems.analista = [];
      clientesService.API.Actions.analista_options(this.visitaSelecao.trade)
        .then((response) => {
          response.data.forEach((element) => {
            this.visitaItems.analista.push(element);
          });
        })
        .finally(() => {
          this.visitaLoading.analista = false;
          this.visitaDisabled.analista = false;
        });
    },
    submitVisita() {
      this.visitaLoading.botao = true;
      if (
        !this.visitaSelecao.trade ||
        !this.visitaSelecao.contrato ||
        !this.visitaSelecao.mes ||
        !this.visitaSelecao.razao ||
        !this.visitaSelecao.marca
      ) {
        this.$store.dispatch(
          "error",
          "Por favor preencher os campos indicados"
        );
      }
      clientesService.API.Actions.visitas(this.visitaSelecao)
        .then((response) => {
          this.$store.dispatch("success", response.data.message);
          this.dialogVisita = false;
        })
        .catch((error) => {
          let errorMessage = "";
          if (error.response?.data.message) {
            errorMessage = error.response.data.message + "\n";
            if (error.response.data?.errors) {
              errorMessage += Object.values(error.response.data.errors)
                .map((v) => `- ${v}`)
                .join("\n");
            }
          }
          this.$store.dispatch("error", errorMessage);
          this.$store.dispatch("stop_submit");
        })
        .finally(() => {
          this.visitaLoading.botao = false;

          Object.keys(this.visitaSelecao).forEach(
            (i) => (this.visitaSelecao[i] = "")
          );

          this.listagem();
        });
    },
    mesAtual() {
      const d = new Date();
      const mesAtual = d.getMonth() + 1;
      this.filter.mes = mesAtual.toString();
    },
  },

  async mounted() {
    this.mesAtual();
    this.listagem();
    this.listarResponsaveis(this.filter.trade);
    await this.listarMarcas();
    await this.listarStatus();
    if (this.$store.getters.userType === "Admin") {
      await this.listarEmpresas();
      /* É importante lembrar que tem o headers no data */
      this.headers = [
        {
          text: "Validade/Contrato",
          align: "start",
          sortable: false,
          value: "validade_final",
        },
        {
          text: "Empresa de trade",
          align: "start",
          sortable: false,
          value: "empresa",
        },
        {
          text: "Razão Social",
          align: "start",
          sortable: false,
          value: "cliente",
        },
        {
          text: "Nome Fantasia",
          align: "start",
          sortable: false,
          value: "nome_fantasia",
        },
        {
          text: "Endereço",
          align: "start",
          sortable: false,
          value: "endereco",
        },
        {
          text: "Marca",
          align: "start",
          sortable: false,
          value: "marca",
        },
        {
          text: "Mês da Visita",
          align: "end",
          sortable: false,
          value: "mesDataF",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
        },
        {
          text: "Analista",
          align: "start",
          sortable: false,
          value: "responsavel",
        },
        {
          text: "Lib no App",
          align: "start",
          sortable: false,
          value: "liberar_data",
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "start",
        },
      ];
    } else {
      this.visitaSelecao.trade = this.$store.getters.userEmpresaId;
      this.listarContratos();
      this.listarAnalistas();
      // this.listarClientes()
      // this.listarMarcasAcao()
    }
  },

  computed: {
    enableNewVisit() {
      return this.$store.getters.isAdmin;
    },
    gatilhoBusca() {
      if (this.buscar.length >= 3) {
        return this.buscar;
      }
      return "";
    },
    tamanhoMinimoDescarte() {
      if (this.descartes.justificativa.length >= 5) {
        this.disabledBotaoDescarte = false;
      } else {
        this.disabledBotaoDescarte = true;
      }
    },
    dateFormatadoVisitas() {
      return this.visitaSelecao.liberar
        ? moment(this.visitaSelecao.liberar).format("DD/MM/YYYY")
        : "";
    },
    dateFormatado() {
      return this.dataApp ? moment(this.dataApp).format("DD/MM/YYYY") : "";
    },
    disabledResponsavelBotao() {
      if (this.responsavel.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    disabledMultiResponsavelBotao() {
      if (this.multiResponsavel.length == 0) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style>
.v-card
  > :last-child:not(.v-btn):not(.v-chip):not(.v-avatar):not(.v-window__next) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
