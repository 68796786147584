<template>
  <v-container>
    <v-card>
      <v-form @submit.prevent="submit" class="pa-4">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="descricao" label="Descrição" required outlined autofocus
              class="obrigatorio" @input="$v.descricao.$touch()"
              :error-messages="descricaoErrors" :loading="$store.getters.loading"
              ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <p>Foto antes?</p>
            <v-radio-group v-model="fotoAntes" row>
              <v-radio label="Não" :value='false'></v-radio>
              <v-radio label="Sim" :value='true'></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="6">
            <p>Foto depois?</p>
            <v-radio-group v-model="fotoDepois" row>
              <v-radio label="Não" :value='false'></v-radio>
              <v-radio label="Sim" :value='true'></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="6">
            <p>Aceita Comentários?</p>
            <v-radio-group v-model="comentario" row>
              <v-radio label="Não" :value='false'></v-radio>
              <v-radio label="Sim" :value='true'></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-col md=12 col=12>
          <small>
            <span class="legenda">
              Campo Obrigatório
            </span>
          </small>
          </v-col>
          <v-col md=12>
            <v-divider></v-divider>
          </v-col>
         <v-row>
          <v-col cols="2" md="6" class="d-none d-sm-flex">
            <router-link to=/tarefas> <v-btn color='secondary' outlined>
              Voltar
              </v-btn>
            </router-link>
          </v-col>
          <v-dialog v-model="dialog" content-class="modal-delete">
            <v-card>
              <v-card-title>
                Excluir o registro?
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="blue-grey lighten-5">
                A exclusão desse registro é permanente e sem retorno.
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="dialog = false">
                  Não
                </v-btn>
                <v-btn color="error" @click="excluir">
                  Sim
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-col class="text-right" cols="10" md="6">
            <v-btn @click="limpar" class="mr-4" v-if='this.$route.params.id == undefined'>
              Limpar
            </v-btn>
            <v-btn @click="dialog = true" class="mr-4" color="error" :loading="$store.getters.submitted" v-if='this.$route.params.id != undefined' outlined>
              Excluir
            </v-btn>
            <v-btn color="primary" type="button" @click="submit" :loading="$store.getters.submitted"
              :disabled="$v.$invalid">
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<style scoped>
  .obrigatorio label, p::after {
    content: "*";
    color: red
  }
</style>
<script>
import tarefaService from '../../../services/tarefaService'
import {
  validationMixin
} from 'vuelidate'
import {
  required,
  requiredIf
} from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],

  data: () => ({
    name: 'dakota-tarefa-form',
    descricao: '',
    fotoAntes: false,
    fotoDepois: false,
    dialog: false,
    comentario: false
  }),

  validations () {
    return {
      descricao: {
        required
      },
      foto: {
        valid: requiredIf(function () {
          return !this.fotoAntes && !this.fotoDepois
        })
      }

    }
  },

  computed: {
    descricaoErrors () {
      const errors = []
      if (!this.$v.descricao.$dirty) {
        return errors
      } else {
        !this.$v.descricao.required && errors.push('É necessário preencher a descrição.')
        return errors
      }
    }
  },

  mounted () {
    const routeId = this.$route.params.id
    if (routeId !== undefined) {
      this.$store.dispatch('start_load')
      tarefaService.API.Actions.find(routeId).then((response) => {
        const dados = response.data
        this.descricao = dados.descricao
        this.fotoAntes = dados.foto_antes
        this.fotoDepois = dados.foto_depois
        this.comentario = dados.comentario
        this.$store.dispatch('stop_load')
      })
    }
  },

  methods: {
    submit () {
      this.$v.$touch()
      this.$store.dispatch('start_submit')

      const tarefa = {
        descricao: this.descricao,
        foto_antes: this.fotoAntes,
        foto_depois: this.fotoDepois,
        comentario: this.comentario
      }

      const routeId = this.$route.params.id

      if (routeId === undefined) {
        tarefaService.API.Actions.create(tarefa)
          .then((response) => {
            this.$store.dispatch('stop_submit')
            this.$store.dispatch('success', response.data.message)
            this.$router.push('/tarefas')
          }).catch((errors) => {
            this.$store.dispatch('stop_submit')
              if (typeof (errors) === 'string') {
              this.$store.dispatch('error', errors)
            } else {
              for (const index in errors) {
                this.$store.dispatch('error', errors[index][0])
              }
            }
          })
      } else {
        tarefaService.API.Actions.update(routeId, tarefa)
          .then((response) => {
            this.$store.dispatch('stop_submit')
            this.$store.dispatch('success', response.data.message)
            this.$router.push('/tarefas')
          }).catch((error) => {
            const errors = error.response.data.errors;

              this.$store.dispatch('stop_submit')
              if (typeof (errors) === 'string') {
              this.$store.dispatch('error', errors)
            } else {
              for (const index in errors) {
                this.$store.dispatch('error', errors[index][0])
              }
            }
          })
      }
    },

    limpar () {
      this.$v.$reset()
      this.descricao = ''
    },

    excluir () {
      const routeId = this.$route.params.id
      this.$store.dispatch('start_submit')

      tarefaService.API.Actions.delete(routeId)
        .then((response) => {
          if (response.data.errors) {
            this.$store.dispatch('stop_submit')
            this.$store.dispatch('Erro', response.data.message)
          } else {
            this.$store.dispatch('stop_submit')
            this.$store.dispatch('Sucesso', response.data.message)
          }

          this.dialog = false
          this.$router.push('/tarefas')
        }).catch((error) => {
          const errorArray = error.response.data.errors
          this.$store.dispatch('stop_submit')
          if (typeof (errorArray) === 'string') {
            this.$store.dispatch('error', errorArray)
          }
          if (errorArray instanceof Array) {
            for (const error in errorArray) {
              this.$store.dispatch('error', errorArray[error][0])
            }
          }
          this.dialog = false
        })
    }
  }
}
</script>
