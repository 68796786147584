import client from '../plugins/apiClient'

export default {
  API: {
    Actions: {
      login: (user, password) => {
        return client.post('oauth/token', {
          username: user,
          password: password,
          grant_type: 'password',
          client_id: process.env.VUE_APP_CLIENT_ID,
          client_secret: process.env.VUE_APP_CLIENT_SECRET,
          URL: process.env.VUE_APP_BASE_API,
          scope: ''
        })
      },
      getUser: () => {
        client.login()
        return client.get('usuario-permissao')
      }
    }
  }
}
