<template>
  <v-container style="display: grid">
    <v-row>
      <v-col>
        <v-card>
          <v-row class="pl-4 pr-4 pt-4">
            <v-col
              cols="12"
              md="4"
              sm="6"
              v-if="permissaoParaAlterarEmpresa"
            >
              <v-autocomplete
                v-model="filter.trade"
                label="Empresa de Trade"
                @input="listarContratos()"
                :items="tradeItems"
                outlined
                :loading="loading.trade"
                :disabled="disableElm(['trade'])"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                v-model="filter.contrato"
                label="Contrato"
                @input="listarMarcas(), listarVisitas()"
                :items="contratoItems"
                outlined
                :loading="loading.contrato"
                :disabled="disableElm(['contrato'])"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                v-model="filter.visitas"
                label="Visitas"
                :items="visitasItems"
                outlined
                chips
                clearable
                deletable-chips
                multiple
                :loading="loading.visitas"
                :disabled="disableElm(['visitas'])"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                v-model="filter.marca"
                label="Marca"
                :items="marcaItems"
                outlined
                :loading="loading.marca"
                @input="(disabled.botao = false), (disabled.pdf = false)"
                :disabled="disableElm(['marca'])"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions class="pa-4 justify-end flex-column align-end">
            <div>
              <v-btn
                @click="popularTabela()"
                :disabled="disableElm(['botao'])"
                color="primary"
                :loading="loading.botao"
              >
                Gerar Relatório
              </v-btn>
            </div>
            <v-divider
              v-if="checkboxesMeses.items.length"
              inset
              class="my-3"
              style="max-width: 100%; width: 100%; height: 5px !important"
            ></v-divider>
            <div
              class="mb-n6 d-flex justify-end flex-wrap"
              v-if="checkboxesMeses.items.length"
            >
              <v-checkbox
                class="d-inline-block mx-3"
                v-model="checkboxesMeses.selected"
                v-for="i in checkboxesMeses.items"
                :key="i.id"
                :label="i.text"
                :value="i.id"
              ></v-checkbox>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="showTable == true">
          <v-container class="fill-height">
            <v-data-table
              :headers="visitasHeader"
              :items="visitasData"
              v-model="checkboxesMeses.selectedItems"
              item-key="unique"
              show-select
              :single-expand="true"
              show-expand
              :expanded.sync="expanded"
              @click:row="clickRow"
            >
              <template #expanded-item="{ _, item }">
                <td></td>
                <td></td>
                <td>
                  <p
                    v-for="(_, marca) in item.marcas_header"
                    style="min-height: 66px; margin-bottom: 0"
                  >
                    <v-container fill-height>
                      <v-row justify="center" align="center">
                        <v-col cols="12">
                          {{ marcasIndex(marca) }}
                        </v-col>
                      </v-row>
                    </v-container>
                  </p>
                </td>
                <td v-for="(_, mes) in item.meses">
                  <p
                    v-for="(_, marca) in item.marcas_header"
                    style="min-height: 66px; margin-bottom: 0"
                  >
                    <v-container fill-height>
                      <v-row justify="center" align="center">
                        <v-col
                          cols="12"
                          v-for="message in item['meses'][mes]['marcas'][marca]"
                          :class="
                            pastToRed(item, mes, message) ? 'red--text' : ''
                          "
                          v-html="message"
                        >
                        </v-col>
                      </v-row>
                    </v-container>
                  </p>
                </td>
              </template>
            </v-data-table>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              @click="csvExport(true)"
              :disabled="!checkboxesMeses.selectedItems.length"
              >Gerar CSV marcados</v-btn
            >
            <v-btn @click="csvExport(false)">Gerar CSV desmarcados</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import empresaService from "../../../services/empresaService";
import relatorioService from "../../../services/relatorioService";
import contratoService from "../../../services/contratoService";
import marcas from "@/services/marcasService";
import moment from "moment";

export default {
  name: "VisitasContrato",
  data: () => ({
    filter: {
      marca: "",
      visitas: [],
      trade: "",
      contrato: "",
    },
    tradeItems: [],
    marcaItems: [],
    contratoItems: [],
    visitasItems: [],
    checkboxesMeses: {
      invertSelection: false,
      items: [],
      selected: [],
      selectedItems: [],
    },
    loading: {
      visitas: false,
      contrato: false,
      marca: false,
      trade: true,
      botao: false,
      pdf: false,
    },
    disabled: {
      botao: true,
      contrato: true,
      visitas: true,
      marca: true,
      trade: true,
      pdf: true,
    },
    showTable: false,
    visitasHeader: [],
    visitasData: [],
    expanded: [],
  }),
  methods: {
    popularTabela() {
      this.visitasData = [];
      this.showTable = false;
      this.loading.botao = true;
      this.selecionadoGerarCSV = [];

      relatorioService.API.Actions.getVisitasContratos(this.filter)
        .then(({ data }) => {
          /* POPULAR CABEÇALHO */
          let visitasHeader = [
            {
              text: "Loja",
              value: "titulo",
            },
          ];
          // TOTAL DE FINALIZADO DE CADA MES PESQUISADO
          let totais = {};
          moment.locale("pt-br");
          // PERCORRE TODO O RESULTADO
          Object.values(data).forEach((v) => {
            Object.getOwnPropertyNames(v.marcas_header)
            .forEach(marca => {
              if (this.marcaItems.findIndex((a) => a.value == marca) < 0) {
                delete v.marcas_header[marca];
              }
            });
            // PERCORRE OS MESES DE CADA RESULTADO
            Object.keys(v.meses).forEach((m) => {
              // SE NÃO EXISTE A COLUNA "m" EM "totais" CRIÁ-LA E DEFINÍ-LA COM 0
              totais[m] ??= 0;
              // SOMA UM PARA CADA MES FINALIZADO
              totais[m] += Number(v.meses[m].finalizado);
            });
          });

          // CHECKBOXES DE SELEÇÃO EM GRUPO
          this.checkboxesMeses.items = Object.keys(totais).map((k) => {
            return {
              id: k,
              text:
                moment(k, "YYYYMM").format("MMM YYYY").toUpperCase() +
                ` (${totais[k]} visita${totais[k] > 1 ? "s" : ""})`,
            };
          });

          // ADD MESES DO FILTRO NO CABEÇALHO DA TABLE
          this.visitasHeader = visitasHeader.concat(
            Object.keys(totais).map((k) => ({
              text: moment(k, "YYYYMM")
                .format("MMM YYYY")
                .replace(/^./, (s) => s.toUpperCase()),
              value: `meses.${k}.finalizado`,
            }))
          );

          // /* POPULAR ITENS */
          this.visitasData = Object.keys(data).map((k) => {
            let d = data[k];
            // Se user iniciar com kw é dev, add o id do cliente e atlac ao título
            if (this.$store.getters.userLogin.substr(0,2).toLowerCase() === "kw") {
              d.titulo = `${d.titulo} [${k} - ${d.unique}]`;
            }
            // Se user for admin, add o id do cliente ao título
            else if(["Admin"].indexOf(this.$store.getters.userType) > -1){
              d.titulo = `${d.titulo} [${k}]`;
            }

            const MonthFrom = moment(d.contratoAlcance[0], "MM\/YYYY");
            const MonthTo = moment(d.contratoAlcance[1], "MM\/YYYY");

            while (MonthFrom < MonthTo) {
              if (
                !d.meses[MonthFrom.format("YYYYMM")] &&
                this.filter.visitas.some(
                  (mes) => mes === MonthFrom.format("MM/YYYY")
                )
              ) {
                const modelo = Object.values(d.meses)[0];
                if (!modelo.marcas) {
                }
                const keys = Object.keys(modelo.marcas);
                keys.forEach((key) => {
                  d.meses[MonthFrom.format("YYYYMM")] ??= {
                    marcas: {},
                    destaque: true,
                    finalizado: "Inexistente",
                  };
                  d.meses[MonthFrom.format("YYYYMM")]["marcas"][key] = "";
                });
              }
              MonthFrom.add(1, "month");
            }
            return d;
          });
          this.showTable = true;
        })
        .finally(() => {
          this.loading.botao = false;
        });
    },
    async listarEmpresas() {
      await empresaService.API.Actions.options().then((response) => {
        response.data.forEach((element) => {
          this.tradeItems.push(element);
        });
        this.loading.trade = false;
        this.disabled.trade = false;
      });
    },
    async listarMarcas() {
      this.marcaItems = [];
      this.loading.marca = true;

      let context = {};
      if ("Representante" == this.$store.getters.userType) {
        context = {
          params: {
            empresaTradeId: this.filter.trade,
          },
        };
      }

      await relatorioService.API.Actions.getMarcas(
        "Todos",
        this.filter.contrato,
        context
      ).then(({ data }) => {
        this.marcaItems.push("Todos");
        this.marcaItems.push(
          ...data.sort((a, b) =>
            a.text.toUpperCase() > b.text.toUpperCase() ? 1 : -1
          )
        );
      });
      this.disabled.marca = false;
      this.loading.marca = false;
    },
    listarContratos() {
      this.contratoItems = [];
      this.loading.contrato = true;
      this.filter.contrato = [];
      contratoService.API.Actions.options(this.filter.trade)
        .then((response) => {
          this.contratoItems = response.data.contratos;
          this.contratoItems.forEach((element, key) => {
            this.contratoItems[key].text =
              element.text + " - " + element.total + " Clientes";
          });

          this.loading.contrato = this.loading.visitas = false;
          this.disabled.contrato = this.disabled.visitas = false;
        })
        .catch(() => {
          this.loading.contrato = this.loading.visitas = false;
          this.disabled.contrato = this.disabled.visitas = true;
        });
    },
    listarVisitas() {
      this.disabled.visitas = true;
      this.contratoItems.forEach((element) => {
        if (element.value == this.filter.contrato) {
          this.visitasItems = [];
          let inicio = moment(element.validade_inicio, "MM/YYYY");
          let final = moment(element.validade_final, "MM/YYYY");

          [...Array(final.diff(inicio, "months") + 1).keys()].forEach(() => {
            this.visitasItems.push(inicio.format("MM/YYYY"));
            inicio.add(1, "month");
          });
          this.disabled.visitas = false;
        }
      });
    },
    csvExport(marcados) {
      let csvContent = "data:text/csv;charset=utf-8,";

      const header = Object.keys(this.visitasData[0].export).join(";");

      let body = this.checkboxesMeses.selectedItems;

      if (!marcados) {
        body = this.visitasData.filter(
          (visita) =>
            !this.checkboxesMeses.selectedItems.some((selected) => {
              return selected.unique === visita.unique;
            })
        );
      }
      body = body.map((i) => Object.values(i.export).join(";")).join("\n");

      csvContent += [header, body].join("\n");

      try {
        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", "Relatório de visitas.csv");
        link.click();
      } catch (err) {}
    },
    clickRow(item, event) {
      if (event.isExpanded) {
        const indexExpanded = this.expanded.findIndex((i) => i === item);
        this.expanded.splice(indexExpanded, 1);
      } else {
        this.expanded = [item];
      }
    },
  },
  mounted() {
    if (!this.permissaoParaAlterarEmpresa) {
      this.filter.trade = this.$store.getters.userEmpresaId;
      this.listarContratos();
    } else {
      this.listarEmpresas();
    }
  },
  computed: {
    permissaoParaAlterarEmpresa() {
      return ['Admin', 'Representante'].indexOf(this.$store.getters.userType) > -1;
    },
    pastToRed() {
      return (item, mes, message) =>
        moment(item.thisMonth, "YYYYMM").diff(moment(mes, "YYYYMM")) > 0 &&
        !moment(message, "DD/MM/YYYY").isValid();
    },
    pastToRedNumber() {
      return (item, mes, marca) =>
        moment(item.thisMonth, "YYYYMM").diff(moment(mes, "YYYYMM")) > 0 &&
        item.finalizado < 1;
    },
    marcasIndex() {
      return (index) =>
        this.marcaItems
          .filter((v) => v.value === index)[0]
          ["text"].replace(/\d+ - /, "");
    },
    disableElm() {
      return (disabledData) => this.disabled[disabledData[0]];
    },
  },
  watch: {
    "filter.contrato"(v) {
      if (!Number(v)) {
        this.visitasItems = [];
        this.marcaItems = [];
      }
    },
    showTable(v) {
      if (!v) {
        this.checkboxesMeses = {
          items: [],
          selected: [],
          selectedItems: [],
        };
      }
    },
    "checkboxesMeses.selected": function () {
      this.checkboxesMeses.selectedItems = [];

      let filtro = this.checkboxesMeses.selected;
      if (!filtro.length) return false;

      this.checkboxesMeses.selectedItems = this.visitasData.filter((item) => {
        // A BASE É CADA DATA DO FILTRO
        return filtro.some((filtroData) => {
          // VERIFICA SE UM DIA DO FILTRO É UM INDICE DE MESES
          if (!item.meses.hasOwnProperty(filtroData)) {
            // SE NÃO FOR, IR PARA O PRÓXIMO
            return false;
          }
          // SEGUNDO TESTE É SE FINALIZADO FOR MAIR QUE ZERO
          return item.meses[filtroData].finalizado > 0;
        });
      });
    },
  },
};
</script>
<style>
.v-data-table {
  width: 100%;
}
.cara,
.cara .v-window__container,
.cara .v-carousel__item {
  height: auto !important;
}

.v-window__container {
  height: 0;
}
.v-card > :last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}
.v-input--selection-controls {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.v-data-table__expanded__content {
  box-shadow: none;
}

.v-data-table__expanded__content tr {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
}
</style>
