import client from '../plugins/apiClient'

export default {
  API: {
    Actions: {
      list: () => {
        client.login()
        return client.get('app_usuarios')
      },
      create: (objeto) => {
        client.login()
        return client.post('app_usuario', objeto)
      },
      delete: (id) => {
        client.login()
        return client.delete('app_usuario/' + id)
      },
      update: (id, objeto) => {
        client.login()
        return client.put('app_usuario/' + id, objeto)
      },
      find: (id) => {
        client.login()
        return client.get('app_usuario/' + id)
      },
      check: (id) => {
        client.login()
        return client.get('app_usuario_check/' + id)
      }
    }
  }
}
