import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import filter from "./plugins/filters";
import VueTheMask from "vue-the-mask";
import VueSweetalert2 from "vue-sweetalert2";
import moment from "moment";

import "sweetalert2/dist/sweetalert2.min.css";

Vue.filter("dateFormat", function (value, format = "DD/MM/YYYY") {
  if (value) {
    return moment(String(value)).format(format);
  }
  return "";
});

Vue.filter("isDev", function () {
  return this.$store.getters.userLogin.substr(0, 2).toLowerCase() === "kw";
});

Vue.use(VueSweetalert2);
Vue.use(VueTheMask);
Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  filter,
  store,
  render: (h) => h(App),
}).$mount("#app");
