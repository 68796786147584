<script>
import client from "../plugins/apiClient";
import picture from "../assets/image-loading.jpg"
import pictureNotFound from "../assets/image-not-found.png"
export default {
  data: function(){
    return {
      imageSrc : picture
    }
  },
  props: {
    src: String,
  },
  mounted() {
    if(process.env.VUE_APP_LOAD_REPORTS_IMAGES){
      this.imageSrc = pictureNotFound
      return;
    }
    client.login();
    client
      .get("image", {
        params: {
          image: this.src,
        },
      })
      .then((response) => {
        if(response.data && response.data.length){
          this.imageSrc = response.data
        }else{
          this.imageSrc = pictureNotFound
        }
      });
  },
};
</script>
<template>
  <img :src="imageSrc" />
</template>
