<template>
  <v-container style="display: grid" v-if="$store.getters.userType === 'Admin'">
    <v-row>
      <v-col>
        <v-card>
          <v-row>
            <v-col class="pa-0 pt-4 pl-4">
              <v-text-field
                v-model="buscar"
                append-icon="mdi-magnify"
                label="Buscar"
                class="ml-3"
              >
              </v-text-field>
            </v-col>
            <v-col class="pa-4">
              <router-link :to="path">
                <v-btn class="ma-4 float-right" color="primary">
                  Incluir
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col class="pa-0 pl-4" cols="6">
              <v-autocomplete
                v-model="filter.trade"
                label="Empresa de Trade"
                @input="list"
                :items="tradeItems"
                outlined
                :loading="loadingTrade"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :search="gatilhoBusca"
            :items="acoes"
            :loading="$store.getters.loading"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <router-link
                :to="{ name: 'acoesFormEdit', params: { id: item.id } }"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="mr-2" v-bind="attrs" v-on="on">
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </router-link>
              <dakota-confirm
                :dataService="service"
                :dataEntry="item.id"
              ></dakota-confirm>
            </template>
            <template v-slot:[`item.status`]="{ item }"
              >{{ item.status | usuarioTipo }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="alterarStatus(item)"
                  >
                    <v-icon v-if="!item.status"> mdi-check </v-icon>
                    <v-icon v-if="item.status"> mdi-close </v-icon>
                  </v-btn>
                </template>
                <span v-if="!item.status">Ativar Ação de Trade</span>
                <span v-if="item.status">Inativar Ação de Trade</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- MODAL -->
    <DakotaModalConfirm ref="confirm" />
  </v-container>
</template>
<script>
import acoesService from "../../../services/acoesService";
import empresaService from "../../../services/empresaService";
import DakotaConfirm from "@/components/DakotaConfirmation";
import DakotaModalConfirm from "../../../components/DakotaModalConfirm.vue";
export default {
  components: {
    DakotaConfirm,
    DakotaModalConfirm,
  },

  data: () => ({
    buscar: "",

    filter: {
      trade: "",
    },
    tradeItems: [],
    marcaItems: [],
    path: "/acoes/formulario",
    acoes: [],
    sortBy: "validade_final",
    service: "",
    requestData: [],
    headers: [
      {
        text: "Fim da Validade",
        align: "start",
        sortable: false,
        value: "validade_final",
      },
      {
        text: "Contrato",
        align: "start",
        sortable: false,
        value: "numero_contrato",
      },
      {
        text: "Empresa de trade",
        align: "start",
        sortable: false,
        value: "empresa",
      },
      {
        text: "Marca",
        align: "start",
        sortable: false,
        value: "marca",
      },
      {
        text: "Status",
        align: "center",
        sortable: false,
        value: "status",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
      },
    ],
    loadingTrade: true,
  }),

  mounted() {
    this.list();
    this.listarEmpresas();
    this.listarMarcas();
  },

  computed: {
    gatilhoBusca() {
      if (this.buscar.length >= 3) {
        return this.buscar;
      }
      return "";
    },
  },

  created() {
    this.service = acoesService;
  },
  methods: {
    async listarEmpresas() {
      await empresaService.API.Actions.options().then((response) => {
        this.tradeItems = response.data;
        this.loadingTrade = false;
      });
    },

    async listarMarcas() {
      await acoesService.API.Actions.marcas().then((response) => {
        this.marcaItems = response.data.marcas;
      });
    },
    list() {
      this.$store.dispatch("start_load");
      let filter = {};
      if (this.filter.trade) {
        filter.empresa_trade = this.filter.trade;
      }
      acoesService.API.Actions.list(filter)
        .then((response) => {
          this.$store.dispatch("stop_load");
          this.acoes = response.data.acoes;
        })
        .catch((error) => {
          this.$store.dispatch("stop_load");
          const errorArray = error.response.data.errors;
          for (const error in errorArray) {
            this.$store.dispatch("error", errorArray[error][0]);
          }
        });
    },
    async alterarStatus(item) {
      let request = {};

      this.$refs.confirm
        .open(
          "Deseja " + (item.status ? "Inativar" : "Ativar") + " essa ação?",
          item.status ? "Pode haver visitas em andamento" : "",
          item.status ? "Inativar" : "Ativar"
        )
        .then((confirm) => {
          if (!confirm) return false;
          this.$store.dispatch("start_submit");
          this.tradeItems.find((obj) => {
            if (obj.text == item.empresa) {
              let empresaRequest = obj.value;
              request.empresa_trade_id = empresaRequest;
            }
          });
          this.marcaItems.find((obj) => {
            if (obj.text == item.marca) {
              let marcaRequest = obj.value;
              request.marca = marcaRequest;
            }
          });
          request.status = item.status;
          acoesService.API.Actions.alterarStatus(item.id, request)
            .then((response) => {
              this.$store.dispatch("stop_submit");
              this.$store.dispatch("success", response.data.message);
              this.list();
            })
            .catch((error) => {
              this.$store.dispatch("stop_submit");
              const errors = error.response.data.errors;
              if (typeof errors === "string") {
                this.$store.dispatch("error", errors);
              } else {
                for (const index in errors) {
                  this.$store.dispatch("error", errors[index][0]);
                }
              }
            });
        });
    },
  },
};
</script>
