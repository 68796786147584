<template>
  <v-container>
    <v-card>
      <v-form ref='form' @submit.prevent='submit' class='pa-4'>
        <v-row>
          <v-col cols='12' md='6'>
            <v-text-field
              v-model='razaoSocial'
              label='Razao Social'
              class='obrigatorio'
              :error-messages='razaoErrors'
              :loading='$store.getters.loading'
              @input='$v.razaoSocial.$touch()'
              autofocus
              required
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols='12' md='6'>
            <v-text-field
              v-model='cnpj'
              label='CNPJ'
              class='obrigatorio'
              v-mask='"##.###.###/####-##"'
              :error-messages='cnpjErrors'
              :loading='$store.getters.loading'
              @input='$v.cnpj.$touch()'
              required
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols='12' md='6'>
            <v-autocomplete
              v-model='estado'
              label='Estado'
              class='obrigatorio'
              :items='optionEstado'
              :disabled='disabledEstados'
              :error-messages='estadoErrors'
              :loading='carregandoEstados'
              @input='selecionouEstado($event), $v.estado.$touch'
              outlined
              required
            ></v-autocomplete>
          </v-col>
          <v-col cols='12' md='6'>
            <v-autocomplete
              v-model='cidade'
              label='Cidade'
              class='obrigatorio'
              :items='optionCidade'
              :disabled='disabledCidades'
              :error-messages='cidadeErrors'
              :loading='carregandoCidades'
              @input='$v.cidade.$touch'
              outlined
              required
            ></v-autocomplete>
          </v-col>
          <v-col cols='12' md='6'>
            <v-text-field
              v-model='responsavel'
              label='Contato'
              class='obrigatorio'
              :error-messages='responsavelErrors'
              :loading='$store.getters.loading'
              @input='$v.responsavel.$touch()'
              required
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols='12' md='6'>
            <v-text-field
              v-model='telefone'
              label='Telefone'
              class='obrigatorio'
              v-mask='["(##) ####-####", "(##) #####-####"]'
              :error-messages='telefoneErrors'
              :loading='$store.getters.loading'
              @input='$v.telefone.$touch()'
              required
              outlined
            ></v-text-field>
          </v-col>
          <v-col md=2 col=12>
            <v-img 
              style="margin-left: 12px"
              v-bind:src='logoShow'
              max-width='150'
              max-height='150'
            ></v-img>
          </v-col>
          <v-col cols='12' md='10'>
            <v-file-input
              v-model='logo'
              accept="image/*"
              label='Logo para o App'
              :class='logoClass'
              :loading='$store.getters.loading'
              :rules="fileRules"
              @input='$v.logo.$touch()'
              @change="exibirImagem($event)"
              outlined
            >
            <template v-slot:selection="{ text }">
              <v-chip
                small
                label
                color="primary"
              >
                {{ text }}
              </v-chip>
              </template>
            </v-file-input>
              <!-- :error-messages='logoErrors' -->
          </v-col>
            <v-col md='12' col='12'>
              <small>
                <span class='legenda fomnst-size'>
                  Campo Obrigatório
                </span>
              </small>
            </v-col>
          </v-row>
          <v-col md='12'>
            <v-divider></v-divider>
          </v-col>
          <v-row>
            <v-col cols='2' md='6' class='d-none d-sm-flex'>
              <router-link to=/empresas>
                <v-btn color='secondary' outlined>
                  Voltar
                </v-btn>
              </router-link>
            </v-col>
            <v-spacer></v-spacer>
            <v-dialog v-model='dialog' content-class='modal-delete'>
              <v-card>
                <v-card-title>
                  Excluir o registro?
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class='blue-grey lighten-5'>
                  A exclusão desse registro é permanente e sem retorno.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" text @click='dialog = false'>
                    Não
                  </v-btn>
                  <v-btn color='error' @click='excluir'>
                    Sim
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-col class='text-right' cols='10' md='6'>
              <v-btn @click='limpar' class='mr-4' v-if='this.$route.params.id == undefined'>
                Limpar
              </v-btn>
              <v-btn @click='dialog = true' class='mr-4' color='error' :loading='$store.getters.submitted' v-if='this.$route.params.id != undefined' outlined>
                Excluir
              </v-btn>
              <v-btn type='button' @click='submit' color='primary' :loading="$store.getters.submitted" :disabled='$v.$invalid'>
                Salvar
              </v-btn>
            </v-col>
          </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import empresaService from '../../../services/empresaService'
import estadoService from '../../../services/estadoService'
import cidadeService from '../../../services/cidadeService'

export default {
  mixins: [validationMixin],

  data: () => ({
    name: 'dakota-empresa-form',
    razaoSocial: '',
    cnpj: '',
    estado: '',
    cidade: '',
    responsavel: '',
    telefone: '',
    logoShow: '',
    logo: [],
    optionEstado: [],
    optionCidade: [],
    disabledEstados: true,
    carregandoEstados: '',
    carregandoCidades: false,
    disabledCidades: true,
    b64: '',
    dialog: false,
    logoClass: 'obrigatorio',
    fileRules: [
      value => {
        const pattern = /^image\D(jpe|jp|pn)g$/g

        if (value && value.name !== undefined) {
          return (value.size < 1024 * 1024 && pattern.test(value.type)) || 'Arquivo inválido.'
        }

        return true
      }
    ]
  }),

  validations () {
    return {
      razaoSocial: {
        required
      },
      cnpj: {
        required
      },
      responsavel: {
        required
      },
      telefone: {
        required
      },
      cidade: {
        required
      },
      estado: {
        required
      },
      logo: {
        required: requiredIf(function () {
          return this.$route.params.id === undefined
        })
      }
    }
  },

  computed: {
    razaoErrors () {
      const errors = []
      if (!this.$v.razaoSocial.$dirty) {
        return errors
      }!this.$v.razaoSocial.required && errors.push('É necessário informar a Razão Social')
      return errors
    },

    cnpjErrors () {
      const errors = []
      if (!this.$v.cnpj.$dirty) {
        return errors
      } else {
        !this.$v.cnpj.required && errors.push('É necessário informar o CNPJ')
        return errors
      }
    },

    responsavelErrors () {
      const errors = []
      if (!this.$v.responsavel.$dirty) {
        return errors
      } else {
        !this.$v.responsavel.required && errors.push('É necessário informar o contato')
        return errors
      }
    },

    telefoneErrors () {
      const errors = []
      if (!this.$v.telefone.$dirty) {
        return errors
      } else {
        !this.$v.telefone.required && errors.push('É necessário informar o telefone')
        return errors
      }
    },

    cidadeErrors () {
      const errors = []
      if (!this.$v.cidade.$dirty) {
        return errors
      } else {
        !this.$v.cidade.required && errors.push('É necessário informar a cidade')
        return errors
      }
    },

    estadoErrors () {
      const errors = []
      if (!this.$v.estado.$dirty) {
        return errors
      } else {
        !this.$v.estado.required && errors.push('É necessário informar o estado')
        return errors
      }
    }
  },

  mounted () {
    this.getEstado()
    const routeId = this.$route.params.id

    if (routeId !== undefined) {
      this.logoClass = ''
      this.$store.dispatch('start_load')

      empresaService.API.Actions.find(routeId).then((response) => {
        const dados = response.data
        this.cnpj = dados.cnpj
        this.disabledCidades = false
        this.razaoSocial = dados.nome
        this.responsavel = dados.responsavel
        this.telefone = dados.telefone
        this.cidade = dados.cidade_id
        this.cnpj = dados.cnpj
        this.estado = dados.estado_id
        this.getCidade()
        this.$store.dispatch('stop_load')
      }).catch((error) => {
        this.$store.dispatch('stop_load')
        const errorArray = error.response.data.errors
        for (const error in errorArray) {
          this.$store.dispatch('error', errorArray[error][0])
        }
      })

      empresaService.API.Actions.logo(routeId).then((response) => {
        this.logoShow = response.data.logo.base64
      }).catch((error) => {
        this.$store.dispatch('stop_load')
        const errorArray = error.response.data.errors
        for (const error in errorArray) {
          this.$store.dispatch('error', errorArray[error][0])
        }
      })
    }
  },
  watch: {
      logo: {
          handler() {
              if (this.logo == null){
                this.logoShow = ''
              }
          },
          deep: true,
      },
  },
  methods: {
    selecionouEstado () {
      this.getCidade()
    },
    exibirImagem(){
       if (this.logo && this.logo.length !== 0) {
         const toBase64 = file => new Promise((resolve, reject) => {
           const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
        toBase64(this.logo).then(result => {
          this.logoShow = result
        })
      }
      
    },

    submit () {
      if (!this.$refs.form.validate()) {
        return
      }

      this.$store.dispatch('start_submit')

      const routeId = this.$route.params.id
      const empresa = {
        nome: this.razaoSocial,
        estado_id: this.estado,
        cidade_id: this.cidade,
        responsavel: this.responsavel,
        telefone: this.telefone,
        cnpj: this.cnpj
      }

      if (this.logo && this.logo.length !== 0) {
        const toBase64 = file => new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })

        toBase64(this.logo).then(result => {
          empresa.logo = result
          if (routeId === undefined) {
            empresaService.API.Actions.create(empresa)
              .then((response) => {
                this.$store.dispatch('stop_submit')
                this.$store.dispatch('success', response.data.message)
                this.$router.push('/empresas')
              }).catch((error) => {
                this.$store.dispatch('stop_submit')
                const errorArray = error.response.data.errors
                for (const error in errorArray) {
                  this.$store.dispatch('error', errorArray[error][0])
                }
                this.loading = false
              })
          } else {
            empresaService.API.Actions.update(routeId, empresa)
              .then((response) => {
                this.$store.dispatch('stop_submit')
                this.$store.dispatch('success', response.data.message)
                this.$router.push('/empresas')
              }).catch((error) => {
                this.$store.dispatch('stop_submit')
                const errorArray = error.response.data.errors
                for (const error in errorArray) {
                  this.$store.dispatch('error', errorArray[error][0])
                }
              })
          }
        })
      } else {
        if (routeId === undefined) {
          empresaService.API.Actions.create(empresa)
            .then((response) => {
              this.$store.dispatch('stop_submit')
              this.$store.dispatch('success', response.data.message)
              this.$router.push('/empresas')
            }).catch((error) => {
              this.$store.dispatch('stop_submit')
              const errorArray = error.response.data.errors
              for (const error in errorArray) {
                this.$store.dispatch('error', errorArray[error][0])
              }
              this.loading = false
            })
        } else {
          empresaService.API.Actions.update(routeId, empresa)
            .then((response) => {
              this.$store.dispatch('stop_submit')
              this.$store.dispatch('success', response.data.message)
              this.$router.push('/empresas')
            }).catch((error) => {
              this.$store.dispatch('stop_submit')
              const errorArray = error.response.data.errors
              for (const error in errorArray) {
                this.$store.dispatch('error', errorArray[error][0])
              }
            })
        }
      }
    },

    limpar () {
      this.$v.$reset()
      this.razaoSocial = ''
      this.logoShow = ''
      this.cnpj = ''
      this.estado = ''
      this.cidade = ''
      this.responsavel = ''
      this.telefone = ''
      this.logo = []
    },

    getCidade: function () {
      this.optionCidade = []
      this.carregandoCidades = true
      cidadeService.API.Actions.options(this.estado)
        .then((response) => {
          response.data.forEach(element => {
            this.optionCidade.push(element)
          })
          this.carregandoCidades = false
          this.disabledCidades = false
        }).catch(() => {
          this.carregandoCidades = false
          this.disabledCidades = true
        })
    },

    getEstado: function () {
      estadoService.API.Actions.options()
        .then((response) => {
          response.data.forEach(element => {
            this.optionEstado.push(element)
          })
          this.carregandoEstados = false
          this.disabledEstados = false

        }).catch(() => {
          this.carregandoEstados = false
          this.disabledEstados = true

        })
    },

    excluir () {
      const routeId = this.$route.params.id
      this.$store.dispatch('start_submit')

      empresaService.API.Actions.delete(routeId)
        .then((response) => {
          if (response.data.errors) {
            this.$store.dispatch('Erro', response.data.message)
          } else {
            this.$store.dispatch('Sucesso', response.data.message)
          }

          this.dialog = false
          this.$store.dispatch('stop_submit')
          this.$router.push('/empresas')
        }).catch((error) => {
          const errorArray = error.response.data.errors
          this.$store.dispatch('stop_submit')
          if (typeof (errorArray) === 'string') {
            this.$store.dispatch('error', errorArray)
          }

          if (errorArray instanceof Array) {
            for (const error in errorArray) {
              this.$store.dispatch('error', errorArray[error][0])
            }
          }
          this.dialog = false
        })
    }
  }
}
</script>

