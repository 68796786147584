import index from './views/index'
import form from './views/form'

export default [{
  path: 'usuarios',
  name: 'Usuarios',
  component: index,
  meta: {
    permissoes: ['Admin'],
    breadcrumbs: [{
      text: 'Home',
      exact: true,
      to: {
        name: 'Base'
      }
    },
    {
      text: 'Usuários',
      exact: true,
      to: {
        name: 'Usuarios'
      }
    }
    ]
  }
},
{
  path: 'usuarios/formulario',
  name: 'usuarioForm',
  component: form,
  meta: {
    permissoes: ['Admin'],
    breadcrumbs: [{
      text: 'Home',
      exact: true,
      to: {
        name: 'Base'
      }
    },
    {
      text: 'Usuários',
      exact: true,
      to: {
        name: 'Usuarios'
      }
    },
    {
      text: 'Cadastro de Usuário',
      exact: true,
      to: {
        name: 'usuarioForm'
      }
    }
    ]
  }
},
{
  path: 'usuarios/formulario/:id',
  name: 'usuarioFormEdit',
  component: form,
  meta: {
    permissoes: ['Admin'],
    breadcrumbs: [{
      text: 'Home',
      exact: true,
      to: {
        name: 'Base'
      }
    },
    {
      text: 'Usuários',
      exact: true,
      to: {
        name: 'Usuarios'
      }
    },
    {
      text: 'Edição de Usuário',
      exact: true,
      to: {
        name: 'usuarioFormEdit'
      }
    }
    ]
  }
}
]
