import index from './views/index'
import form from './views/form'

export default [
  {
    path: 'acoes',
    name: 'Acoes',
    component: index,
    meta: {
      permissoes: ['Admin'],
      breadcrumbs: [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Base'
          }
        },
        {
          text: 'Ações de Trade',
          exact: true,
          to: {
            name: 'Acoes'
          }
        }
      ]
    }
  },
  {
    path: 'acoes/formulario',
    name: 'acoesForm',
    component: form,
    meta: {
      permissoes: ['Admin'],
      breadcrumbs: [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Base'
          }
        },
        {
          text: 'Ações de Trade',
          exact: true,
          to: {
            name: 'Acoes'
          }
        },
        {
          text: 'Cadastro de Ações',
          exact: true,
          to: {
            name: 'acoesForm'
          }
        }
      ]
    }
  },
  {
    path: 'acoes/formulario/:id',
    name: 'acoesFormEdit',
    component: form,
    meta: {
      permissoes: ['Admin'],
      breadcrumbs: [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Base'
          }
        },
        {
          text: 'Ações de Trade',
          exact: true,
          to: {
            name: 'Acoes'
          }
        },
        {
          text: 'Edição de Ações',
          exact: true,
          to: {
            name: 'acoesFormEdit'
          }
        }
      ]
    }
  }
]
