<template>
    <span>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn :icon='!button' @click="dialog = true"  v-bind="attrs" v-on="on">
                    <v-icon> mdi-delete </v-icon>
                    <span v-if="button">Excluir</span>
                </v-btn>
            </template>
                <span>Excluir</span>
        </v-tooltip>
        <v-dialog v-model="dialog" content-class="modal-delete">
            <v-card>
                <v-card-title>
                    Excluir o registro?
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="blue-grey lighten-5">
                    A exclusão desse registro é permanente e sem retorno.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="dialog = false">
                        Não
                    </v-btn>
                    <v-btn color="error" @click="excluir()" :loading="$store.getters.loading">
                        Sim
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>
<style>
    .modal-delete {
        width: 30%!important;
    }
    @media only screen and (max-width: 700px){
        .modal-delete {
            width: 80%!important;
        }
    }
    .v-card__title{
        padding:16px 24px 10px !important;
    }
    .v-card__text{
        padding: 16px!important;
        color: rgba(0,0,0,.6) !important;
    }
</style>
<script>
export default {
  name: 'dakota-confirm',
  props: ['dataService', 'button', 'dataClass', 'dataColor', 'dataEntry'],
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    excluir () {
      this.$store.dispatch('start_load')
      this.dataService.API.Actions.delete(this.dataEntry)
        .then((response) => {
          if (response.data.errors) {
            this.$store.dispatch('error', response.data.message)
          }

          this.$store.dispatch('stop_load')
          this.dialog = false
          this.$router.go()
        }).catch((error) => {
          const errorArray = error.response.data.errors
          if (typeof (errorArray) === 'string') {
            this.$store.dispatch('error', errorArray)
          }
          if (errorArray instanceof Array) {
            for (const error in errorArray) {
              this.$store.dispatch('error', errorArray[error][0])
            }
          }
          this.$store.dispatch('stop_load')
          this.dialog = false
        })
    }
  }
}
</script>
