<template>
  <div>
    <div>
      <h2>Aguarde. Gerando PDF</h2>
    </div>
    <div v-show="false">
      <v-container class="pa-4" id="tabela">
        <v-row
          style="
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
            grid-template-rows: 1fr 1fr;
            width: 1100px;
          "
        >
          <div class="pa-4" style="text-align: center">
            <img
              :src="require('../../../assets/logo.png')"
              contain
              width="30%"
            />
          </div>
          <div class="pa-4" style="text-align: center">
            <h2>Trade Universo Dakota</h2>
            <h3>QUIZ</h3>
          </div>
          <div class="pa-4" style="text-align: end">
            <h3>
              {{ datimeNow.split(" ")[0] }}
            </h3>
            <h3>
              {{ datimeNow.split(" ")[1] }}
            </h3>
          </div>
          <div class="pa-4" style="text-align: center">
            <h4>
              <!-- Marca: {{dadosTopo.marca}} -->
              <img
                :src="require('../../../assets/' + dadosTopo.marca)"
                contain
                width="25%"
              />
            </h4>
          </div>
          <div class="pa-4" style="text-align: start">
            <h4>Empresa de Trade: {{ dadosTopo.trade }}</h4>
            <h4>Contrato: {{ dadosTopo.contrato }}</h4>
          </div>
          <div class="pa-4" style="text-align: end">
            <h4>
              Recebido De:
              {{
                $route.query.dateInicial.split("-")[2] +
                "/" +
                $route.query.dateInicial.split("-")[1] +
                "/" +
                $route.query.dateInicial.split("-")[0]
              }}
            </h4>
            <h4>
              Recebido Até:
              {{
                $route.query.dateFinal.split("-")[2] +
                "/" +
                $route.query.dateFinal.split("-")[1] +
                "/" +
                $route.query.dateFinal.split("-")[0]
              }}
            </h4>
          </div>
          <!-- <div> -->
          <!-- <v-btn @click="printScreen()" color="primary" id="printPageButton">Salvar Relatório </v-btn> -->
          <!-- </div> -->
        </v-row>
        <v-row style="width: 1100px">
          <v-col>
            <v-container class="fill-height">
              <v-data-table
                :headers="headers"
                :items="items"
                style="overflow: auto; width: 1100px"
                dense
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.dados.razao_social">
                      <td>
                        <div style="text-align: center" class="dados">
                          <b> {{ item.dados.nome_fantasia }}<br /> </b>
                          {{ item.dados.razao_social }}<br />
                          {{ item.dados.logradouro }},
                          {{ item.dados.numero }} Bairro: {{ item.dados.bairro
                          }}<br />
                          {{ item.dados.cidade }} / {{ item.dados.estado
                          }}<br />
                        </div>
                      </td>
                      <td>
                        <div style="text-align: center">
                          {{ item.dados.analista }}
                        </div>
                      </td>
                      <!-- <td>
                      <div style="text-align:center; ">
                        {{item.dados.marca}}
                      </div>
                    </td> -->
                      <td>
                        {{
                          item.dados.data_criado.split("-")[2].split(" ")[0] +
                          "/" +
                          item.dados.data_criado.split("-")[1] +
                          "/" +
                          item.dados.data_criado.split("-")[0]
                        }}
                      </td>

                      <td v-for="pergunta in perguntas" :key="pergunta">
                        <div
                          v-for="resposta in item.respostas"
                          :key="resposta.resposta"
                          style="width: 100%"
                        >
                          <div
                            class="pb-4 pt-4"
                            style="text-align: center"
                            v-if="resposta.pergunta == pergunta"
                          >
                            <div
                              v-if="
                                resposta.tipo == 'Foto' &&
                                resposta.resposta != null &&
                                resposta.descarte != true
                              "
                            >
                              <img
                                width="100"
                                height="100"
                                style="display: table-cell; cursor: pointer"
                                :src="resposta.resposta"
                                @click="dialogImgMetodo(resposta.resposta)"
                              />
                            </div>
                            <div
                              v-if="
                                resposta.tipo == 'Escala' &&
                                resposta.descarte != true &&
                                resposta.resposta != null
                              "
                            >
                              {{ resposta.resposta }}<br />
                            </div>
                            <div
                              v-if="
                                resposta.tipo == 'Múltipla Escolha' &&
                                resposta.descarte != true &&
                                resposta.resposta != null
                              "
                            >
                              {{ resposta.resposta }}<br />
                            </div>
                            <div
                              v-if="
                                resposta.tipo == 'Única Escolha' &&
                                resposta.descarte != true &&
                                resposta.resposta != null
                              "
                            >
                              {{ resposta.resposta }}<br />
                            </div>
                            <div
                              v-if="
                                resposta.tipo == 'Texto' &&
                                resposta.descarte != true &&
                                resposta.resposta != null
                              "
                            >
                              {{ resposta.resposta }}<br />
                            </div>
                            <div
                              v-if="
                                resposta.tipo == 'Foto' &&
                                resposta.resposta != null &&
                                resposta.descarte == true
                              "
                            >
                              <b>DESCARTE</b><br />
                              Motivo: {{ resposta.resposta }}<br />
                            </div>
                            <div
                              v-if="
                                resposta.tipo != 'Foto' &&
                                resposta.descarte == true
                              "
                            >
                              <b>DESCARTE</b><br />
                              Motivo: {{ resposta.resposta }}<br />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <template>
                    <v-dialog v-model="dialogImg" width="unset">
                      <v-card class="text-center">
                        <img :src="imgExpandida" />
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="error"
                            outlined
                            block
                            text
                            @click="dialogImg = false"
                          >
                            Fechar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </template>
              </v-data-table>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import relatorioService from "../../../services/relatorioService";
import html2pdf from "html2pdf.js";
import moment from "moment";

export default {
  data: () => ({
    items: [],
    headers: [
      {
        text: "Loja",
        align: "center",
        sortable: false,
      },
      {
        text: "Analista",
        sortable: false,
        align: "center",
      },
      {
        text: "Recebido",
        sortable: false,
        align: "center",
      },
    ],
    menuInicial: false,
    menuFinal: false,
    relatorio: [],
    perguntas: [],
    dados: [],
    dialogImg: false,
    imgExpandida: "",
    datimeNow: "",
    dadosTopo: [],
  }),
  methods: {
    async popularTabela() {
      this.perguntas = [];
      this.headers = [];
      this.dados = [];
      await relatorioService.API.Actions.getTabelaQuiz(this.$route.query)
        .then((response) => {
          this.items = response.data;
          this.items.forEach((item) => {
            item.respostas.forEach((element) => {
              element.data_hora =
                element.data_hora.split("/")[1] +
                "/" +
                element.data_hora.split("/")[0] +
                "/" +
                element.data_hora.split("/")[2];
            });
          });
          response.data.forEach((element) => {
            element.respostas.forEach((resposta) => {
              this.perguntas.push(resposta.pergunta);
              // this.dados.push(
              //   {
              //     pergunta : resposta.pergunta,
              //   }
              // )
            });
          });
          this.perguntas = [...new Set(this.perguntas)];
          (this.headers = [
            {
              text: "Loja",
              align: "center",
              sortable: false,
            },
            {
              text: "Analista",
              sortable: false,
              align: "center",
            },
            {
              text: "Recebido",
              sortable: false,
              align: "center",
            },
          ]),
            this.perguntas.forEach((element, key) => {
              this.headers.push({
                text: this.perguntas[key],
                align: "center",
                sortable: false,
              });
            });
        })
        .catch((error) => {
          this.$store.dispatch("stop_submit");
          const errors = error.response.data.error;
          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
    },
    async converterIndices() {
      await relatorioService.API.Actions.converterIndices(this.$route.query)
        .then((response) => {
          this.dadosTopo = response.data;
          switch (this.dadosTopo.marca) {
            case "Dakota":
              this.dadosTopo.marca = "logo_00001.jpg";
              break;
            case "Kolosh":
              this.dadosTopo.marca = "logo_00002.jpg";
              break;
            case "Pink Cats":
              this.dadosTopo.marca = "logo_00004.jpg";
              break;
            case "Campesí":
              this.dadosTopo.marca = "logo_00006.jpg";
              break;
            case "Tanara":
              this.dadosTopo.marca = "logo_00007.jpg";
              break;
            case "Mississipi":
              this.dadosTopo.marca = "logo_00011.jpg";
              break;
          }
        })
        .catch((error) => {
          this.$store.dispatch("stop_submit");
          const errors = error.response.data.error;
          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
    },
    dialogImgMetodo(input) {
      this.dialogImg = true;
      this.imgExpandida = input;
    },
    async printScreen() {
      this.printing = true;
      var element = document.getElementById("tabela");
      var opt = {
        margin: 0.5,
        filename: "relatiorioQuiz.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 4, format: "a4" },
        jsPDF: { unit: "em", orientation: "l" },
        pagebreak: { mode: "avoid-all" },
      };
      // html2pdf().set(opt).from(element).save().output('datauri');
      await html2pdf()
        .set(opt)
        .from(element)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          window.open(pdf.output("bloburl"));
        });
    },
  },
  async mounted() {
    await this.converterIndices();
    this.datimeNow = moment().format("DD/MM/YYYY HH:mm:ss");
    await this.popularTabela();
    await this.printScreen();
    // setTimeout(() => {window.close()}, 5000);
    window.close();
  },
  computed: {
    dateInicialFormatado() {
      return this.$route.query.dateInicial
        ? moment(this.$route.query.dateInicial).format("DD/MM/YYYY")
        : "";
    },
    dateFinalFormatado() {
      return this.$route.query.dateFinal
        ? moment(this.$route.query.dateFinal).format("DD/MM/YYYY")
        : "";
    },
  },
};
</script>
<style>
.v-data-table {
  max-width: 100%;
}
@media print {
  #printPageButton {
    display: none;
  }
}
.v-data-table > .v-data-table__wrapper > table {
  border-spacing: 0 0.5rem;
}
.dados {
  font-size: 0.7rem;
}
</style>
