<template>
  <v-container style="display: grid">
    <v-row>
      <v-col>
        <v-card>
          <v-row>
            <v-col class="pa-4">
              <v-text-field v-model="buscar" autofocus append-icon="mdi-magnify" 
                label="Buscar" class="ml-3">
              </v-text-field>
            </v-col>
            <v-col class="pa-4">
              <router-link :to="path">
                <v-btn class="ma-4 float-right" color="primary">
                  Incluir
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table :headers="headers" :items="questoes" :search="gatilhoBusca" :sort-by.sync="sortBy" :loading='$store.getters.loading'>
            <template v-slot:[`item.actions`]="{ item }">
              <router-link :to="{ name: 'questaoFormEdit', params: { id: item.id }}">
              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="mr-2" v-bind="attrs" v-on="on">
                      <v-icon>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </router-link >
              <dakota-confirm :dataService='service' :dataEntry='item.id'></dakota-confirm>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import questaoService from '../../../services/questaoService'
import DakotaConfirm from '@/components/DakotaConfirmation'

export default {
  components: {
    DakotaConfirm
  },

  data: () => ({
    dialog: false,
    buscar: '',
    sortBy: 'pergunta',
    dialogDelete: false,
    path: '/questoes/formulario',
    questoes: [],
    headers: [{
      text: 'Descrição',
      align: 'start',
      sortable: false,
      value: 'pergunta'
    },
    {
      text: 'Tipo',
      value: 'tipo',
      sortable: false,
      align: 'start'
    },
    {
      text: 'Ações',
      value: 'actions',
      sortable: false,
      align: 'start'
    }

    ]
  }),

  created () {
    this.service = questaoService
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Adicionar Questão' : 'Editar Questão'
    },

    gatilhoBusca () {
      if (this.buscar.length >= 3) {
        return this.buscar
      }
      return ''
    }
  },

  mounted () {
    this.$store.dispatch('start_load')
    questaoService.API.Actions.list().then((response) => {
      this.$store.dispatch('stop_load')
      this.questoes = response.data
    }).catch((error) => {
      this.$store.dispatch('stop_load')
      const errorArray = error.response.data.errors
      for (const error in errorArray) {
        this.$store.dispatch('error', errorArray[error][0])
      }
    })
  }
}
</script>
