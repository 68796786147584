
import client from '../plugins/apiClient'

export default {
  API: {
    Actions: {
      options: (id = 0) => {
        client.login()
        if (id) {
          return client.get('cidade-options', { params: { estado: id } })
        } else {
          return client.get('cidade-options')
        }
      }
    }
  }
}
