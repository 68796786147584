import client from "../plugins/apiClient";

export default {
  API: {
    Actions: {
      getClientes: (contratoId) => {
        client.login();
        return client.get("get-clientes/" + contratoId);
      },
      getMarcas: (clienteId, contratoId, context) => {
        client.login();

        return client.get(
          "get-marcas/" + clienteId + "/" + contratoId,
          context ?? {}
        );
      },
      getMeses: (contratoId) => {
        client.login();
        return client.get("get-meses/" + contratoId);
      },
      getRespostasDescartes: function (filter) {
        let param = {};

        client.login();
        param = Object.assign({}, filter);

        return client.get("get-respostas-descartes", {
          params: param,
        });
        // return client.get('acoes_trade_app')
      },
      getTabelaAcoes: function (filter) {
        let param = {};

        client.login();
        param = Object.assign({}, filter);

        return client.get("get_tabela_acoes", {
          params: param,
        });
      },
      /**
       * Rota para apagar visitas de tarefas e questões
      */
      visitasDelete: function (params) {
        client.login();

        return client.delete("visitas_delete", { params });
      },
      getTabelaQuiz: function (filter) {
        let param = {};

        client.login();
        param = Object.assign({}, filter);

        return client.get("get_tabela_quiz", {
          params: param,
        });
      },
      getVisitasContratos: function (filter) {
        let param = {};

        client.login();
        param = Object.assign({}, filter);

        return client.get("get_visitas_contrato", {
          params: param,
        });
        // return client.get('acoes_trade_app')
      },
      converterIndices: (objeto) => {
        client.login();
        return client.post("converter-indices", objeto);
      },
    },
  },
};
