<template>
  <v-container>
    <v-card>
      <v-form @submit.prevent="submit" class="pa-4">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="nome"
            autofocus
            label="Nome"
            outlined
            required
            class="obrigatorio"
            :error-messages="nameErrors"
            :loading="$store.getters.loading"
            @input="$v.nome.$touch()"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="usuario"
            label="Usuário"
            required
            outlined
            :error-messages="userErrors"
            class="obrigatorio"
            :loading="$store.getters.loading"
            @input="$v.usuario.$touch()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="senha"
            label="Senha"
            required
            outlined
            :class="senhaClasse"
            :type="senhaStatus ? 'text' : 'password'"
            :append-icon="senhaStatus ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="senhaStatus = !senhaStatus"
            :error-messages="senhaErrors"
            @input="$v.senha.$touch()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="confirmacao"
            label="Confirmar Senha"
            required
            outlined
            :class="senhaClasse"
            :type="senhaStatus ? 'text' : 'password'"
            :append-icon="senhaStatus ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="senhaStatus = !senhaStatus"
            :error-messages="confirmacaoErrors"
            @input="$v.confirmacao.$touch()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-radio-group v-model="radioGroup" mandatory>
            <v-radio label="Admin" :value="0"></v-radio>
            <v-radio label="Trade" :value="1"></v-radio>
            <v-radio label="Representante" :value="2"></v-radio>
          </v-radio-group>
        </v-col>
        <!-- Empresas de trade para Representante -->
        <v-col cols="12" v-show="radioGroup == 2">
          <v-row v-for="(empresa_trade, index) in empresas_trade" :key="index">
            <template v-if="index == empresas_trade.length - 1">
              <v-col cols="12" md="6">
                <v-autocomplete
                  :items="tradeItems"
                  class="obrigatorio"
                  label="Empresa de Trade"
                  prepend-icon="mdi-plus"
                  v-model="empresa_trade.empresa_trade_id"
                  @click:prepend="addEmpresaTrade()"
                  @input="$v.trade.$touch()"
                  :loading="loadingTrade"
                  :disabled="disabledTrade"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  multiple
                  :items="brandItems"
                  class="obrigatorio"
                  v-model="empresa_trade.marcas"
                  label="Marcas"
                  :loading="loading.allowedBrands"
                  :disabled="loading.allowedBrands"
                  outlined
                ></v-autocomplete>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" md="6">
                <v-autocomplete
                  :items="tradeItems"
                  class="obrigatorio"
                  label="Empresa de Trade"
                  v-model="empresa_trade.empresa_trade_id"
                  prepend-icon="mdi-minus"
                  @click:prepend="removeEmpresaTrade(index)"
                  @input="$v.trade.$touch()"
                  :loading="loadingTrade"
                  :disabled="disabledTrade"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  multiple
                  :items="brandItems"
                  class="obrigatorio"
                  v-model="empresa_trade.marcas"
                  label="Marcas"
                  :loading="loading.allowedBrands"
                  :disabled="loading.allowedBrands"
                  outlined
                ></v-autocomplete>
              </v-col>
            </template>
          </v-row>
        </v-col>
        <!-- Empresas de trade -->
        <v-col cols="12" md="6">
          <v-autocomplete
            v-show="this.radioGroup == 1"
            :items="tradeItems"
            class="obrigatorio"
            label="Empresa de Trade"
            v-model="trade"
            @input="$v.trade.$touch()"
            :loading="loadingTrade"
            :disabled="disabledTrade"
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-switch
            v-model="ativo"
            color="primary"
            :loading="$store.getters.loading"
            :label="`Status: ${ativo ? 'Ativo' : 'Inativo'}`"
          >
          </v-switch>
        </v-col>
        <v-col md="12" col="12">
          <small>
            <span class="legenda"> Campo Obrigatório </span>
          </small>
        </v-col>
        <v-col md="12">
          <v-divider></v-divider>
        </v-col>
        <v-row class="pa-3">
          <v-col cols="2" md="6" class="d-none d-sm-flex">
            <v-btn color="secondary" :to="'/usuarios'" outlined> Voltar </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" content-class="modal-delete">
            <v-card>
              <v-card-title> Excluir o registro? </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="blue-grey lighten-5">
                A exclusão desse registro é permanente e sem retorno.
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="dialog = false">
                  Não
                </v-btn>
                <v-btn color="error" @click="excluir"> Sim </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-col class="text-right" cols="10" md="6">
            <v-btn
              @click="limpar"
              class="mr-4"
              v-if="this.$route.params.id == undefined"
            >
              Limpar
            </v-btn>
            <v-btn
              @click="dialog = true"
              class="mr-4"
              color="error"
              :loading="$store.getters.submitted"
              v-if="this.$route.params.id != undefined"
              outlined
            >
              Excluir
            </v-btn>
            <v-btn
              color="primary"
              type="button"
              @click="submit"
              :loading="$store.getters.submitted"
              :disabled="$v.$invalid"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import usuarioService from "../../../services/usuarioService";
import empresaService from "../../../services/empresaService";
import marcasService from "../../../services/marcasService";
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  data: () => ({
    name: "dakota-usuario-form",
    nome: "",
    ativo: "",
    radioGroup: "",
    usuario: "",
    trade: "",
    empresas_trade: [
      {
        empresa_trade_id: null,
        marcas: [],
      },
    ],
    allowedBrands: [],
    tradeItems: [],
    brandItems: [],
    path: "/usuarios",
    senha: "",
    confirmacao: "",
    senhaStatus: false,
    senhaClasse: "obrigatorio",
    dialog: false,
    disabledTrade: true,
    loadingTrade: true,
    loading: {
      allowedBrands: false,
    },
  }),

  validations() {
    if (this.$route.params.id === undefined) {
      if (this.radioGroup === 1) {
        return {
          nome: {
            required,
          },
          usuario: {
            required,
          },
          senha: {
            required,
          },
          trade: {
            required,
          },
          confirmacao: {
            required,
            senhasConferem: sameAs("senha"),
          },
        };
      } else {
        return {
          nome: {
            required,
          },
          usuario: {
            required,
          },
          senha: {
            required,
          },
          trade: {},
          confirmacao: {
            required,
            senhasConferem: sameAs("senha"),
          },
        };
      }
    } else {
      if (this.radioGroup === 1) {
        return {
          nome: {
            required,
          },
          usuario: {
            required,
          },
          senha: {},
          trade: {
            required,
          },
          confirmacao: {},
        };
      } else {
        return {
          nome: {
            required,
          },
          usuario: {
            required,
          },
          senha: {},
          trade: {},
          confirmacao: {},
        };
      }
    }
  },

  mounted() {
    this.listarEmpresas();
    const routeId = this.$route.params.id;
    if (routeId !== undefined) {
      this.senhaClasse = "";
      this.$store.dispatch("start_load");
      usuarioService.API.Actions.find(routeId)
        .then((response) => {
          this.usuarios = response.data;
          const dados = response.data;
          this.allowedBrands = dados.marcas?.map(({ value }) => value);
          this.nome = dados.nome;
          this.usuario = dados.login;
          this.ativo = dados.status;
          this.trade = dados.empresa_trade_id;
          this.radioGroup = dados.tipo;

          if(dados?.empresas_trade.length){
            this.empresas_trade = dados.empresas_trade;
          }

          this.$store.dispatch("stop_load");
        })
        .catch((error) => {
          this.$store.dispatch("stop_load");
          const errorArray = error.response?.data.errors;
          for (const error in errorArray) {
            this.$store.dispatch("error", errorArray[error][0]);
          }
        });
    } else {
      this.ativo = true;
    }
  },

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.nome.$dirty) {
        return errors;
      } else {
        !this.$v.nome.required && errors.push("É necessário informar um nome");
        return errors;
      }
    },

    userErrors() {
      const errors = [];
      if (!this.$v.usuario.$dirty) {
        return errors;
      } else {
        !this.$v.usuario.required &&
          errors.push("É necessário informar o usuário");
        return errors;
      }
    },

    tradeErrors() {
      const errors = [];
      if (!this.$v.trade.$dirty) {
        return errors;
      } else {
        !this.$v.trade.required &&
          errors.push("É necessário informar a empresa");
        return errors;
      }
    },

    senhaErrors() {
      if (this.$route.params.id === undefined) {
        const errors = [];
        if (!this.$v.senha.$dirty) {
          return errors;
        } else {
          !this.$v.senha.required &&
            errors.push("É necessário informar a senha");
          return errors;
        }
      } else {
        return "";
      }
    },

    confirmacaoErrors() {
      if (this.$route.params.id === undefined) {
        const errors = [];
        if (!this.$v.confirmacao.$dirty) {
          return errors;
        } else {
          !this.$v.confirmacao.required &&
            errors.push("É necessário confirmar a senha");
          !this.$v.confirmacao.senhasConferem &&
            errors.push("As senhas não conferem");
        }
        return errors;
      } else {
        return "";
      }
    },
  },
  methods: {
    submit: function () {
      this.$v.$touch();
      this.$store.dispatch("start_submit");

      const routeId = this.$route.params.id;
      const user = {};
      user.nome = this.nome;
      user.login = this.usuario;
      user.tipo = this.radioGroup;
      user.status = this.ativo;

      if (this.radioGroup === 1) {
        user.empresa_trade_id = this.trade;
      } else if (this.radioGroup === 2) {
        user.empresas_trade = this.empresas_trade;
      }

      if (this.senha !== "") {
        user.senha = this.senha;
      }

      if (routeId === undefined) {
        usuarioService.API.Actions.create(user)
          .then((response) => {
            this.$store.dispatch("stop_submit");
            this.$store.dispatch("success", response.data.message);
            this.$router.push("/usuarios");
          })
          .catch((error) => {
            this.$store.dispatch("stop_submit");
            const errorArray = error.response?.data.errors;
            for (const error in errorArray) {
              this.$store.dispatch("error", errorArray[error][0]);
            }
          });
      } else {
        usuarioService.API.Actions.update(routeId, user)
          .then((response) => {
            this.$store.dispatch("stop_submit");
            this.$store.dispatch("success", response.data.message);
            this.$router.push("/usuarios");
          })
          .catch((error) => {
            this.$store.dispatch("stop_submit");
            const errorArray = error.response.data.errors;
            for (const error in errorArray) {
              this.$store.dispatch("error", errorArray[error][0]);
            }
          });
      }
    },
    limpar: function () {
      this.$v.$reset();
      this.nome = "";
      this.radioGroup = "";
      this.usuario = "";
      this.trade = 0;
      this.senha = "";
      this.confirmacao = "";
      this.senhaStatus = false;
    },
    listarEmpresas() {
      empresaService.API.Actions.options().then((response) => {
        response.data.forEach((element) => {
          this.tradeItems.push(element);
          this.disabledTrade = false;
          this.loadingTrade = false;
        });
      });
    },
    excluir() {
      const routeId = this.$route.params.id;
      this.$store.dispatch("start_submit");

      usuarioService.API.Actions.delete(routeId)
        .then((response) => {
          if (response.data.errors) {
            this.$store.dispatch("Erro", response.data.message);
          } else {
            this.$store.dispatch("Sucesso", response.data.message);
          }

          this.dialog = false;
          this.$store.dispatch("stop_submit");
          this.$router.push("/usuarios");
        })
        .catch((error) => {
          const errorArray = error.response.data.errors;
          this.$store.dispatch("stop_submit");

          if (typeof errorArray === "string") {
            this.$store.dispatch("error", errorArray);
          }
          if (errorArray instanceof Array) {
            for (const error in errorArray) {
              this.$store.dispatch("error", errorArray[error][0]);
            }
          }
          this.dialog = false;
        });
    },
    addEmpresaTrade() {
      this.empresas_trade.push({
        empresa_trade_id: 0,
        marcas: [],
      });
    },
    removeEmpresaTrade(index) {
      this.empresas_trade.splice(index, 1);
    },
  },
  watch: {
    radioGroup(value) {
      if (value === 2 && this.brandItems.length == 0) {
        this.loading.allowedBrands = true;
        marcasService.API.Actions.getAll()
          .then(({ data }) => {
            this.brandItems = [...data.data].sort((a, b) =>
              a.text.toUpperCase() > b.text.toUpperCase() ? 1 : -1
            );
          })
          .finally(() => {
            this.loading.allowedBrands = false;
          });
      }
    },
  },
};
</script>
