<template>
  <v-container>
    <v-form @submit.prevent="submit" class="pa-4" style="overflow-x: hidden">
      <v-card class="pa-4">
        <v-row>
          <v-col cols="12" md="4">
            <v-autocomplete
              :items="tradeItems"
              v-model="trade"
              class="obrigatorio"
              autofocus
              label="Empresa de Trade"
              :loading="loadingTrade"
              :disabled="disabledTrade"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="contrato"
              v-mask="'####################'"
              required
              outlined
              label="Número do contrato"
              class="obrigatorio"
              hint="Inserir somente números"
              persistent-hint
              autocomplete="new-password"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="validadeInicio"
              v-mask="'##/####'"
              required
              outlined
              label="Início da Validade"
              class="obrigatorio validade-datas"
              :readonly="!isActiveContract"
              type="text"
              :hint="isActiveContract ? '' : 'Contrato Inativo'"
              persistent-hint
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              v-model="validadeFinal"
              v-mask="'##/####'"
              required
              outlined
              label="Fim da Validade"
              class="obrigatorio validade-datas"
              :readonly="!isActiveContract"
              :hint="isActiveContract ? '' : 'Contrato Inativo'"
              persistent-hint
              type="text"
              @input="checkDiferencaValidade()"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3" md="12">
            <v-dialog
              v-model="dialog"
              width="500"
              persistent
              v-if="!$route.params.id"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  class="mr-4 mt-4"
                  col="12"
                  md="3"
                  v-bind="attrs"
                  v-on="on"
                >
                  Importar CSV
                </v-btn>
              </template>
              <div style="overflow-x: hidden">
                <v-card>
                  <v-card-title class="text-h6 grey lighten-2">
                    Importação de Arquivo CSV
                  </v-card-title>
                  <v-file-input
                    ref="fileupload"
                    v-model="file"
                    label="Insira o arquivo CSV"
                    accept="text/csv"
                    :loading="$store.getters.loading"
                    class="pa-10"
                    outlined
                  ></v-file-input>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-col cols="6" md="6">
                      <v-btn
                        color="error"
                        block
                        outlined
                        large
                        @click="(dialog = false), (file = [])"
                      >
                        Cancelar
                      </v-btn>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-btn color="primary" block large @click="importar">
                        Importar
                      </v-btn>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </div>
            </v-dialog>
            <v-btn
              class="mr-4 mt-4"
              col="12"
              md="3"
              @click="showTemplate = true"
              download
              v-if="!$route.params.id"
            >
              Baixar template CSV
            </v-btn>
            <v-dialog v-model="showTemplate" content-class="modal-delete">
              <div style="overflow-x: hidden">
                <v-card>
                  <v-card-title> Informações de importação </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="blue-grey lighten-5">
                    Para importar os dados corretamente, é necessário manter o
                    cabeçalho do modelo inserindo os dados de clientes a partir
                    da segunda linha.
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="showTemplate = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="success"
                      href="/files/template.csv"
                      @click="showTemplate = false"
                    >
                      Baixar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-dialog>
            <v-btn
              class="mr-4 mt-4"
              col="12"
              md="3"
              @click="lojaDialog"
              v-if="$route.params.id !== undefined && isActiveContract"
            >
              Adicionar Cliente
            </v-btn>
            <!--  -->
            <v-col cols="12" md="4" class="text-right mt-8">
              <v-autocomplete
                :items="razaoList"
                v-model="razaoFilter"
                label="Filtrar por Razão Social"
                v-if="$route.params.id !== undefined"
                outlined
              ></v-autocomplete>
            </v-col>
            <!--  -->
            <v-dialog v-model="showDialogCliente">
              <div style="overflow-x: hidden">
                <v-card>
                  <v-row class="px-2">
                    <v-col cols="12">
                      <v-card-title> Informações do Cliente </v-card-title>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="novoCliente.cnpj"
                        required
                        outlined
                        label="CNPJ"
                        type="text"
                        v-mask="'##.###.###/####-##'"
                        class="obrigatorio pr-2 pl-2"
                        :error-messages="errosModalCliente['cliente.cnpj']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="novoCliente.razao_social"
                        required
                        outlined
                        label="Razão Social"
                        type="text"
                        maxlength="60"
                        class="obrigatorio pr-2 pl-2 uppercase"
                        :error-messages="errosModalCliente['cliente.razao_social']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="novoCliente.nome_fantasia"
                        required
                        outlined
                        label="Nome Fantasia"
                        type="text"
                        maxlength="60"
                        class="obrigatorio pr-2 pl-2 uppercase"
                        :error-messages="errosModalCliente['cliente.nome_fantasia']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="novoCliente.logradouro"
                        required
                        outlined
                        label="Logradouro"
                        type="text"
                        maxlength="60"
                        class="obrigatorio pr-2 pl-2 uppercase"
                        :error-messages="errosModalCliente['cliente.logradouro']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="novoCliente.numero"
                        required
                        outlined
                        label="Número"
                        type="text"
                        maxlength="20"
                        class="obrigatorio pr-2 pl-2"
                        :error-messages="errosModalCliente['cliente.numero']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="novoCliente.complemento"
                        outlined
                        label="Complemento"
                        type="text"
                        maxlength="60"
                        class="pr-2 pl-2 uppercase"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="novoCliente.bairro"
                        required
                        outlined
                        label="Bairro"
                        type="text"
                        maxlength="60"
                        class="obrigatorio pr-2 pl-2 uppercase"
                        :error-messages="errosModalCliente['cliente.bairro']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="novoCliente.cidade"
                        required
                        outlined
                        label="Cidade"
                        type="text"
                        maxlength="30"
                        class="obrigatorio pr-2 pl-2 uppercase"
                        :error-messages="errosModalCliente['cliente.cidade']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="novoCliente.estado"
                        required
                        outlined
                        label="Estado"
                        type="text"
                        maxlength="30"
                        class="obrigatorio pr-2 pl-2 uppercase"
                        :error-messages="errosModalCliente['cliente.estado']"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      text
                      @click="showDialogCliente = false"
                    >
                      VOLTAR
                    </v-btn>
                    <!-- <v-btn color="success" @click="showTemplate = false, addCliente"> -->
                    <v-btn
                      color="success"
                      @click="addCliente"
                      v-if="addClienteVar == true"
                      :loading="loadingInformacoes"
                    >
                      SALVAR
                    </v-btn>
                    <v-btn
                      color="success"
                      @click="editCliente"
                      v-if="editarClienteVar == true"
                      :loading="loadingInformacoes"
                    >
                      SALVAR
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" col="12">
            <small>
              <span class="legenda"> Campo Obrigatório </span>
            </small>
          </v-col>
          <v-col cols="12" v-show="showErrors">
            <v-data-table
              :headers="headersErros"
              :items="errors"
              class="errors"
            >
              <template v-slot:[`item.errors`]="{ item }">
                <ul>
                  <li v-for="err in item.errors" :key="err">
                    {{ err }}
                  </li>
                </ul>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :search="buscar"
              :items="dados"
              :loading="$store.getters.loading"
              dense
            >
              <template v-slot:[`item.line`]="{ item }">
                {{ dados.indexOf(item) + 1 }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="
                        editarClienteFill(item), (editarClienteVar = true)
                      "
                      icon
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      v-if="$route.params.id !== undefined && isActiveContract"
                    >
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar Cliente</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="2" md="6" class="d-none d-sm-flex mt-4">
            <router-link to=/contratos>
              <v-btn color='secondary' outlined>
                Voltar
              </v-btn>
            </router-link>
          </v-col>
          <v-dialog v-model="deleteDialog" content-class="modal-delete">
            <v-card>
              <v-card-title> Excluir o registro? </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="blue-grey lighten-5">
                A exclusão desse registro é permanente e sem retorno.
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="deleteDialog = false">
                  Não
                </v-btn>
                <v-btn color="error" @click="excluir"> Sim </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-col cols="10" md="6" class="text-right mt-4">
            <v-btn
              @click="limpar"
              class="mr-4"
              v-if="this.$route.params.id === undefined"
            >
              Limpar
            </v-btn>
            <v-btn
              @click="deleteDialog = true"
              class="mr-4"
              color="error"
              :loading="$store.getters.submitted"
              v-if="this.$route.params.id != undefined"
              outlined
            >
              Excluir
            </v-btn>
            <v-btn
              type="button"
              @click="submit"
              color="primary"
              :loading="$store.getters.submitted"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </v-container>
</template>
<style scoped>
.errors * {
  color: red;
}
</style>
<script>
import empresaService from "../../../services/empresaService";
import contratoService from "../../../services/contratoService";
import moment from "moment";

const Papa = require("papaparse");

export default {
  data: () => ({
    tradeItems: [],
    trade: "",
    contrato: "",
    validadeInicio: "",
    validadeFinal: "",
    validadeDiferenca: "",
    datasValidas: true,
    dados: [],
    errors: [],
    buscar: "",
    file: [],
    fileJSON: "",
    contratoRequest: [],
    dialog: false,
    deleteDialog: false,
    showTemplate: false,
    showErrors: false,
    showDialogCliente: false,
    loadingTrade: true,
    loadingExcluir: false,
    loadingInformacoes: false,
    disabledTrade: true,
    razaoList: ["Todos"],
    razaoFilter: "Todos",
    addClienteVar: false,
    editarClienteVar: false,
    disabledValidade: true,
    errosModalCliente: {},
    novoCliente: {
      id: "",
      cnpj: "",
      razao_social: "",
      nome_fantasia: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
      contratoId: "",
    },
    headers: [
      {
        text: "Linha",
        align: "start",
        sortable: true,
        value: "line",
      },
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "CNPJ",
        align: "start",
        sortable: true,
        value: "cnpj",
      },
      {
        text: "Razão Social",
        value: "razao_social",
        sortable: true,
        align: "end",
      },
      {
        text: "Nome Fantasia",
        value: "nome_fantasia",
        sortable: true,
        align: "end",
      },
      {
        text: "Logradouro",
        value: "logradouro",
        sortable: true,
        align: "end",
      },
      {
        text: "Número",
        value: "numero",
        sortable: true,
        align: "end",
      },
      {
        text: "Complemento",
        value: "complemento",
        sortable: true,
        align: "end",
      },
      {
        text: "Bairro",
        value: "bairro",
        sortable: true,
        align: "end",
      },
      {
        text: "Cidade",
        value: "cidade",
        sortable: true,
        align: "end",
      },
      {
        text: "Estado",
        value: "estado",
        sortable: true,
        align: "end",
      },
    ],
    headersErros: [
      {
        text: "Linha Numero",
        align: "start",
        sortable: true,
        value: "linha",
      },
      {
        text: "Erros",
        align: "start",
        sortable: true,
        value: "errors",
      },
    ],
  }),

  mounted() {
    this.listarEmpresas();
    const routeId = this.$route.params.id;
    if (routeId !== undefined) {
      this.disabledValidade = false;
      this.headers.unshift({
        text: "",
        align: "start",
        sortable: false,
        value: "actions",
      });
      this.$store.dispatch("start_load");
      contratoService.API.Actions.find(routeId, this.razaoFilter)
        .then((response) => {
          const clientes = response.data.clientes;
          this.contratoRequest = response.data.contrato;

          this.validadeInicio = this.contratoRequest.validade_inicio;
          this.validadeFinal = this.contratoRequest.validade_final;

          this.disabledValidade = this.contratoRequest.contratoAtivo;

          let listaRazoes = clientes.map((x) => x.razao_social);
          this.razaoList.push(...listaRazoes);

          this.dados = clientes;
          this.fileJSON = JSON.stringify(clientes);
          this.trade = this.contratoRequest.empresa_trade_id;
          this.contrato = this.contratoRequest.numero_contrato;
          this.novoCliente.contratoId = this.contratoRequest.id;
          this.checkDiferencaValidade();

          // this.validadeInicio = this.validadeInicioParaData
          // this.validadeFinal = this.validadeFinalParaData
          this.$store.dispatch("stop_load");
        })
        .catch((error) => {
          this.$store.dispatch("stop_submit");
          this.$store.dispatch("stop_load");
          const errors = error;
          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
    }
  },
  watch: {
    razaoFilter(value) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        contratoService.API.Actions.find(this.$route.params.id, value)
          .then((response) => {
            const clientes = response.data.clientes;
            this.contratoRequest = response.data.contrato;

            this.dados = clientes;
            this.fileJSON = JSON.stringify(clientes);
            this.trade = this.contratoRequest.empresa_trade_id;
            this.contrato = this.contratoRequest.numero_contrato;
            this.novoCliente.contratoId = this.contratoRequest.id;
            this.$store.dispatch("stop_load");
          })
          .catch((error) => {
            this.$store.dispatch("stop_load");
            const errorArray = error.response.data.errors;
            for (const error in errorArray) {
              this.$store.dispatch("error", errorArray[error][0]);
            }
          });
      }, 600);
    },
    dados: {
      deep: true,
      handler(v) {
        this.fileJSON = JSON.stringify(v);
      },
    },
  },

  methods: {
    lojaDialog() {
      if (moment(this.validadeFinal, "MM/YYYY").isBefore(moment(), "month")) {
        this.$store.dispatch(
          "error",
          "Não é possível Adicionar cliente. Contrato encerrado."
        );
        return false;
      }
      this.novoCliente = {};
      this.editarClienteVar = false;
      this.addClienteVar = true;
      this.showDialogCliente = true;
      this.errosModalCliente = {};
    },

    parseErrors(value) {
      this.errors = [];
      const lines = [];
      var pattern =
        /^(.*)(Fim da Validade|número do contrato|empresa de trade|clientes)\.?(\d)?\.?([^ ]*)(.*)$/;

      for (const error in value) {
        const campo = error.split(".");

        if (lines.indexOf(campo[1]) < 0) {
          if (campo[0] === "clientes") {
            let erro = "";
            let linha = "";
            const textError = value[error][0];

            if (!campo[1]) {
              linha = "Arquivo csv";
              erro = value[error];
            } else {
              this.linha = campo[1];
              linha = "linha " + (parseInt(campo[1]) + 1);
              erro = [textError.replace(pattern, "$1$4$5")];
            }

            this.errors.push({
              linha: linha,
              errors: erro,
            });
            lines.push(campo[1]);
          } else {
            if (campo[1]) {
              if (this.errors[0]) {
                this.errors[0].errors[0] =
                  this.errors[0].errors[0] + " " + value[error];
              } else {
                this.errors.push({
                  linha: "Campos digitáveis ",
                  errors: value[error],
                });
              }
            }
          }
        } else {
          const index = this.errors
            .map(function (e) {
              return e.linha;
            })
            .indexOf("linha " + campo[1]);
          if (index >= 0) {
            const textError = value[error][0];
            this.errors[index].errors.push(
              textError.replace(pattern, "$1$4$5")
            );
          }
        }
      }
      this.showErrors = true;
    },

    submit() {
      this.$store.dispatch("start_submit");

      const routeId = this.$route.params.id;
      const contrato = {};
      contrato.empresa_trade_id = this.trade;
      contrato.numero_contrato = this.contrato;
      contrato.validade_inicio = this.validadeInicio;
      contrato.validade_final = this.validadeFinal;
      contrato.contrato = this.fileJSON;

      if (this.validadeDiferenca > 11) {
        this.$store.dispatch(
          "error",
          "Favor informar uma diferença de, no máximo, 12 meses"
        );
        this.$store.dispatch("stop_submit");
      } else if (this.validadeDiferenca < 0) {
        this.$store.dispatch("error", "Data inicial maior que a final");
        this.$store.dispatch("stop_submit");
      } else if (this.validadeDiferenca == 0 && false) {
        this.$store.dispatch("error", "Data inicial igual a final");
        this.$store.dispatch("stop_submit");
      } else if (!this.datasValidas) {
        this.$store.dispatch("error", "Verificar datas de validade inseridas");
        this.$store.dispatch("stop_submit");
      } else {
        if (routeId === undefined) {
          contratoService.API.Actions.create(contrato)
            .then((response) => {
              this.$store.dispatch("stop_submit");
              this.$store.dispatch("success", response.data.message);
              this.$router.push("/contratos");
            })
            .catch((error) => {
              this.$store.dispatch("stop_load");
              const errorArray = error.response.data.errors;

              this.$store.dispatch("stop_submit");
              // Object.values

              if (typeof errorArray === "object") {
                for (const [key, value] of Object.entries(errorArray)) {
                  this.$store.dispatch("error", value[0]);
                }
              }

              if (typeof errorArray === "string") {
                this.$store.dispatch("error", errorArray);
              }
              if (errorArray instanceof Array) {
                for (const error in errorArray) {
                  this.$store.dispatch("error", errorArray[error][0]);
                }
              }
            });
        } else {
          contratoService.API.Actions.update(routeId, contrato)
            .then((response) => {
              this.$store.dispatch("stop_submit");
              this.$store.dispatch("success", response.data.message);
              this.$router.push("/contratos");
            })
            .catch((error) => {
              this.$store.dispatch("stop_load");
              const errorArray = error.response.data.errors;
              this.$store.dispatch("stop_submit");
              if (typeof errorArray === "string") {
                this.$store.dispatch("error", errorArray);
              }
              if (errorArray instanceof Array) {
                for (const error in errorArray) {
                  this.$store.dispatch("error", errorArray[error][0]);
                }
              }
            });
        }
      }
    },
    excluir() {
      const routeId = this.$route.params.id;
      this.$store.dispatch("start_submit");

      contratoService.API.Actions.delete(routeId)
        .then((response) => {
          if (response.data.errors) {
            this.$store.dispatch("Erro", response.data.message);
          } else {
            this.$store.dispatch("Sucesso", response.data.message);
          }

          this.dialog = false;
          this.$router.push("/contratos");
          this.$store.dispatch("stop_submit");
        })
        .catch((error) => {
          const errorArray = error.response.data.errors;
          this.$store.dispatch("stop_submit");
          if (typeof errorArray === "string") {
            this.$store.dispatch("error", errorArray);
          }
          if (errorArray instanceof Array) {
            for (const error in errorArray) {
              this.$store.dispatch("error", errorArray[error][0]);
            }
          }
          this.dialog = false;
        });
    },

    limpar() {
      this.trade = "";
      this.errors = [];
      this.dados = [];
      this.contrato = "";
      this.buscar = "";
      this.validadeInicio = "";
      this.validadeFinal = "";
      this.file = [];
      this.fileJSON = "";
      this.dialog = false;
      this.showErrors = false;
    },

    importar() {
      this.fileJSON = "";
      Papa.parse(this.file, {
        header: true,
        complete: (results) => {
          const client = [];
          results.data.forEach((linha) => {
            const empty = Object.values(linha).filter((i) => i.length == 0);
            const content = Object.values(linha).filter((i) => i.length > 0);
            const count = Object.keys(linha);
            if (
              empty.length != content.length &&
              content.length > 0 &&
              count.length == 9
            ) {
              client.push(linha);
            }
          });

          this.dados = client;
          this.$refs.fileupload.reset();
        },
      });
      this.dialog = false;
    },

    listarEmpresas() {
      empresaService.API.Actions.options().then((response) => {
        response.data.forEach((element) => {
          this.tradeItems.push(element);
        });
        this.loadingTrade = false;
        this.disabledTrade = false;
      });
    },
    
    handleModalClienteError(error) {
      const errors = error.response.data.errors;
      this.$store.dispatch("stop_submit");
      
      if (typeof errors === "string") {
        this.$store.dispatch("error", errors);
      }
      if (errors instanceof Object) {
        for (const error in errors) {
          this.$store.dispatch("error", errors[error][0]);
          this.errosModalCliente[error] = errors[error];
        }
      }
    },

    addCliente() {
      this.loadingInformacoes = true;
      this.novoCliente.contratoId = this.contratoRequest.id;
      if (this.novoCliente.complemento == null) {
        this.novoCliente.complemento = "";
      }
      this.errosModalCliente = {};
      contratoService.API.Actions.addCliente(this.novoCliente)
        .then((response) => {
          this.$store.dispatch("success", response.data.message);
          this.showDialogCliente = false;
          this.$store.dispatch("start_load");
          const routeId = this.$route.params.id;
          contratoService.API.Actions.find(routeId, this.razaoFilter)
            .then((response) => {
              const clientes = response.data.clientes;
              this.contratoRequest = response.data.contrato;

              this.dados = clientes;
              this.fileJSON = JSON.stringify(clientes);
              this.trade = this.contratoRequest.empresa_trade_id;
              this.contrato = this.contratoRequest.numero_contrato;
              this.novoCliente.contratoId = this.contratoRequest.id;
              this.validadeInicio = this.contratoRequest.validade_inicio;
              this.validadeFinal = this.contratoRequest.validade_final;
              this.$store.dispatch("stop_load");
            })
            .catch((error) => {
              const errorArray = error.response.data.errors;
              this.$store.dispatch("stop_submit");
              if (typeof errorArray === "string") {
                this.$store.dispatch("error", errorArray);
              }
              if (errorArray instanceof Array) {
                for (const error in errorArray) {
                  this.$store.dispatch("error", errorArray[error][0]);
                }
              }
            });
        })
        .catch(this.handleModalClienteError)
        .finally(() => {
          this.loadingInformacoes = false;
        });
    },

    editarClienteFill(item) {
      this.addClienteVar = false;
      this.showDialogCliente = true;
      let tempCliente = { ...item };
      this.novoCliente = tempCliente;
      this.novoCliente.cnpjAntigo = this.novoCliente.cnpj;
      this.errosModalCliente = [];
    },

    editCliente() {
      this.loadingInformacoes = true;
      
      const routeId = this.$route.params.id;
      this.novoCliente.contratoId = routeId;

      contratoService.API.Actions.editCliente(this.novoCliente)
        .then((response) => {
          this.$store.dispatch("success", response.data.message);
          this.showDialogCliente = false;
          this.$store.dispatch("start_load");
          contratoService.API.Actions.find(routeId, this.razaoFilter)
            .then((response) => {
              const clientes = response.data.clientes;
              this.contratoRequest = response.data.contrato;
              this.dados = clientes;
              this.fileJSON = JSON.stringify(clientes);
              this.trade = this.contratoRequest.empresa_trade_id;
              this.contrato = this.contratoRequest.numero_contrato;
              this.validadeInicio = this.contratoRequest.validade_inicio;
              this.validadeFinal = this.contratoRequest.validade_final;
              this.$store.dispatch("stop_load");
            })
            .catch((error) => {
              this.$store.dispatch("error", error.response.data.errors);
            });
        })
        .catch(this.handleModalClienteError)
        .finally(() => {
          this.loadingInformacoes = false;
        });
    },
    checkDisabledValidade() {
      if (this.validadeInicio.length == 7) {
        this.disabledValidade = false;
      } else {
        this.disabledValidade = true;
      }
    },
    checkDiferencaValidade() {
      if (this.validadeFinal.length == 7) {
        let inicio = moment(this.validadeInicio, "MM/YYYY");
        let final = moment(this.validadeFinal, "MM/YYYY");
        this.datasValidas = inicio.isValid() && final.isValid();
        if (this.datasValidas) {
          this.validadeDiferenca = final.diff(inicio, "months", true);
        }
      }
    },
    uppercase($event) {
      $event.srcElement.value = $event.srcElement.value.toUpperCase();
    },
  },
  computed: {
    isActiveContract() {
      return this.disabledValidade;
    },
  },
};
</script>

<style>
.uppercase input {
  text-transform: uppercase !important;
}
.validade-datas .v-messages__message {
  color: rgb(255, 0, 0);
}
</style>
