import client from '../plugins/apiClient'

export default {
  API: {
    Actions: {
      list: (filter) => {
        client.login();
        return client.get("contratos", {
          params: filter,
        });
      },
      options: (empresaId) => {
        client.login();
        return client.get("contrato-options/" + empresaId);
      },
      create: (objeto) => {
        client.login();
        return client.post("contrato", objeto);
      },
      addCliente: (objeto) => {
        client.login();
        return client.post("contrato_add", objeto);
      },
      editCliente: (objeto) => {
        client.login();
        return client.post("edit_cliente", objeto);
      },
      delete: (id) => {
        client.login();
        return client.delete("contrato/" + id);
      },
      update: (id, objeto) => {
        client.login();
        return client.put("contrato/" + id, objeto);
      },
      find: (id, filter) => {
        client.login();
        return client.get("contrato/" + id + "/" + filter);
      },
    },
  },
};
