<template>
  <v-container style="display: grid">
    <!-- FILTRO -->
    <v-row>
      <v-col>
        <v-card>
          <v-row class="pl-4 pr-4 pt-4">
            <v-col
              cols="12"
              md="4"
              sm="6"
              v-if="
                ['Admin', 'Representante'].indexOf($store.getters.userType) > -1
              "
            >
              <v-autocomplete
                v-model="filter.trade"
                label="Empresa de Trade"
                @input="listarContratos($event), listarResponsaveis()"
                :items="tradeItems"
                outlined
                :loading="loadingTrade"
                :disabled="disabledTrade"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                v-model="filter.contrato"
                label="Contrato"
                @input="
                  listarClientes($event), listarMarcas($event), listarMeses()
                "
                :items="contratoItems"
                outlined
                :loading="loadingContrato"
                :disabled="disabledContrato"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                v-model="filter.cliente"
                label="Razão Social"
                :items="clienteItems"
                outlined
                :loading="loadingCliente"
                :disabled="disabledCliente"
              ></v-autocomplete>
            </v-col>
            <!-- MARCAS -->
            <v-col cols="12" md="2" sm="6">
              <v-autocomplete
                v-model="filter.marca"
                label="Marca"
                :items="marcaItems"
                outlined
                :loading="loadingMarca"
                @input="
                  (disabledDataInicial = false),
                    (disabledMes = false),
                    (disabledBotao = false),
                    (disabledDataFinal = false),
                    (disabledAnalista = false)
                "
                :disabled="disabledMarca"
              >
              </v-autocomplete> </v-col
            ><!-- /MARCAS -->
            <v-col cols="12" md="2" sm="3">
              <v-autocomplete
                v-model="filter.tasks_situation"
                label="Situação das tarefas"
                :items="tasks_situation"
                outlined
                :disabled="disabledContrato"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" sm="3">
              <v-autocomplete
                v-model="filter.mes"
                label="Mês da visita"
                :items="mesesItems"
                outlined
                :loading="loadingMes"
                :disabled="disabledMes"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" sm="3">
              <v-autocomplete
                v-model="filter.analista"
                label="Analista"
                :items="analistaFiltroList"
                outlined
                :loading="loadingAnalista"
                :disabled="disabledAnalista"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
              <v-menu
                v-model="menuInicial"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dateInicialFormatado"
                    label="Recebido De:"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :disabled="disabledDataInicial"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filter.dateInicial"
                  @input="menuInicial = false"
                  :disabled="disabledDataInicial"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2">
              <v-menu
                v-model="menuFinal"
                :close-on-content-click="false"
                nudge-left="115"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dateFinalFormatado"
                    label="Recebido Até:"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :disabled="disabledDataFinal"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filter.dateFinal"
                  @input="menuFinal = false"
                  :disabled="disabledDataFinal"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions class="pa-4 justify-end">
            <v-btn
              @click="search()"
              :disabled="!filterCompleted"
              color="primary"
              :loading="loadingReport"
            >
              Gerar Relatório
            </v-btn>
            <v-btn
              :disabled="!filterCompleted || loadingReport"
              v-if="isAdmin"
              @click="copyReportLinkToClipboard"
              title="Copiar link do relatório com os filtros aplicados"
              >GERAR LINK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col> </v-row
    ><!-- /FILTRO -->
    <!-- TABLE -->
    <v-row>
      <v-col>
        <v-card style="width: 100%">
          <div id="tabela">
            <v-data-table
              v-if="showTable == true"
              :headers="headers"
              :items="items"
              style="width: 100%"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>
                      <!-- <div style="text-align:center"> -->
                      <div>
                        <b> {{ item.dados.nome_fantasia }}<br /> </b>
                        {{ item.dados.razao_social }}<br />
                        {{ item.dados.logradouro }},
                        {{ item.dados.numero }} Bairro: {{ item.dados.bairro
                        }}<br />
                        {{ item.dados.cidade }} / {{ item.dados.estado }}<br />
                        <span v-if="isDev">
                          [{{ item.dados.clientes_id }} -
                          {{ item.dados.atlac }}]
                        </span>
                      </div>
                    </td>
                    <td>
                      {{ item.dados.mes.split("-")[1] }} -
                      {{ item.dados.visitas }}
                    </td>
                    <td>{{ item.dados.analista }}</td>
                    <td>{{ item.dados.marca }}</td>
                    <td>
                      <b>
                        {{ item.dados.descricao }}
                      </b>
                    </td>
                    <td v-if="item.respostas.length > 0">
                      {{ item.respostas[0].data_criado.split(" ")[0] }}
                    </td>

                    <td>
                      <div cols="12" style="width: 100%; display: flex">
                        <div
                          class="cols-12 sm-12 md-6"
                          style="flex-flow: col nowrap"
                        >
                          <div
                            v-for="(resposta, index) in item.respostas"
                            :key="index"
                          >
                            <div
                              class="pb-4"
                              v-if="resposta.foto_antes == true"
                              style="
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                              "
                            >
                              <div style="float: left" class="pr-2">
                                <b> Foto Antes: <br /> </b>
                                <span
                                  v-if="resposta.resposta != 'Sem foto antes'"
                                >
                                  Data: {{ resposta.data_hora.split(" ")[0]
                                  }}<br />
                                  Hora: {{ resposta.data_hora.split(" ")[1] }}
                                </span>
                                <v-tooltip
                                  bottom
                                  v-if="
                                    resposta.data_diff > 0 &&
                                    resposta.resposta != 'Sem foto antes'
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      color="red"
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      mdi-exclamation-thick
                                    </v-icon>
                                  </template>
                                  <span
                                    >Diferença entre criação e envio é superior
                                    a 24 horas. ({{
                                      resposta.data_criado
                                    }})</span
                                  >
                                </v-tooltip>
                                <br />

                                <!-- </div> -->

                                <span
                                  v-if="resposta.resposta != 'Sem foto antes'"
                                >
                                  Localização:
                                  <a
                                    :id="`https://maps.google.com/?q=${resposta.geolocalizacao}`"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    :href="`https://maps.google.com/?q=${resposta.geolocalizacao}`"
                                    ><span class="mdi mdi-google-maps"></span
                                  ></a>
                                </span>
                              </div>
                              <div
                                v-if="resposta.resposta != 'Sem foto antes'"
                                @click="dialogMetodo(item.respostas, resposta)"
                              >
                                <ImageLoader
                                  width="100px"
                                  height="100px"
                                  :src="resposta.resposta"
                                />
                              </div>
                              <div
                                v-if="resposta.resposta == 'Sem foto antes'"
                                class="d-flex align-center"
                                style="width: 100px; margin-left: 1px"
                              >
                                <b>
                                  Não foram registradas fotos antes para essa
                                  tarefa.
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- == -->
                        <div
                          class="cols-12 sm-12 md-6"
                          style="flex-flow: col nowrap"
                        >
                          <div
                            v-for="(resposta, index) in item.respostas"
                            :key="index"
                          >
                            <div
                              class="pb-4"
                              v-if="resposta.foto_depois == true"
                              style="
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                              "
                            >
                              <div style="float: left" class="pr-2">
                                <b> Foto Depois: <br /> </b>
                                Data: {{ resposta.data_hora.split(" ")[0] }}
                                <br />
                                Hora: {{ resposta.data_hora.split(" ")[1] }}
                                <v-tooltip bottom v-if="resposta.data_diff > 0">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      color="red"
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      mdi-exclamation-thick
                                    </v-icon>
                                  </template>
                                  <span
                                    >Diferença entre criação e envio é superior
                                    a 24 horas. ({{
                                      resposta.data_criado
                                    }})</span
                                  >
                                </v-tooltip>
                                <br />
                                Localização:
                                <a
                                  :id="`https://maps.google.com/?q=${resposta.geolocalizacao}`"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  :href="`https://maps.google.com/?q=${resposta.geolocalizacao}`"
                                  ><span class="mdi mdi-google-maps"></span
                                ></a>
                              </div>
                              <div
                                @click="dialogMetodo(item.respostas, resposta)"
                                v-if="resposta.resposta != 'Sem foto antes'"
                              >
                                <ImageLoader
                                  width="100px"
                                  height="100px"
                                  :src="resposta.resposta"
                                />
                              </div>
                            </div>
                            <div
                              class="pb-4"
                              style=""
                              v-if="
                                resposta.foto_antes != true &&
                                resposta.foto_depois != true
                              "
                            >
                              <b>DESCARTE</b><br />
                              Motivo: {{ resposta.resposta }}<br />
                              Data: {{ resposta.data_hora.split(" ")[0] }}<br />
                              Hora: {{ resposta.data_hora.split(" ")[1] }}<br />
                              Localização:
                              <a
                                :id="`https://maps.google.com/?q=${resposta.geolocalizacao}`"
                                target="_blank"
                                rel="noopener noreferrer"
                                :href="`https://maps.google.com/?q=${resposta.geolocalizacao}`"
                                ><span class="mdi mdi-google-maps"></span
                              ></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>{{ item.dados.comentario }}<br /></div>
                    </td>
                    <td v-if="isAdmin">
                      <v-btn
                        icon
                        title="Apagar essa tarefa"
                        @click="showDeleteModal(item.dados.atlac)"
                        ><v-icon> mdi-delete </v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
                <template>
                  <div class="text-center">
                    <v-dialog
                      v-model="dialogImg"
                      width="80%"
                      @keydown.esc="dialogImg = false"
                      tabindex="0"
                      value="dialogImg"
                      scrollable
                    >
                      <v-card class="text-center">
                        <v-card-actions class="d-flex flex-row-reverse">
                          <v-icon color="error" @click="dialogImg = false">
                            mdi-close-thick
                          </v-icon>
                        </v-card-actions>
                        <template>
                          <v-carousel
                            v-model="imageDialog"
                            height="auto"
                            style="width: max-content"
                            class="d-flex justify-center align-self-center align-center"
                            :show-arrows="false"
                          >
                            <v-carousel-item
                              v-for="(item, index) in imageDialogLista"
                              :key="index"
                              height="100%"
                            >
                              <ImageLoader :src="item.resposta" />
                            </v-carousel-item>
                          </v-carousel>
                        </template>
                        <div
                          class="pa-4"
                          style="display: flex; justify-content: center"
                        >
                          <b
                            class="text-center"
                            v-if="imageDialogStatus[imageDialog]?.antes"
                          >
                            Antes
                          </b>
                          <b
                            class="text-center"
                            v-if="imageDialogStatus[imageDialog]?.depois"
                          >
                            Depois
                          </b>
                        </div>

                        <div class="v-window__prev">
                          <v-btn
                            @click="mudarImagem('anterior')"
                            class="v-btn v-btn--icon v-btn--round theme--dark v-size--default"
                            type="button"
                            aria-label="Visão anterior"
                          >
                            <span class="v-btn__content">
                              <i
                                class="v-icon notranslate mdi mdi-chevron-left theme--dark"
                                aria-hidden="true"
                                style="font-size: 36px"
                              ></i>
                            </span>
                          </v-btn>
                        </div>
                        <div class="v-window__next">
                          <v-btn
                            @click="mudarImagem('proxima')"
                            class="v-btn v-btn--icon v-btn--round theme--dark v-size--default"
                            type="button"
                            aria-label="Próxima visão"
                          >
                            <span class="v-btn__content">
                              <i
                                class="v-icon notranslate mdi mdi-chevron-right theme--dark"
                                aria-hidden="true"
                                style="font-size: 36px"
                              ></i>
                            </span>
                          </v-btn>
                        </div>
                      </v-card>
                    </v-dialog>
                  </div>
                </template>
              </template>
            </v-data-table>
          </div>
          <v-row justify="center">
            <v-dialog v-model="deleteTask.visible" scrollable width="500">
              <v-card>
                <v-card-title class="pa-0">
                  <v-toolbar color="#F5F5F5">
                    <v-toolbar-title
                      class="pr-4"
                      style="color: #1a1a1a; font-size: 17px; font-weight: bold"
                    >
                      Confirmar EXCLUSÃO dessa visita?
                    </v-toolbar-title>
                    <slot name="btnTitle" />
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      dark
                      :disabled="deleteTask.loading"
                      @click="deleteTask.visible = false"
                      color="#1a1a1a"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                </v-card-title>
                <v-card-text class="mt-6">
                  <p class="font-weight-black">
                    Esta ação irá realizar a exclusão dos registros no
                    "Relatório - Tabela de Tarefas" e no "Relatório - Tabela de
                    Questões", confirma a exclusão destes registros para esta
                    visita?
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-row no-gutters justify="end">
                    <v-btn
                      class="mx-2"
                      :disabled="deleteTask.loading"
                      @click="deleteTask.visible = false"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      class="mx-2"
                      :loading="deleteTask.loading"
                      color="error"
                      @click="handleDelete"
                      >Confirmar EXCLUSÃO</v-btn
                    >
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- /TABLE -->
  </v-container>
</template>
<script>
import empresaService from "../../../services/empresaService";
import relatorioService from "../../../services/relatorioService";
import contratoService from "../../../services/contratoService";
import acoesService from "../../../services/acoesService";
import clientesService from "../../../services/clientesService";
import ImageLoader from "../../../components/DakotaImageLoader.vue";
import moment from "moment";

export default {
  data: () => ({
    filter: {
      marca: "Todos",
      cliente: "",
      trade: "",
      contrato: "",
      dateInicial: "",
      dateFinal: "",
      analista: "Todos",
      mes: "Todos",
      tasks_situation: 1,
    },
    tasks_situation: [
      {
        value: 1,
        text: "Todos",
      },
      {
        value: 2,
        text: "Realizadas",
      },
      {
        value: 3,
        text: "Descartadas",
      },
    ],
    headers: [
      {
        text: "Loja",
        align: "start",
        sortable: false,
      },
      {
        text: "Mês da visita",
        sortable: false,
        align: "start",
      },
      {
        text: "Analista",
        sortable: false,
        align: "start",
      },
      {
        text: "Marca",
        sortable: false,
        align: "start",
      },
      {
        text: "Tarefa",
        sortable: false,
        align: "start",
      },
      {
        text: "Recebido",
        sortable: false,
        align: "start",
      },
      {
        text: "Resposta",
        sortable: false,
        align: "center",
      },
      {
        text: "Comentario",
        sortable: false,
        align: "start",
      },
    ],
    deleteTask: {
      loading: false,
      visible: false,
      atlac_id: 0,
    },
    menuInicial: false,
    menuFinal: false,
    tradeItems: [],
    marcaItems: [],
    contratoItems: [],
    mesesItems: ["Todos"],
    analistaFiltroList: ["Todos"],
    clienteItems: ["Todos"],
    loadingCliente: false,
    loadingContrato: false,
    loadingMarca: false,
    loadingTrade: true,
    loadingReport: false,
    loadingAnalista: false,
    loadingMes: false,
    disabledAnalista: true,
    disabledBotao: true,
    disabledContrato: true,
    disabledMes: true,
    disabledCliente: true,
    disabledMarca: true,
    disabledTrade: true,
    disabledDataInicial: true,
    disabledDataFinal: true,
    showTable: false,
    relatorio: [],
    dialogImg: false,
    imageDialog: 0,
    imageDialogLista: [],
    imageDialogStatus: [{ antes: false, depois: false }],
  }),
  methods: {
    async listarEmpresas() {
      await empresaService.API.Actions.options().then((response) => {
        response.data.forEach((element) => {
          this.tradeItems.push(element);
        });
        this.loadingTrade = false;
        this.disabledTrade = false;
        // PROCEDIMENTO PARA PREENCHER O FILTRO QUANDO RECEBER QUERYSTRING
        if (this.filterQueryString?.trade) {
          this.filter.trade = +this.filterQueryString.trade;
          // PARA OS USUÁRIOS DO TIPO REPRESENTANTE
          // SE O RECEBIMENTO DAS EMPRESAS DE TRADE NÃO TROUXER A EMRPESA DE TRADE DA QUERY STRING
          // NÃO EXECUTE O CARREGAMENTO DE CONTRATOS
          if (
            this.tradeItems.filter((i) => i.value === this.filter.trade).length
          ) {
            this.listarContratos();
          }
        }
      });
    },
    async listarMarcas() {
      this.marcaItems = [];
      this.loadingMarca = true;
      this.filter.cliente = "Todos";

      let context = {};
      if (["Representante"].indexOf(this.$store.getters.userType) > -1) {
        context = {
          params: {
            empresaTradeId: this.filter.trade,
          },
        };
      }
      await relatorioService.API.Actions.getMarcas(
        this.filter.cliente,
        this.filter.contrato,
        context
      ).then(({ data }) => {
        this.marcaItems = [...data].sort((a, b) =>
          a.text.toUpperCase() > b.text.toUpperCase() ? 1 : -1
        );
        // PROCEDIMENTO PARA PREENCHER O FILTRO QUANDO RECEBER QUERYSTRING
        if (this.filterQueryString?.marca) {
          this.filter.marca = this.filterQueryString.marca;
          this.listarResponsaveis();
        }
      });
      this.disabledMarca = false;
      this.loadingMarca = false;
    },
    listarContratos() {
      this.contratoItems = [];
      this.marcaItems = [];
      this.loadingContrato = true;
      this.filter.contrato = [];
      this.filter.marca = [];
      contratoService.API.Actions.options(this.filter.trade)
        .then((response) => {
          this.contratoItems = response.data.contratos;
          this.contratoItems.forEach((element, key) => {
            this.contratoItems[key].text =
              element.text + " - " + element.total + " Clientes";
          });

          this.loadingContrato = false;
          this.disabledContrato = false;
          // PROCEDIMENTO PARA PREENCHER O FILTRO QUANDO RECEBER QUERYSTRING
          if (this.filterQueryString?.contrato) {
            this.filter.contrato = +this.filterQueryString.contrato;
            this.filter.tasks_situation = Number(
              this.filterQueryString.tasks_situation
            );
            this.listarClientes();
            this.listarMarcas();
            this.listarMeses();
            this.listarResponsaveis();
            this.filter.dateInicial = this.filterQueryString.dateInicial;
            this.filter.dateFinal = this.filterQueryString.dateFinal;
            this.disabledDataInicial = false;
            this.disabledDataFinal = false;
          }
        })
        .catch((e) => {
          this.loadingContrato = false;
          this.disabledContrato = true;
        });
    },
    listarMeses() {
      this.mesesItems = [];
      this.loadingMes = true;
      relatorioService.API.Actions.getMeses(this.filter.contrato)
        .then((response) => {
          this.mesesItems = response.data;

          this.loadingMes = false;
          this.disabledMes = false;
          // PROCEDIMENTO PARA PREENCHER O FILTRO QUANDO RECEBER QUERYSTRING
          if (this.filterQueryString?.mes) {
            this.filter.mes = this.filterQueryString.mes;
          }
        })
        .catch(() => {
          this.loadingMes = false;
          this.disabledMes = true;
        });
    },
    listarResponsaveis() {
      this.analistaFiltroList = ["Todos"];
      clientesService.API.Actions.analista_options(this.filter.trade)
        .then((response) => {
          response.data.forEach((element) => {
            this.analistaFiltroList.push(element);
          });
          this.loadingAnalista = false;
          // PROCEDIMENTO PARA PREENCHER O FILTRO QUANDO RECEBER QUERYSTRING
          if (this.filterQueryString?.analista) {
            this.filter.analista = +this.filterQueryString.analista || "Todos";
            this.disabledAnalista = false;
          }
        })
        .catch((error) => {
          this.loadingAnalista = false;
          const errors = error;
          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
    },
    listarClientes() {
      this.clienteItems = ["Todos"];
      this.filter.cliente = "Todos";
      this.loadingCliente = true;
      this.filter.cliente = [];
      relatorioService.API.Actions.getClientes(this.filter.contrato).then(
        (response) => {
          response.data.clientes.forEach((element) => {
            if (this.$store.getters.userType == "Admin") {
              element.text = `${element.value} - ${element.text}`;
            }
            this.clienteItems.push(element);
          });
          this.loadingCliente = false;
          this.disabledCliente = false;
          if (
            this.filterQueryString?.cliente &&
            this.filterQueryString.cliente != "Todos"
          ) {
            this.filter.cliente = +this.filterQueryString.cliente;
          }
        }
      );
    },
    search(data) {
      this.showTable = false;
      this.items = [];
      this.loadingReport = true;
      relatorioService.API.Actions.getTabelaAcoes(data || this.filter)
        .then((response) => {
          this.items = response.data;
          this.items.forEach((item) => {
            if (!Array.isArray(item.respostas)) {
              item.respostas = Array.of(item.respostas);
            }
            if (typeof item.respostas[0] == "undefined") {
              console.warn(item.respostas);
            }
            item.respostas.forEach((element) => {
              element.data_hora =
                element.data_hora.split("/")[1] +
                "/" +
                element.data_hora.split("/")[0] +
                "/" +
                element.data_hora.split("/")[2];

              element.data_criado =
                element.data_criado.split("/")[1] +
                "/" +
                element.data_criado.split("/")[0] +
                "/" +
                element.data_criado.split("/")[2];
            });
          });
          this.showTable = true;
          this.loadingReport = false;
        })
        .catch((error) => {
          let errorMessage = "Erro interno, comunique o administrador";
          if (error.response?.data.message) {
            errorMessage = error.response.data.message + "\n";
            if (error.response.data?.errors) {
              errorMessage += Object.values(error.response.data.errors)
                .map((v) => `- ${v}`)
                .join("\n");
            }
          }
          this.$store.dispatch("error", errorMessage);
          this.$store.dispatch("stop_submit");
          this.loadingReport = false;
        });
    },
    mudarImagem(direcao) {
      if (this.imageDialogLista.length > 1) {
        if (direcao == "anterior") {
          const prevIndex =
            (this.imageDialog - 1) % this.imageDialogLista.length;
          this.imageDialog = prevIndex;
        } else if (direcao == "proxima") {
          const nextIndex =
            (this.imageDialog + 1) % this.imageDialogLista.length;
          this.imageDialog = nextIndex;
        }
      }
    },
    dialogMetodo(list, input) {
      this.imageDialogLista = [];
      this.imageDialogStatus = [];
      this.dialogImg = true;
      // this.imageDialog = list.indexOf(input)

      list.forEach((element) => {
        if (element.resposta != "Sem foto antes") {
          this.imageDialogLista.push(element);
        }
      });

      this.imageDialog = this.imageDialogLista.indexOf(input);
      // this.imageDialogLista = list

      this.imageDialogLista.forEach((status) => {
        this.imageDialogStatus.push({
          antes: status.foto_antes,
          depois: status.foto_depois,
        });
      });
      // this.imageDialogLista.map((dado) => {
      //   this.imageDialogStatus.antes = dado.foto_antes;
      //   this.imageDialogStatus.depois = dado.foto_depois;
      // })

      // this.imageDialogStatus.antes = list[this.imageDialog].foto_antes
      // this.imageDialogStatus.depois = list[this.imageDialog].foto_depois
    },
    moment: function () {
      return moment();
    },
    showDeleteModal(id_atlac) {
      this.deleteTask.visible = true;
      this.deleteTask.atlac_id = id_atlac;
    },
    handleDelete() {
      this.$store.dispatch("start_submit");
      this.deleteTask.loading = true;
      this.showTable = false;

      relatorioService.API.Actions.visitasDelete({
        atlacId: this.deleteTask.atlac_id,
      })
        .then((response) => {
          this.$store.dispatch("success", response.data.message);
        })
        .catch((error) => {
          let errorMessage = "Erro interno, comunique o administrador";
          if (error.response?.data.message) {
            errorMessage = error.response.data.message + "\n";
            if (error.response.data?.errors) {
              errorMessage += Object.values(error.response.data.errors)
                .map((v) => `- ${v}`)
                .join("\n");
            }
          }
          this.$store.dispatch("error", errorMessage);
        })
        .finally(() => {
          this.$store.dispatch("stop_submit");
          this.deleteTask.loading = false;
          this.deleteTask.visible = false;
          this.search();
        });
    },
    async copyReportLinkToClipboard() {
      try {
        const text = new URLSearchParams(this.filter).toString();
        const url = `${location.origin}${location.pathname}?${text}`;
        await navigator.clipboard.writeText(url);
        this.$store.dispatch(
          "success",
          "Link do relatório copiado para a área de transferência"
        );
      } catch (err) {
        this.$store.dispatch("error", "Falha ao copiar o link");
      }
    },
  },
  mounted() {
    if (Object.values(this.$route.query).length) {
      this.filterQueryString = this.$route.query;
      this.search(this.filterQueryString);
    }
    let self = this;
    window.addEventListener("keyup", function (event) {
      if (event.keyCode === 27) {
        self.dialogImg = false;
      }
    });

    // this.listarResponsaveis();
    this.filter.dateInicial = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);
    this.filter.dateFinal = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);
    if (["Trade"].indexOf(this.$store.getters.userType) > -1) {
      this.filter.trade = this.$store.getters.userEmpresaId;
      this.listarContratos();
      this.listarResponsaveis();
    } else {
      this.listarEmpresas();
    }

    if (this.isAdmin) {
      this.headers.push({
        text: "Ações",
        sortable: false,
        align: "end",
      });
    }
  },
  computed: {
    dateInicialFormatado() {
      return this.filter.dateInicial
        ? moment(this.filter.dateInicial).format("DD/MM/YYYY")
        : "";
    },
    dateFinalFormatado() {
      return this.filter.dateFinal
        ? moment(this.filter.dateFinal).format("DD/MM/YYYY")
        : "";
    },
    isDev() {
      return this.$store.getters.userLogin.substr(0, 2).toLowerCase() === "kw";
    },
    isAdmin() {
      return this.$store.getters.userType === "Admin";
    },
    filterCompleted() {
      return (
        !this.disabledContrato &&
        !this.disabledAnalista &&
        !this.disabledDataInicial &&
        !this.disabledDataFinal &&
        !this.disabledMarca
      );
    },
  },
  watch: {
    dialogImg: function (newVal) {
      if (!newVal) {
        setTimeout(() => {
          this.imageDialogPergunta = [];
          this.imageDialogLista = [];
          this.imageDialogStatus = [];
        }, 1000);
      }
    },
  },
  components: { ImageLoader },
};
</script>
<style scoped>
.cara,
.cara .v-window__container,
.cara .v-carousel__item {
  height: auto !important;
}

.v-window__container {
  height: 0;
}
.v-card > :last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}
</style>
