<template>
  <div>
    <v-navigation-drawer v-model="drawer" app clipped>
      <router-link :to="baseMenu">
        <v-list-item link>
          <div class="pa-4">
            <v-img :src="require('../assets/logo.png')" contain width="70%" />
          </div>
        </v-list-item>
      </router-link>
      <v-list dense nav>
        <router-link
          v-for="item in menu"
          :key="item.title"
          :to="item.path"
          style="text-decoration: none; color: inherit"
        >
          <!--  -->
          <v-list-item link v-if="item.title != 'Relatórios'">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!--  -->
          <v-list-group v-if="item.title == 'Relatórios'">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <router-link
              v-for="item in relatorioMenu"
              :key="item.title"
              :to="item.path"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item link v-if="item.title != 'Relatórios'">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list-group>

          <!--  -->
        </router-link>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app clipped-left color="primary">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="white--text"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            tile
            v-on="on"
            depressed
            color="primary"
            class="rounded-pill"
          >
            <v-icon class="mr-2">mdi-account</v-icon>
            {{ user_name }}
          </v-btn>
        </template>
        <v-list class="pa-2">
          <v-list-item link @click="Opcoes">
            <v-list-item-icon>
              <v-icon>mdi-lock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Opções</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card elevation="2" color="error">
            <v-list-item link @click="logout">
              <v-list-item-icon>
                <v-icon class="white--text">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="white--text">Sair</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-container
      ><v-breadcrumbs class="pl-0" color="white" :items="breadcrumbs"> </v-breadcrumbs
    ></v-container>
    <div>
      <v-overlay :value="$store.getters.loading" absolute opacity="0.15">
        <v-progress-circular
          :size="70"
          :width="7"
          color="grey"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <slot> </slot>
    </div>
  </div>
</template>
<script>


import relatorioRouter from '../modules/relatorio/router';
const first = (array) => array.length ? array[0] : array;

const rotaVisitaContrato = first(relatorioRouter.find(rota => rota.name == "VisitasContrato"));

export default {
  name: "dakota-menu",
  methods: {
    logout: function () {
      this.$store.commit("logout");
      return this.$router.push({ name: "Login" });
    },

    Opcoes: function () {
      return this.$router.push({ name: "Opcoes" });
    },

    teste: function () {
      this.$store.commit("logout");
      return this.$router.push({ name: "Login" });
    },
  },

  computed: {
    breadcrumbs: function () {
      return this.$route.meta.breadcrumbs;
    },

    menu: function () {
      return this.items.filter((item) => {
        return item.permissao.includes(this.$store.getters.userType);
      });
    },
    relatorioMenu: function () {
      return this.relatorioItens.filter((item) => {
        return item.permissao.includes(this.$store.getters.userType);
      });
    },

    user_name: function () {
      return this.$store.getters.userName;
    },
  },

  data: () => ({
    drawer: true,
    baseMenu: "/",
    items: [
      {
        title: "Empresas de Trade",
        icon: "mdi-domain",
        path: { name: "Empresas" },
        permissao: ["Admin"],
      },
      {
        title: "Usuários",
        icon: "mdi-account-multiple-outline",
        path: { name: "Usuarios" },
        permissao: ["Admin"],
      },
      {
        title: "Tarefas",
        icon: "mdi-clipboard-check-outline",
        path: { name: "Tarefas" },
        permissao: ["Admin"],
      },
      {
        title: "Questões",
        icon: "mdi-comment-question-outline",
        path: { name: "Questoes" },
        permissao: ["Admin"],
      },
      {
        title: "Contratos de Clientes",
        icon: "mdi-file-document-multiple-outline",
        path: { name: "Contratos" },
        permissao: ["Admin"],
      },
      {
        title: "Ações de Trade",
        icon: "mdi-gesture-double-tap",
        path: { name: "Acoes" },
        permissao: ["Admin"],
      },
      {
        title: "Logins de Acesso ao App",
        icon: "mdi-cellphone-cog",
        path: { name: "Acessos" },
        permissao: ["Admin", "Trade"],
      },
      {
        title: "Analistas de Ações de Trade",
        icon: "mdi-account-tie",
        path: { name: "Clientes" },
        permissao: ["Admin", "Trade"],
      },
      {
        title: "Relatórios",
        icon: "mdi-clipboard-text-multiple ",
        path: {},
        permissao: ["Admin", "Trade", "Representante"],
      },
    ],
    relatorioItens: [
      // {
      //   title: 'Agrupado por Razão Social',
      //   icon: 'mdi-circle-small',
      //   path: { name: 'Agrupado por Razão Social' },
      //   permissao: ['Admin', 'Trade']
      // },
      {
        title: "Tabela de Tarefas",
        icon: "mdi-circle-small",
        path: { name: "Tabela de Tarefas" },
        permissao: ["Admin", "Trade", "Representante"],
      },
      {
        title: "Tabela de Questões",
        icon: "mdi-circle-small",
        path: { name: "Tabela de Quiz" },
        permissao: ["Admin", "Trade", "Representante"],
      },
      {
        title: "Visitas do Contrato",
        icon: "mdi-circle-small",
        path: { name: "VisitasContrato" },
        permissao: rotaVisitaContrato.meta.permissoes,
      },
    ],
  }),
};
</script>
