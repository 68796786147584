import client from "../plugins/apiClient";

export default {
  API: {
    Actions: {
      list: (filter) => {
        client.login();
        return client.get("acoes_trade", {
          params: filter,
        });
      },
      create: (objeto) => {
        client.login();
        return client.post("acao_trade", objeto);
      },
      delete: (id) => {
        client.login();
        return client.delete("acao_trade/" + id);
      },
      update: (id, objeto) => {
        client.login();
        return client.put("acao_trade/" + id, objeto);
      },
      alterarStatus: (id, objeto) => {
        client.login();
        return client.put("alterarStatus/" + id, objeto);
      },
      find: (id) => {
        client.login();
        return client.get("acao_trade/" + id);
      },
      marcas: () => {
        client.login();
        return client.get("marcas");
      },
      marcasAcao: (contrato) => {
        client.login();
        return client.get("marcas-acao/" + contrato);
      },
    },
  },
};
