import client from '../plugins/apiClient'

export default {
  API: {
    Actions: {
      list: () => {
        client.login()
        return client.get('tarefas')
      },
      options: () => {
        client.login()
        return client.get('tarefas-options')
      },
      create: (objeto) => {
        client.login()
        return client.post('tarefa', objeto)
      },
      delete: (id) => {
        client.login()
        return client.delete('tarefa/' + id)
      },
      update: (id, objeto) => {
        client.login()
        return client.put('tarefa/' + id, objeto)
      },
      find: (id) => {
        client.login()
        return client.get('tarefa/' + id)
      }
    }
  }
}
