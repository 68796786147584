import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import pt from 'vuetify/lib/locale/pt'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#007cc3',
        secondary: '#6ed5c2'
        // #a9e6db
      }
    }
  },
  lang: {
    locales: { pt },
    current: 'pt'
  }
})
