<template>
  <v-container fluid>
    <v-card>
      <v-form @submit.prevent="submit" class="pa-4">
        <v-row>
          <v-col cols='12' md='4'>
            <v-autocomplete dense hide-details="auto" v-model='trade' label='Empresa de Trade'
              class='obrigatorio' @input='selecionouEmpresa($event) , $v.trade.$touch' :items='tradeItems' outlined
              required :error-messages='tradeErrors' :loading='loadingTrade'
              :disabled='disabledTrade'></v-autocomplete>
          </v-col>
          <v-col cols='12' md='4'>
            <v-autocomplete dense hide-details="auto" v-model='marca' label='Marca' class='obrigatorio'
              :items='marcaItems' outlined required :error-messages='marcaErrors' @input='$v.marca.$touch'
              :loading='loadingMarca'
              :disabled='disabledMarca'></v-autocomplete>
          </v-col>
          <v-col cols='12' md='4'>
            <v-autocomplete dense hide-details="auto" v-model='contrato' label='Contrato de Clientes'
              class='obrigatorio' :disabled='contratoStatus' :loading='contratoCarregando'
              :items='contratoItems' outlined required :error-messages='contratoErrors' @input='$v.contrato.$touch'
              @change="contagem() , calcularVisitas(), meses= []"
              :hint="contagemValue"
              persistent-hint
              ></v-autocomplete>
          </v-col>
          <!-- <v-card v-show="showCheckbox" class="ml-4 pa-4 mb-2" outlined> -->
            <!-- <div>
              Visitas
            </div> -->
            <v-col cols='4' md='4' v-if="showCheckbox">
              <v-autocomplete
                v-model="meses"
                :items="validadeDiferenca"
                outlined
                dense
                chips
                small-chips
                label="Visitas"
                multiple
              ></v-autocomplete>
              <!-- <div v-for="mes in validadeDiferenca">
                <v-checkbox class="mr-1"
                  :label="mes"
                  :value="mes.split('/')[0]"
                  v-model="meses"
                ></v-checkbox>
              </div> -->
            </v-col>
          <!-- </v-card> -->

          <v-col cols='12' md='12' class="py-0">
            <v-switch v-if='routeId === undefined' v-model="status" color="primary" :loading='$store.getters.loading'
              :label="`Status: ${status ? 'Ativo':'Inativo'}`" class="mt-0">
            </v-switch>
            <label v-if='routeId !== undefined'><b>Status: </b>{{`${status ? 'Ativo':'Inativo'}`}}</label>
          </v-col>

        </v-row>
        <v-row>
          <v-col cols=12 md=6>
            <h4 class='pa-2'>Tarefas:</h4>
            <v-row v-for="(indiceTarefa,key) in tarefas" v-bind:key="key + 'tarefa'">
              <template v-if="key == (tarefas.length - 1)">
                <v-col cols='12' md='6'>
                  <v-autocomplete v-model='indiceTarefa.id'
                    label='Tarefa' class='obrigatorio'
                    :items='tarefasList'
                    prepend-icon='mdi-plus'
                    @click:prepend="addTarefa()"
                    outlined
                    dense
                    hide-details="auto"
                    required
                    :error-messages='tarefaErrors'
                    @input='$v.tarefas.$touch'
                    :loading='loadingTarefa'
                    :disabled='disabledTarefa'>
                  </v-autocomplete>
                </v-col>
                <v-col cols='12' md='6'>
                  <v-text-field v-model='indiceTarefa.observacao'
                    label='Observação'
                    dense
                    hide-details="auto"
                    outlined
                    >
                  </v-text-field>
                </v-col>
              </template>
              <!-- Os demais campos -->
              <template v-else>
                <v-col cols='12' md='6'>
                  <v-autocomplete v-model='indiceTarefa.id'
                    label='Tarefa'
                    prepend-icon="mdi-minus"
                    @click:prepend="removeTarefa(key)"
                    class='obrigatorio'
                    :items='tarefasList'
                    outlined
                    dense
                    hide-details="auto"
                    required
                    :error-messages='tarefaErrors'
                    @input='$v.tarefas.$touch'>
                  </v-autocomplete>
                </v-col>
                <v-col cols='12' md='6'>
                  <v-text-field v-model='indiceTarefa.observacao'
                    label='Observação'
                    outlined
                    dense
                    hide-details="auto"
                    required>
                  </v-text-field>
                </v-col>
              </template>
            </v-row>
          </v-col>
          <v-divider vertical class='d-none d-md-flex'></v-divider>
          <v-col cols=12 md=6>
            <h4 class='pa-2'>Questões:</h4>
            <v-row v-for="(indiceQuestao,key) in questoes" v-bind:key="key + 'questao'">
              <template v-if="key == (questoes.length - 1)">
                <v-col cols='12' md='12'>
                  <v-autocomplete v-model='indiceQuestao.id' label='Questão' dense hide-details="auto"
                    class='obrigatorio'
                    :items='questoesList'
                    prepend-icon="mdi-plus"
                    :hint="`${indiceQuestao.questao ? indiceQuestao.questao : ''}`"
                    @click:prepend="addQuestao()"
                    @change="indiceQuestao.ordem = key , indiceQuestao.questao = questoesList[questoesList.findIndex(obj => obj.value === indiceQuestao.id)].text"
                    outlined
                    persistent-hint
                    required
                    :error-messages='questoesErrors'
                    @input='$v.questoes.$touch'
                    :loading='loadingQuestao'
                    :disabled='disabledQuestao'
                    >
                  </v-autocomplete>
                </v-col>
              </template>
              <!-- Os demais campos -->
              <template v-else>
                <v-col cols='12' md='12'>
                  <v-autocomplete v-model='indiceQuestao.id' label='Questão' dense hide-details="auto"
                    class='obrigatorio'
                    :items='questoesList'
                    prepend-icon="mdi-minus"
                    @click:prepend="removeQuestao(key)"
                    :hint="`${indiceQuestao.questao ? indiceQuestao.questao : ''}`"
                    @change="indiceQuestao.ordem = key , indiceQuestao.questao = questoesList[questoesList.findIndex(obj => obj.value === indiceQuestao.id)].text"
                    outlined
                    persistent-hint
                    required
                    :error-messages='questoesErrors'
                    @input='$v.questoes.$touch'>
                  </v-autocomplete>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
        <v-col md='12' col='12'>
          <small>
            <span class='legenda fomnst-size'>
              Campo Obrigatório
            </span>
          </small>
        </v-col>
        <v-divider></v-divider>
          <v-row>
            <v-spacer></v-spacer>
            <v-dialog v-model='dialog' content-class='modal-delete'>
              <v-card>
                <v-card-title>
                  Excluir o registro?
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class='blue-grey lighten-5'>
                  A exclusão desse registro é permanente e sem retorno.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" text @click='dialog = false'>
                    Não
                  </v-btn>
                  <v-btn color='error' @click='excluir'>
                    Sim
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model='dialogStatus' content-class='modal-delete'>
              <v-card>
                <v-card-title>
                  Alterar Status?
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class='blue-grey lighten-5'>
                  Realmente deseja alterar o status desta ação?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text @click='dialogStatus = false'>
                    Não
                  </v-btn>
                  <v-btn color='success' @click='alterarStatus'>
                    Sim
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-col cols='2' md='6' class='d-none d-sm-flex pt-6'>
              <router-link to=/acoes> <v-btn color='secondary' outlined>
                Voltar
                </v-btn>
              </router-link>
            </v-col>
            <v-col class='text-right pt-6' cols='10' md='6'>
              <v-btn @click='dialogStatus = true' class='mr-4' v-if='this.$route.params.id != undefined' color='warning'>
                {{status ? 'Inativar':'Ativar'}} Ação
              </v-btn>
              <v-btn @click='limpar' class='mr-4' v-if='this.$route.params.id == undefined'>
                Limpar
              </v-btn>
              <v-btn @click='dialog = true' class='mr-4' color='error' :loading='$store.getters.submitted'
                v-if='this.$route.params.id != undefined' outlined>
                Excluir
              </v-btn>
              <v-btn type='button' @click='submit(), loadingSubmit = true' color='primary' :disabled='$v.$invalid' :loading="loadingSubmit">
                Salvar
              </v-btn>
            </v-col>
          </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import {
  validationMixin
} from 'vuelidate'
import empresaService from '../../../services/empresaService'
import acoesService from '../../../services/acoesService'
import contratoService from '../../../services/contratoService'
import tarefaService from '../../../services/tarefaService'
import questaoService from '../../../services/questaoService'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
  mixins: [validationMixin],

  data: () => ({
    tradeItems: [],
    trade: '',
    marca: '',
    marcaItems: [{}],
    contrato: '',
    contratoItems: [{}],
    contratoStatus: true,
    meses:[],
    contratoCarregando: false,
    status: false,
    tarefas: [{
      id: '',
      observacao: ''
    }],
    tarefasFlag: '',
    tarefasList: [{}],
    questoes: [{
      questao: '',
      ordem: '',
      id: ''
    }],
    questoesFlag: '',
    questoesList: [{}],
    dialog: false,
    dialogStatus:false,
    contagemValue: '',
    routeId:'',
    requestData: '',
    showCheckbox: false,
    disabledField: true,
    loadingTrade: true,
    disabledTrade: true,
    loadingMarca: true,
    disabledMarca: true,
    loadingTarefa: true,
    disabledTarefa: true,
    loadingQuestao: true,
    disabledQuestao: true,
    loadingSubmit:false ,
    arrayVisitas:[],
    validadeDiferenca:[],
  }),

  validations () {
    return {
      trade: {
        required
      },
      marca: {
        required
      },
      contrato: {
        required
      },
      tarefas: {
        required
      },
      questoes: {
        required
      }
    }
  },

  computed: {
    tradeErrors () {
      const errors = []
      if (!this.$v.trade.$dirty) {
        return errors
      }!this.$v.trade.required && errors.push('É necessário informar uma empresa')
      return errors
    },

    marcaErrors () {
      const errors = []
      if (!this.$v.marca.$dirty) {
        return errors
      }!this.$v.marca.required && errors.push('É necessário informar uma marca')
      return errors
    },

    contratoErrors () {
      const errors = []
      if (!this.$v.contrato.$dirty) {
        return errors
      }!this.$v.contrato.required && errors.push('É necessário informar o contrato')
      return errors
    },

    tarefaErrors () {
      const errors = []
      if (!this.$v.tarefas.$dirty) {
        return errors
      }!this.$v.tarefas.required && errors.push('É necessário informar uma tarefa')
      return errors
    },

    questoesErrors () {
      const errors = []
      if (!this.$v.questoes.$dirty) {
        return errors
      }!this.$v.questoes.required && errors.push('É necessário informar uma questão')
      return errors
    }
  },

  async mounted () {
    this.routeId = this.$route.params.id
    this.listarEmpresas()
    this.listarTarefas()
    this.listarQuestoes()


    if (this.routeId !== undefined) {
      this.$store.dispatch('start_load')
      await this.listarMarcas()
      this.contratoStatus = false
      await acoesService.API.Actions.find(this.routeId).then((response) => {
        this.$store.dispatch('stop_load')
        const dados = response.data

        this.requestData = dados.acao[0]
        this.trade = dados.acao[0].empresa_trade_id
        this.contrato = dados.acao[0].contrato_id
        this.meses = dados.meses
        this.marca = dados.acao[0].marca
        this.status = dados.acao[0].status
        this.tarefas = dados.tarefas
        this.questoes = dados.questoes
        this.questoes.forEach(element => {
          delete element.escala_de
          delete element.escala_ate
          element.id = element.questao_id
        })
        this.tarefas.forEach(element => {
          delete element.foto_antes
          delete element.foto_depois
          element.id = element.tarefa_id
        })
      })
      await this.listarContratos()
      await this.calcularVisitas()
      this.validadeDiferenca.forEach((mesLista)=> {
        this.meses.forEach((mesResposta, key)=> {
          if(mesLista.split('/')[0] == mesResposta){
            this.meses[key] = mesLista
          }
        })
      })
      this.showCheckbox = true
    }else{
      await this.listarMarcas()
    }
  },

  methods: {
    submit () {
      const acoes = {
        empresa_trade_id: this.trade,
        marca: this.marca,
        contrato_id: this.contrato,
        status: this.status,
        meses: this.meses,
        tarefas: JSON.stringify(this.tarefas),
        questoes: JSON.stringify(this.questoes)
      }
      this.routeId = this.$route.params.id
      if (this.routeId === undefined) {
        acoesService.API.Actions.create(acoes)
          .then((response) => {
            this.$store.dispatch('stop_submit')
            this.$store.dispatch('success', response.data.message)
            this.$router.push('/acoes')
          }).catch((error) => {
            this.loadingSubmit = false
            this.$store.dispatch('stop_submit')
            const errors = error.response.data.errors
            if (typeof (errors) === 'string') {
              this.$store.dispatch('error', errors)
            } else {
              for (const index in errors) {
                this.$store.dispatch('error', errors[index][0])
              }
            }
          })
      } else {
        acoesService.API.Actions.update(this.routeId, acoes)
          .then((response) => {
            this.$store.dispatch('stop_submit')
            this.$store.dispatch('success', response.data.message)
            this.$router.push('/acoes')
          }).catch((error) => {
            this.loadingSubmit = false
            this.$store.dispatch('stop_submit')
            const errors = error.response.data.errors
            if (typeof (errors) === 'string') {
              this.$store.dispatch('error', errors)
            } else {
              for (const index in errors) {
                this.$store.dispatch('error', errors[index][0])
              }
            }
          })
      }
    },

    limpar () {
      this.$v.$reset()
      this.trade = ''
      this.marca = ''
      this.contagemValue = ''
      this.contrato = ''
      this.tarefas = [{
        id: '',
        observacao: ''
      }]
      this.questoes = [{}]
    },

    excluir () {
      this.routeId = this.$route.params.id
      this.$store.dispatch('start_submit')

      acoesService.API.Actions.delete(this.routeId)
        .then((response) => {
          if (response.data.errors) {
            this.$store.dispatch('Erro', response.data.message)
          } else {
            this.$store.dispatch('Sucesso', response.data.message)
          }

          this.dialog = false
          this.$store.dispatch('stop_submit')
          this.$router.push('/acoes')
        }).catch((error) => {
          const errorArray = error.response.data.errors
          this.$store.dispatch('stop_submit')
          if (typeof (errorArray) === 'string') {
            this.$store.dispatch('error', errorArray)
          }
          if (errorArray instanceof Array) {
            for (const error in errorArray) {
              this.$store.dispatch('error', errorArray[error][0])
            }
          }
          this.dialog = false
        })
    },

    addTarefa () {
      this.tarefas.push({
        id: '',
        observacao: ''
      })
    },

    removeTarefa (key) {
      this.tarefas.splice(key, 1)
    },

    addQuestao () {
      this.questoes.push({
        questao: '',
        ordem: '',
        id: ''
      })
    },

    removeQuestao (key) {
      this.questoes.splice(key, 1)
    },

    selecionouEmpresa () {
      this.contrato = null
      this.listarContratos()
    },

    listarEmpresas () {
      empresaService.API.Actions.options().then((response) => {
        this.tradeItems = response.data
        this.loadingTrade = false
        this.disabledTrade = false
      })
    },

    async listarMarcas () {
      await acoesService.API.Actions.marcas().then((response) => {
        this.marcaItems = response.data.marcas
        this.loadingMarca = false
        this.disabledMarca = false
      })
    },

    async listarContratos () {
      this.routeId = this.$route.params.id
      this.contratoItems = []
      this.contratoCarregando = true
      await contratoService.API.Actions.options(this.trade)
        .then((response) => {
          this.contratoItems = response.data.contratos
          if(this.routeId !== undefined){
            this.contagem()
          }
          this.contratoCarregando = false
          this.contratoStatus = false
        }).catch(() => {
          this.contratoCarregando = false
          this.contratoStatus = true
        })
    },

    listarTarefas () {
      tarefaService.API.Actions.options()
        .then((response) => {
          this.tarefasList = response.data.tarefas
          this.loadingTarefa = false
          this.disabledTarefa = false
        })
    },

    listarQuestoes () {
      questaoService.API.Actions.options()
        .then((response) => {
          this.questoesList = response.data.questoes
          this.loadingQuestao = false
          this.disabledQuestao = false
        })
    },
    alterarStatus(){
      this.$store.dispatch('start_submit')
      acoesService.API.Actions.alterarStatus(this.routeId, this.requestData)
          .then((response) => {
            this.$store.dispatch('stop_submit')
            this.status = !this.status
            this.dialogStatus = false
            this.$store.dispatch('success', response.data.message)
          }).catch((error) => {
            this.$store.dispatch('stop_submit')
            const errors = error.response.data.errors
            this.dialogStatus = false
            if (typeof (errors) === 'string') {
              this.$store.dispatch('error', errors)
            } else {
              for (const index in errors) {
                this.$store.dispatch('error', errors[index][0])
              }
            }
          })
    },
    contagem(){
      this.contagemValue = ''
      this.contratoItems.find(obj => {
        if (obj.value == this.contrato){
          this.contagemValue = 'Total de Clientes: ' + String(obj.total)
        }
      })
    },
    calcularVisitas(){
      this.validadeDiferenca= []
      // this.meses= []
      this.contratoItems.forEach(element => {
        if (element.value == this.contrato) {
          let inicio = moment(element.validade_inicio.split('/')[0] + '/01/' + element.validade_inicio.split('/')[1])
          let final = moment(element.validade_final.split('/')[0] + '/01/' + element.validade_final.split('/')[1])

          while (final > inicio || inicio.format('M') === final.format('M')) {
            this.validadeDiferenca.push(inicio.format('MM/YYYY'));
            inicio.add(1,'month');
          }
          if(this.routeId == undefined){
            this.showCheckbox = true
          }
        }
      });
    },
  }
}
</script>
