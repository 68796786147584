<template>
  <v-container>
    <v-card class='pa-4'>
      <v-form @submit.prevent='submit'>
        <v-row>
          <v-col cols='12' md='12'>
            <v-textarea v-model='pergunta' class='obrigatorio' label='Descrição'
              @input="$v.pergunta.$touch()" required maxlength='120' autofocus outlined></v-textarea>
          </v-col>
          <v-col cols='12' md='12'>
            <v-divider></v-divider>
            <h4 class='mb-2'>Forma de resposta:</h4>
            <v-container fluid>
              <v-radio-group v-model='tipo' mandatory>
                <v-radio label='Escala' value='0'></v-radio>
                <v-radio label='Foto' value='1'></v-radio>
                <v-radio label='Múltipla escolha, resposta múltipla' value='2'></v-radio>
                <v-radio label='Múltipla escolha, resposta única' value='3'></v-radio>
                <v-radio label='Texto' value='4'></v-radio>
              </v-radio-group>
            </v-container>
          </v-col>
        </v-row>
        <v-row v-if='this.tipo == "0"'>
          <v-col md='3' sm='12'>
            <v-text-field type='number' label='De' class='obrigatorio' v-model='escala_de'
              @input="$v.escala_de.$touch()" :loading='$store.getters.loading' outlined></v-text-field>
          </v-col>
          <v-col md='3' sm='12'>
            <v-text-field type='number' label='Até' class='obrigatorio' v-model='escala_ate'
              :rules='[rules.greater(escala_ate, escala_de)]' @input="$v.escala_ate.$touch()"
              :loading='$store.getters.loading' outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row v-else-if='this.tipo == "3" || this.tipo == "2"'>
          <v-col md='4' sm='12'>
            <v-text-field outlined v-model='item' class='obrigatorio' append-outer-icon="mdi-plus"
              @click:append-outer="addItem(item)" maxlength='60' label='Opções'
              :loading='$store.getters.loading' :rules='[rules.match]'></v-text-field>
          </v-col>
          <v-col md='4' sm='12' class='mt-n8'>
            <div>
              <span v-for="(item, index) in items" :key="index">
                <v-chip class="ma-2" color="primary">
                  <v-icon @click="deleteDropFile(index)" small>
                    mdi-close
                  </v-icon>
                  {{ item }}
                </v-chip>
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md=12 col=12>
            <small>
              <span class="legenda">
                Campo Obrigatório
              </span>
            </small>
          </v-col>
        </v-row>
        <v-col md=12 cols=12>
            <v-divider></v-divider>
          </v-col>
        <v-row>
          <v-col cols='2' md='6' class="d-none d-sm-flex">
            <router-link to=/questoes> <v-btn color='secondary' outlined>
              Voltar
              </v-btn>
            </router-link>
          </v-col>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" content-class="modal-delete">
            <v-card>
              <v-card-title>
                Excluir o registro?
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="blue-grey lighten-5">
                A exclusão desse registro é permanente e sem retorno.
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="dialog = false">
                  Não
                </v-btn>
                <v-btn color="error" @click="excluir">
                  Sim
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-col cols='10' md='6' class="text-right">
            <v-btn @click="limpar" class="mr-4" v-if='this.$route.params.id == undefined'>
              Limpar
            </v-btn>
            <v-btn @click="dialog = true" class="mr-4" color="error" :loading='$store.getters.submitted'
              v-if='this.$route.params.id != undefined' outlined>
              Excluir
            </v-btn>
            <v-btn color="primary" type="button" @click="submit" :loading='$store.getters.submitted'
              :disabled="$v.$invalid">
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<style>
  .obrigatorio label::after {
    content: "*";
    color: red
  }
</style>
<script>
import questaoService from '../../../services/questaoService'
import {
  validationMixin
} from 'vuelidate'
import {
  required,
  requiredIf,
  minValue
} from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],

  data: () => ({
    name: 'dakota-questao-form',
    pergunta: '',
    tipo: '',
    escala_de: '',
    escala_ate: '',
    questoes: [],
    item: '',
    items: [],
    rules: {
      match: v => {
        const pattern = /^[a-zà-ý\d ]{0,100}$/i
        return pattern.test(v) || 'Caracteres inválidos'
      },
      greater (escalaAte, escalaDe) {
        return parseInt(escalaAte) > parseInt(escalaDe) || 'Escala até deve ser maior que escala de.'
      }
    },
    path: '/questoes',
    dialog: false
  }),

  validations () {
    return {
      pergunta: {
        required
      },
      escala_de: {
        required: requiredIf(function () {
          return this.tipo === 0
        })
      },
      escala_ate: {
        required: requiredIf(function () {
          return this.tipo === 0
        }),
        maior: minValue(parseInt(this.escala_de) + 1)
      }
    }
  },

  mounted () {
    const routeId = this.$route.params.id
    if (routeId !== undefined) {
      this.$store.dispatch('start_load')

      questaoService.API.Actions.find(routeId).then((response) => {
        this.$store.dispatch('stop_load')
        const dados = response.data.questao
        this.pergunta = dados.pergunta
        this.tipo = dados.tipo

        if (this.tipo === '0') {
          this.escala_ate = dados.escala_ate
          this.escala_de = dados.escala_de
        } else if (this.tipo === '2' || this.tipo === '3') {
          this.$nextTick(function () {
            dados.opcoes.forEach(element => {
              this.items.push(element.opcao)
            })
          })
        }
      }).catch((error) => {
            this.$store.dispatch('stop_submit')
            const errorArray = error.response.data.errors

            for (const error in errorArray) {
              this.$store.dispatch('error', errorArray[error][0])
            }
          })
    }
  },

  methods: {
    submit: function () {
      this.$v.$touch()
      this.$store.dispatch('start_submit')

      const questao = {
        pergunta: this.pergunta,
        tipo: this.tipo
      }
      if (this.tipo === '0') {
        questao.escala_de = this.escala_de
        questao.escala_ate = this.escala_ate
      }

      if (this.tipo === '2' || this.tipo === '3') {
        questao.questoes_opcoes = this.items
      }

      const routeId = this.$route.params.id
      if (routeId === undefined) {
        questaoService.API.Actions.create(questao)
          .then((response) => {
            this.$store.dispatch('stop_submit')
            this.$store.dispatch('success', response.data.message)
            this.$router.push('/questoes')
          }).catch((error) => {
            this.$store.dispatch('stop_submit')
            const errorArray = error.response.data.errors

            for (const error in errorArray) {
              let errorFinal =  errorArray[error][0]
              if(errorFinal.includes('questoes')){
                errorFinal = errorFinal.replace('questoes', 'questões')
                }
                if(errorFinal.includes('opcoes')){
                errorFinal = errorFinal.replace('opcoes', 'opções')
                }
                if(errorFinal.includes('tipo for 0')){
                errorFinal = errorFinal.replace('tipo for 0', 'tipo for Escala')
                }
                if(errorFinal.includes('tipo for 1')){
                  errorFinal = errorFinal.replace('tipo for 1', 'tipo for Foto')
                }
                if(errorFinal.includes('tipo for 2')){
                  errorFinal = errorFinal.replace('tipo for 2', 'tipo for Múltipla Escolha')
                }
                if(errorFinal.includes('tipo for 3')){
                  errorFinal = errorFinal.replace('tipo for 3', 'tipo for Única Escolha')
                }
                if(errorFinal.includes('tipo for 4')){
                  errorFinal = errorFinal.replace('tipo for 4', 'tipo for Texto')
                }
              this.$store.dispatch('error', errorFinal)
            }
          })
      } else {
        questaoService.API.Actions.update(routeId, questao)
          .then((response) => {
            this.$store.dispatch('stop_submit')
            this.$store.dispatch('success', response.data.message)
            this.$router.push('/questoes')
          }).catch((error) => {
            this.$store.dispatch('stop_submit')
            if (typeof(error.response.data.errors) == 'string'){
              let errorFinal =  error.response.data.errors
              if(errorFinal.includes('questoes')){
                errorFinal = errorFinal.replace('questoes', 'questões')
              }
              if(errorFinal.includes('opcoes')){
                errorFinal = errorFinal.replace('opcoes', 'opções')
              }

              if(errorFinal.includes('tipo for 0')){
                errorFinal = errorFinal.replace('tipo for 0', 'tipo for Escala')
              }
              if(errorFinal.includes('tipo for 1')){
                errorFinal = errorFinal.replace('tipo for 1', 'tipo for Foto')
              }
              if(errorFinal.includes('tipo for 2')){
                errorFinal = errorFinal.replace('tipo for 2', 'tipo for Múltipla Escolha')
              }
              if(errorFinal.includes('tipo for 3')){
                errorFinal = errorFinal.replace('tipo for 3', 'tipo for Única Escolha')
              }
              if(errorFinal.includes('tipo for 4')){
                errorFinal = errorFinal.replace('tipo for 4', 'tipo for Texto')
              }
              this.$store.dispatch('error', errorFinal)
            } else{
              this.$store.dispatch('stop_submit')
              const errorArray = error.response.data.errors
  
              for (const error in errorArray) {
                let errorFinal =  errorArray[error][0]
                if(errorFinal.includes('questoes')){
                  errorFinal = errorFinal.replace('questoes', 'questões')
                }
                if(errorFinal.includes('opcoes')){
                  errorFinal = errorFinal.replace('opcoes', 'opções')
                }
                if(errorFinal.includes('tipo for 0')){
                errorFinal = errorFinal.replace('tipo for 0', 'tipo for Escala')
                }
                if(errorFinal.includes('tipo for 1')){
                  errorFinal = errorFinal.replace('tipo for 1', 'tipo for Foto')
                }
                if(errorFinal.includes('tipo for 2')){
                  errorFinal = errorFinal.replace('tipo for 2', 'tipo for Múltipla Escolha')
                }
                if(errorFinal.includes('tipo for 3')){
                  errorFinal = errorFinal.replace('tipo for 3', 'tipo for Única Escolha')
                }
                if(errorFinal.includes('tipo for 4')){
                  errorFinal = errorFinal.replace('tipo for 4', 'tipo for Texto')
                }
                this.$store.dispatch('error', errorFinal)
              }
            }
          })
      }
    },

    limpar: function () {
      this.$v.$reset()
      this.pergunta = ''
      this.tipo = ''
      this.escala_de = ''
      this.escala_ate = ''
      this.items = []
    },

    excluir () {
      const routeId = this.$route.params.id
      this.$store.dispatch('start_submit')

      questaoService.API.Actions.delete(routeId)
        .then((response) => {
          if (response.data.errors) {
            this.$store.dispatch('Erro', response.data.message)
          } else {
            this.$store.dispatch('Sucesso', response.data.message)
          }
          this.$store.dispatch('stop_submit')
          this.dialog = false
          this.$router.push('/questoes')
        }).catch((error) => {
          const errorArray = error.response.data.errors
          this.$store.dispatch('stop_submit')

          if (typeof (errorArray) === 'string') {
            this.$store.dispatch('error', errorArray)
          }

          if (errorArray instanceof Array) {
            for (const error in errorArray) {
              this.$store.dispatch('error', errorArray[error][0])
            }
          }
          this.dialog = false
        })
    },

    clear () {
      this.item = ''
      this.items = []
    },

    addItem (item) {
      const pattern = /^[a-zà-ý\d ]{1,100}$/i
      if (pattern.test(item)) {
        this.items.push(item)
        this.item = ''
      }
    },

    deleteDropFile (index) {
      this.items.splice(index, 1)
    }
  }
}
</script>
