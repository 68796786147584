import index from './views/index'

export default [{
  path: 'opcoes',
  name: 'Opcoes',
  component: index,
  meta: {
    permissoes: ['Admin'],
    breadcrumbs: [{
      text: 'Home',
      exact: true,
      to: {
        name: 'Base'
      }
    },
    {
      text: 'Opções',
      exact: true,
      to: {
        name: 'Opcoes'
      }
    }
    ]
  }
}]
