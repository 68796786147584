import index from './views/index'

export default [
  {
    path: 'clientes',
    name: 'Clientes',
    component: index,
    meta: {
      permissoes: ['Admin', 'Trade'],
      breadcrumbs: [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Base'
          }
        },
        {
          text: 'Analistas de Ações de Trade',
          exact: true,
          to: {
            name: 'Clientes'
          }
        }
      ]
    }
  }
]
