import index from './views/index'
import form from './views/form'

export default [
  {
    path: 'questoes',
    name: 'Questoes',
    component: index,
    meta: {
      permissoes: ['Admin'],
      breadcrumbs: [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Base'
          }
        },
        {
          text: 'Questões',
          exact: true,
          to: {
            name: 'Questoes'
          }
        }
      ]
    }
  },
  {
    path: 'questoes/formulario',
    name: 'questaoForm',
    component: form,
    meta: {
      permissoes: ['Admin'],
      breadcrumbs: [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Base'
          }
        },
        {
          text: 'Questões',
          exact: true,
          to: {
            name: 'Questoes'
          }
        },
        {
          text: 'Cadastro de Questões',
          exact: true,
          to: {
            name: 'questaoForm'
          }
        }
      ]
    }
  },
  {
    path: 'questoes/formulario/:id',
    name: 'questaoFormEdit',
    component: form,
    meta: {
      permissoes: ['Admin'],
      breadcrumbs: [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Base'
          }
        },
        {
          text: 'Questões',
          exact: true,
          to: {
            name: 'Questoes'
          }
        },
        {
          text: 'Edição de Questões',
          exact: true,
          to: {
            name: 'questaoFormEdit'
          }
        }
      ]
    }
  }
]
