import index from './views/index'
import form from './views/form'

export default [{
  path: 'tarefas',
  name: 'Tarefas',
  component: index,
  meta: {
    permissoes: ['Admin'],
    breadcrumbs: [{
      text: 'Home',
      exact: true,
      to: {
        name: 'Base'
      }
    },
    {
      text: 'Tarefas',
      exact: true,
      to: {
        name: 'Tarefas'
      }
    }
    ]
  }
},
{
  path: 'tarefas/formulario',
  name: 'tarefasForm',
  component: form,
  meta: {
    permissoes: ['Admin'],
    breadcrumbs: [{
      text: 'Home',
      exact: true,
      to: {
        name: 'Base'
      }
    },
    {
      text: 'Tarefas',
      exact: true,
      to: {
        name: 'Tarefas'
      }
    },
    {
      text: 'Cadastro de Tarefas',
      exact: true,
      to: {
        name: 'tarefasForm'
      }
    }
    ]
  }
},
{
  path: 'tarefas/formulario/:id',
  name: 'tarefasFormEdit',
  component: form,
  meta: {
    permissoes: ['Admin'],
    breadcrumbs: [{
      text: 'Home',
      exact: true,
      to: {
        name: 'Base'
      }
    },
    {
      text: 'Tarefas',
      exact: true,
      to: {
        name: 'Tarefas'
      }
    },
    {
      text: 'Edição de Tarefas',
      exact: true,
      to: {
        name: 'tarefasFormEdit'
      }
    }
    ]
  }
}
]
