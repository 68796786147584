import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

var pjson = require("../../package.json");

export default new Vuex.Store({
  state: {
    version: pjson.version,
    loading: false,
    submitted: false,
    token_type: localStorage.getItem("token_type") || "",
    access_token: localStorage.getItem("access_token") || "",
    refresh_token: localStorage.getItem("refresh_token") || "",
    alerts: [],
    user_type: localStorage.getItem("user_type") || "",
    user_name: localStorage.getItem("user_name") || "",
    user_login: localStorage.getItem("user_login") || "",
    user_empresa_id: localStorage.getItem("user_empresa_id") || "",
  },

  getters: {
    userType: (state) => {
      return state.user_type;
    },

    userName: (state) => {
      return state.user_name;
    },

    userLogin: (state) => {
      return state.user_login;
    },

    userEmpresaId: (state) => {
      return state.user_empresa_id;
    },

    loading: (state) => {
      return state.loading;
    },

    submitted: (state) => {
      return state.submitted;
    },

    bearerToken: (state) => {
      return state.token_type + " " + state.access_token;
    },

    version: (state) => {
      return state.version;
    },

    accessToken: (state) => {
      return state.access_token;
    },

    tokenType: (state) => {
      return state.access_token;
    },

    isAuthenticated: (state) => {
      return Boolean(state.access_token);
    },
    isDev(state) {
      return state.user_login.substr(0, 2).toLowerCase() === "kw";
    },
    isAdmin(state) {
      return state.user_type === "Admin";
    },
    haveAuth(state, permissions) {
      return permissions.includes(state.user_type);
    },

    alerts: (state) => state.alerts,
  },

  mutations: {
    changeUserType(state, data) {
      state.user_type = data.user_type;
      state.user_name = data.user_name;
      state.user_empresa_id = data.user_empresa_id;
      localStorage.setItem("user_type", state.user_type);
      localStorage.setItem("user_name", state.user_name);
      localStorage.setItem("user_empresa_id", state.user_empresa_id);
    },

    login(state, data) {
      state.access_token = data.access_token;
      state.token_type = data.token_type;
      state.refresh_token = data.refresh_token;
      state.user_login = data.user;

      localStorage.setItem("access_token", state.access_token);
      localStorage.setItem("token_type", state.token_type);
      localStorage.setItem("refresh_token", state.refresh_token);
      localStorage.setItem("user_login", state.user_login);
    },

    logout(state) {
      state.access_token = "";
      state.token_type = "";
      state.refresh_token = "";
      state.user_type = "";
      state.user_name = "";
      state.user_login = "";
      localStorage.removeItem("access_token");
      localStorage.removeItem("token_type");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user_type");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_login");
    },

    clear_message(state) {
      state.alerts = [];
    },

    loading(state, data) {
      state.loading = data.status;
    },

    submitted(state, data) {
      state.submitted = data.status;
    },

    set_message(state, { message, color, type }) {
      state.alerts.push({
        alert_visible: true,
        alert_message: message,
        alert_color: color,
        alert_type: type,
      });
    },

    setPermissions(state, value) {
      localStorage.setItem("user_type", value);
      localStorage.setItem("user_name", value);
    },
  },

  actions: {
    setUserType(context, data) {
      context.commit("changeUserType", data);
    },

    start_load(context) {
      context.commit("loading", {
        status: true,
      });
    },

    stop_load(context) {
      context.commit("loading", {
        status: false,
      });
    },

    start_submit(context) {
      context.commit("submitted", {
        status: true,
      });
    },

    stop_submit(context) {
      context.commit("submitted", {
        status: false,
      });
    },

    error(context, message) {
      context.commit("clear_message");
      context.commit("set_message", {
        message,
        color: "error",
        type: "error",
      });
      setTimeout(() => {
        context.commit("clear_message");
      }, 7500);
    },

    success(context, message) {
      context.commit("clear_message");
      context.commit("set_message", {
        message,
        color: "success",
        type: "success",
      });
      setTimeout(() => {
        context.commit("clear_message");
      }, 7500);
    },
  },
});
