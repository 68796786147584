import index from "./views/index";
import tabelaDeAcoes from "./views/tabelaDeAcoes";
import tabelaDeQuiz from "./views/tabelaDeQuiz";
import visitasContrato from "./views/visitasContrato";
import tabelaDeQuizPrint from "./views/tabelaDeQuizPrint";
import tabelaDeAcoesPrint from "./views/tabelaDeAcoesPrint";

export default [
  {
    path: "relatorio",
    name: "Agrupado por Razão Social",
    component: index,
    meta: {
      permissoes: ["Admin", "Trade"],
      breadcrumbs: [
        {
          text: "Home",
          exact: true,
          to: {
            name: "Base",
          },
        },
        {
          text: "Relatório - Agrupado por Razão Social",
          exact: true,
        },
      ],
    },
  },
  {
    path: "tabela-tarefas",
    name: "Tabela de Tarefas",
    component: tabelaDeAcoes,
    meta: {
      permissoes: ["Admin", "Trade", "Representante"],
      breadcrumbs: [
        {
          text: "Home",
          exact: true,
          to: {
            name: "Base",
          },
        },
        {
          text: "Relatório - Tabela de Tarefas",
          exact: true,
          to: {
            name: "Tabela de Tarefas",
          },
        },
      ],
    },
  },
  {
    path: "tabela-quiz",
    name: "Tabela de Quiz",
    component: tabelaDeQuiz,
    meta: {
      permissoes: ["Admin", "Trade", "Representante"],
      breadcrumbs: [
        {
          text: "Home",
          exact: true,
          to: {
            name: "Base",
          },
        },
        {
          text: "Relatório - Tabela de Questões",
          exact: true,
          to: {
            name: "Tabela de Quiz",
          },
        },
      ],
    },
  },
  {
    path: "visitas-contrato",
    name: "VisitasContrato",
    component: visitasContrato,
    meta: {
      permissoes: ["Admin", "Representante"],
      breadcrumbs: [
        {
          text: "Home",
          exact: true,
          to: {
            name: "Base",
          },
        },
        {
          text: "Relatório - Visitas do Contrato",
          exact: true,
          to: {
            name: "VisitasContrato",
          },
        },
      ],
    },
  },
  // {
  //   path: 'tabela-quiz-print',
  //   name: 'Exibição de Tabela de Quiz',
  //   component: tabelaDeQuizPrint,
  //   meta: {
  //     permissoes: ['Admin', 'Trade'],
  //     breadcrumbs: [
  //       {
  //         text: 'Home',
  //         exact: true,
  //         to: {
  //           name: 'Base'
  //         }
  //       },
  //       {
  //         text: 'Relatório - Tabela de Questões',
  //         exact: true,
  //         to: {
  //           name: 'Tabela de Quiz'
  //         }
  //       },
  //       {
  //         text: 'Exibição - Tabela de Questões',
  //         exact: true,
  //       }
  //     ]
  //   }
  // },
  {
    path: "tabela-tarefas-print",
    component: tabelaDeAcoesPrint,
    meta: {
      permissoes: ["Admin", "Trade"],
      breadcrumbs: [
        {
          text: "Home",
          exact: true,
          to: {
            name: "Base",
          },
        },
        {
          text: "Relatório - Tabela de Tarefas",
          exact: true,
          to: {
            name: "Tabela de Tarefas",
          },
        },
        {
          text: "Exibição - Tabela de Tarefas",
          exact: true,
        },
      ],
    },
  },
];
