import index from './views/index'
import form from './views/form'

export default [
  {
    path: 'acessos',
    name: 'Acessos',
    component: index,
    meta: {
      permissoes: ['Admin', 'Trade'],
      breadcrumbs: [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Base'
          }
        },
        {
          text: 'Login de Acesso ao App',
          exact: true,
          to: {
            name: 'Acessos'
          }
        }

      ]
    }
  },
  {
    path: 'acessos/formulario',
    name: 'acessosForm',
    component: form,
    meta: {
      permissoes: ['Admin', 'Trade'],
      breadcrumbs: [{
        text: 'Home',
        exact: true,
        to: {
          name: 'Base'
        }
      },
      {
        text: 'Login de Acesso ao App',
        exact: true,
        to: {
          name: 'Acessos'
        }
      },
      {
        text: 'Cadastro de Login de Acesso ao App',
        exact: true,
        to: {
          name: 'acessosForm'
        }
      }
      ]
    }
  },
  {
    path: 'acessos/formulario/:id',
    name: 'acessosFormEdit',
    component: form,
    meta: {
      permissoes: ['Admin', 'Trade'],
      breadcrumbs: [{
        text: 'Home',
        exact: true,
        to: {
          name: 'Base'
        }
      },
      {
        text: 'Login de Acesso ao App',
        exact: true,
        to: {
          name: 'Acessos'
        }
      },
      {
        text: 'Edição de Login de Acesso ao App',
        exact: true,
        to: {
          name: 'acessosFormEdit'
        }
      }
      ]
    }
  }
]
