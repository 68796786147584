<template>
  <v-container style="display: grid">
    <v-row>
      <v-col>
        <v-card>
          <v-row>
            <v-col class="pa-4">
              <v-text-field
                v-model="buscar"
                autofocus
                append-icon="mdi-magnify"
                label="Buscar"
                class="ml-3"
              >
              </v-text-field>
            </v-col>
            <v-col class="pa-4">
              <router-link :to="path">
                <v-btn class="ma-4 float-right" color="primary">
                  Incluir
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
          <v-row class="ma-0">
            <v-col class="pa-0" cols="6">
              <v-autocomplete
                v-model="filter.trade"
                label="Empresa de Trade"
                @input="getContractsData"
                :items="tradeItems"
                outlined
                :loading="loadingTrade"
              ></v-autocomplete>
            </v-col>
          </v-row>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="contratos"
            :search="buscar"
            :loading="$store.getters.loading"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <router-link
                :to="{ name: 'contratosFormEdit', params: { id: item.id } }"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="mr-2" v-bind="attrs" v-on="on">
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </router-link>
              <dakota-confirm
                :dataService="service"
                :dataEntry="item.id"
              ></dakota-confirm>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import contratoService from "../../../services/contratoService";
import empresaService from "../../../services/empresaService";
import DakotaConfirm from "@/components/DakotaConfirmation";

export default {
  components: {
    DakotaConfirm,
  },

  data: () => ({
    dialog: false,
    buscar: "",
    filter: {
      trade: "",
    },
    tradeItems: [],
    loadingTrade: false,
    contratos: [],
    service: "",
    dialogDelete: false,
    path: "/contratos/formulario",
    acessos: [],
    headers: [
      {
        text: "Validade",
        value: "validade_final",
        align: "start",
        sortable: false,
      },
      {
        text: "Número do Contrato",
        value: "numero_contrato",
        sortable: false,
        align: "start",
      },
      {
        text: "Empresa de Trade",
        value: "empresa_trade",
        sortable: false,
        align: "start",
      },
      {
        text: "Clientes",
        value: "total",
        sortable: false,
        align: "start",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
      },
    ],
  }),

  created() {
    this.service = contratoService;
  },

  methods: {
    async listarEmpresas() {
      await empresaService.API.Actions.options().then((response) => {
        this.tradeItems = response.data;
        this.loadingTrade = false;
      });
    },
    getContractsData() {
      this.$store.dispatch("start_load");
      this.listarEmpresas();
      let filter = {};
      if (this.filter.trade) {
        filter.empresa_trade = this.filter.trade;
      }
      contratoService.API.Actions.list(filter)
      .then((response) => {
          this.contratos = response.data.contratos;
        })
        .catch((error) => {
          const errorArray = error.response.data.errors;
          for (const error in errorArray) {
            this.$store.dispatch("error", errorArray[error][0]);
          }
        })
        .finally(() => {
          this.$store.dispatch("stop_load");
        });
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Adicionar Usuário" : "Editar Usuário";
    },
  },

  mounted() {
    this.getContractsData();
  },
};
</script>
