import client from '../plugins/apiClient'

export default {
  API: {
    Actions: {
      options: () => {
        client.login()
        return client.get('empresa-options')
      },
      list: () => {
        client.login()
        return client.get('empresas')
      },
      create: (objeto) => {
        client.login()
        return client.post('empresa', objeto)
      },
      delete: (id) => {
        client.login()
        return client.delete('empresa/' + id)
      },
      update: (id, objeto) => {
        client.login()
        return client.put('empresa/' + id, objeto)
      },
      find: (id) => {
        client.login()
        return client.get('empresa/' + id)
      },
      logo: (id) => {
        client.login()
        return client.get('logo/' + id)
      }
    }
  }
}
