import client from "../plugins/apiClient";

export default {
  API: {
    Actions: {
      getAll: () => {
        client.login();
        return client.get("brands");
      },
    },
  },
};
