<template>
  <v-main style="padding-bottom: 20px">
    <dakota-menu>
      <v-row>
        <v-col>
          <dakota-alert></dakota-alert>
        </v-col>
      </v-row>
      <router-view />
    </dakota-menu>
    <v-footer fixed padless class="justify-end">
      <code> Versão: {{ $store.getters.version }} </code>
    </v-footer>
  </v-main>
</template>
<script>
import DakotaMenu from "@/components/Menu";
import DakotaAlert from "@/components/DakotaAlertField";

export default {
  components: {
    DakotaMenu,
    DakotaAlert,
  },
};
</script>
