<template>
  <v-container>
    <v-card>
      <v-form @submit.prevent="submit" class="pa-4">
        <v-row>
          <v-col cols="12" md="12" v-if="$store.getters.userType === 'Admin'">
          <v-autocomplete
            label="Empresa de Trade"
            v-model="trade"
            :items="tradeList"
            outlined
            :loading='loadingEmpresas'
            :disabled='disabledEmpresas'
            :error-messages="tradeErrors" 
            @input="$v.trade.$touch() , desbloquear()"
          ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="usuario" autofocus label="Usuário" outlined required class="obrigatorio" type="text"
              :error-messages="usuarioErrors" v-mask="'####'" @input="$v.usuario.$touch()"
              hint="Inserir 4 caracteres numéricos após o hífen." :loading='$store.getters.loading' persistent-hint
              :prefix="prefixo +'-'"
              :disabled='disabledField'
              >
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  Para realizar login no App é necessário informar o prefixo seguido do hífen e código inserido neste
                  campo.
                </v-tooltip>
              </template>
            </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="senha" label="Senha" outlined required class="obrigatorio"
                :error-messages="senhaErrors" :loading='$store.getters.loading' @input="$v.senha.$touch()">
              </v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-switch v-model="ativo" color="primary" :loading='$store.getters.loading' :label="`Status: ${ativo ? 'Ativo':'Inativo'}`">
            </v-switch>
          </v-col>
        </v-row>
        <v-col md=12 col=12>
          <small>
            <span class="legenda">
              Campo Obrigatório
            </span>
          </small>
        </v-col>
        <v-col md=12>
          <v-divider></v-divider>
        </v-col>
        <v-row>
          <v-col cols="2" md="6"  class="d-none d-sm-flex">
            <router-link to=/acessos> <v-btn color="secondary" outlined>
              Voltar
              </v-btn>
            </router-link>
          </v-col>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" content-class="modal-delete">
            <v-card>
              <v-card-title>
                Excluir o registro?
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="blue-grey lighten-5">
                A exclusão desse registro é permanente e sem retorno.
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="dialog = false">
                  Não
                </v-btn>
                <v-btn color="error" @click="excluir">
                  Sim
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-col class="text-right" cols='10' md='6'>
            <v-btn @click="limpar" class="mr-4" v-if='this.$route.params.id == undefined'>
              Limpar
            </v-btn>
            <v-btn @click="dialog = true" class="mr-4" color="error" :loading='$store.getters.submitted' v-if='this.$route.params.id != undefined' outlined>
              Excluir
            </v-btn>
            <v-btn color="primary" type="button" @click="submit" :loading='$store.getters.submitted'
              :disabled="$v.$invalid">
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import {
  validationMixin
} from 'vuelidate'
import {
  required,
  requiredIf

} from 'vuelidate/lib/validators'
import empresaService from '../../../services/empresaService'
import acessosService from '../../../services/acessosService'

export default {
  mixins: [validationMixin],

  data: () => ({
    name: 'dakota-acesso-form',
    usuario: '',
    senha: '',
    trade: '',
    e: '',
    prefixo: '',
    disabledField: true,
    tradeList: [],
    ativo: true,
    dialog: false,
    loadingEmpresas: true,
    disabledEmpresas: true,
    acesso: ''
  }),

  async mounted () {
    if (this.$store.getters.userType === 'Admin') {
      this.listarEmpresas()
      this.prefixo = '00'
    } else if(this.$store.getters.userType != 'Admin'){
      this.desbloquear ()
      this.prefixo = this.$store.getters.userEmpresaId
    }
    const routeId = this.$route.params.id
    


    if (routeId !== undefined) {
      this.$store.dispatch('start_load')
      this.desbloquear ()

      if (this.$store.getters.userType != 'Admin') {
        await acessosService.API.Actions.check(routeId).then((response) => {
          this.acesso = response.data
        })
       }

      if (this.$store.getters.userEmpresaId != this.acesso.empresa_trade_id && this.$store.getters.userType != 'Admin') {
        this.$store.dispatch('error', 'Você não tem permissão para visitar esta tela')
        this.$router.push('/acessos')
      }else{
        acessosService.API.Actions.find(routeId).then((response) => {
          this.$store.dispatch('stop_load')
          const dados = response.data
            this.usuario = dados.login
            if (this.usuario.includes('-')){
              let usuarioSplit = this.usuario.split('-')
              this.usuario = usuarioSplit[1]
            }
            this.trade = dados.empresa_trade_id
            this.prefixo = this.trade
            this.ativo = dados.status
            this.senha = dados.senha
      })
      }
    } else {
      this.ativo = true
    }
  },

  validations () {
    return {
      usuario: {
        required
      },
      senha: {
        required
      },
      trade: {
        required: requiredIf(function () {
          return this.$store.getters.userType === 'Admin'
        })
      }
    }
  },

  computed: {
    usuarioErrors () {
      const errors = []
      if (!this.$v.usuario.$dirty) {
        return errors
      } else {
        !this.$v.usuario.required && errors.push('É necessário informar o usuário')
        return errors
      }
    },

    senhaErrors () {
      const errors = []
      if (!this.$v.senha.$dirty) {
        return errors
      } else {
        !this.$v.senha.required && errors.push('É necessário informar a senha')
        return errors
      }
    },

    tradeErrors () {
      const errors = []
      if (!this.$v.trade.$dirty) {
        return errors
      } else {
        !this.$v.trade.required && errors.push('É necessário informar a empresa')
        return errors
      }
    }
  },

  methods: {
    desbloquear () {
      this.disabledField = false,
      this.prefixo = this.trade
    },
    submit () {
      this.$v.$touch()
      this.$store.dispatch('start_submit')

      const routeId = this.$route.params.id

      const acessos = {
        login: this.prefixo+'-'+this.usuario,
        senha: this.senha,
        status: this.ativo
      }
      if (this.$store.getters.userType === 'Admin') {
        acessos.empresa_trade_id = this.trade
      }

      if (routeId === undefined) {
        acessosService.API.Actions.create(acessos)
          .then((response) => {
            this.$store.dispatch('stop_submit')
            this.$store.dispatch('success', response.data.message)
            this.$router.push('/acessos')
          }).catch((error) => {
            this.$store.dispatch('stop_submit')
            const errors = error.response.data.errors
            if (typeof (errors) === 'string') {
              this.$store.dispatch('error', errors)
            } else {
              for (const index in errors) {
                this.$store.dispatch('error', errors[index][0])
              }
            }
          })
      } else {
        acessosService.API.Actions.update(routeId, acessos)
          .then((response) => {
            this.$store.dispatch('stop_submit')
            this.$store.dispatch('success', response.data.message)
            this.$router.push('/acessos')
          }).catch((error) => {
            this.$store.dispatch('stop_submit')
            const errors = error.response.data.errors
            if (typeof (errors) === 'string') {
              this.$store.dispatch('error', errors)
            } else {
              for (const index in errors) {
                this.$store.dispatch('error', errors[index][0])
              }
            }
          })
      }
    },

    limpar () {
      this.$v.$reset()
      this.usuario = ''
      this.senha = ''
      this.trade = ''
      this.disabledField= true
      this.prefixo= '00'

    },

    listarEmpresas () {
      this.loadingEmpresas = true
      empresaService.API.Actions.options().then((response) => {
        this.tradeList = [];
        response.data.forEach(element => {
          this.tradeList.push(element)
          this.loadingEmpresas = false
          this.disabledEmpresas = false
        })
      })
    },
    excluir () {
      const routeId = this.$route.params.id

      acessosService.API.Actions.delete(routeId)
        .then((response) => {
          if (response.data.errors) {
            this.$store.dispatch('Erro', response.data.message)
          } else {
            this.$store.dispatch('Sucesso', response.data.message)
          }

          this.dialog = false
          this.$router.push('/acessos')
        }).catch((error) => {
          const errorArray = error.response.data.errors
          if (typeof (errorArray) === 'string') {
            this.$store.dispatch('error', errorArray)
          }

          if (errorArray instanceof Array) {
            for (const error in errorArray) {
              this.$store.dispatch('error', errorArray[error][0])
            }
          }
          this.dialog = false
        })
    }
  }
}
</script>
