<template>
    <v-row justify="center">
        <v-col cols='4' v-for="item in $store.getters.alerts" v-bind:class="{'d-none': !item.alert_visible}" :key="item.alert_message">
            <v-snackbar top right :color="item.alert_color" v-model='$store.state.alerts' elevation='0'>
                <span>{{ item.alert_message }}</span>
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            text
                            v-bind="attrs"
                            @click="item.alert_visible = false"
                        >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </template>
            </v-snackbar>
        </v-col>
    </v-row>
</template>
<script>
export default {
  name: 'dakota-alert',
  data () {
    return {
      alert: true
    }
  }
}
</script>
