<template>
  <div>
    <div v-if="loadingData" class="text-center">
      <h1 class="px-4 pt-4 pb-3 text-h4">
        Recebendo dados do relatório e renderizando
      </h1>
      <div class="dot-flashing d-inline-block"></div>
    </div>
    <div v-else>
      <v-container style="display: grid" class="pa-4" id="tabela">
        <v-row
          style="
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
            grid-template-rows: 1fr 1fr;
            width: 1100px;
          "
        >
          <div class="pa-4" style="text-align: center">
            <img
              :src="require('../../../assets/logo.png')"
              contain
              width="30%"
            />
          </div>
          <div class="pa-4" style="text-align: center">
            <h2>Trade Universo Dakota</h2>
            <h3>TAREFAS</h3>
          </div>
          <div class="pa-4" style="text-align: end">
            <h3>
              {{ datimeNow.split(" ")[0] }}
            </h3>
            <h3>
              {{ datimeNow.split(" ")[1] }}
            </h3>
          </div>
          <div class="" style="text-align: center">
            <h4>
              <!-- Marca: {{dados.marca}} -->
              <img
                :src="require('../../../assets/' + dados.marca)"
                contain
                width="25%"
              />
            </h4>
          </div>
          <div class="" style="text-align: start">
            <h4>Empresa de Trade: {{ dados.trade }}</h4>
            <h4>Contrato: {{ dados.contrato }}</h4>
          </div>
          <div class="" style="text-align: end">
            <h4>
              Recebido De:
              {{
                $route.query.dateInicial.split("-")[2] +
                "/" +
                $route.query.dateInicial.split("-")[1] +
                "/" +
                $route.query.dateInicial.split("-")[0]
              }}
            </h4>
            <h4>
              Recebido Até:
              {{
                $route.query.dateFinal.split("-")[2] +
                "/" +
                $route.query.dateFinal.split("-")[1] +
                "/" +
                $route.query.dateFinal.split("-")[0]
              }}
            </h4>
          </div>
          <div v-if="!printing">
            <v-btn @click="printScreen()" color="success" id="printPageButton"
              >Exportar Relatório
            </v-btn>
          </div>
        </v-row>
        <v-row style="width: 1100px">
          <v-col>
            <v-container class="fill-height">
              <v-data-table
                :headers="headers"
                :items="items"
                style="width: 1100px"
                dense
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.dados.razao_social">
                      <td style="min-width: 150px">
                        <div class="dados" style="min-width: 150px">
                          <b> {{ item.dados.razao_social }}<br /> </b>
                          {{ item.dados.nome_fantasia }}<br />
                          {{ item.dados.logradouro }},
                          {{ item.dados.numero }} Bairro: {{ item.dados.bairro
                          }}<br />
                          {{ item.dados.cidade }} / {{ item.dados.estado
                          }}<br />
                        </div>
                      </td>
                      <td>{{ item.dados.analista }}</td>
                      <!-- <td>{{item.dados.marca}}</td> -->
                      <td>
                        <b>
                          {{ item.dados.descricao }}
                        </b>
                      </td>
                      <td v-if="typeof item.respostas[0] != 'undefined'">
                        {{ item.respostas[0].data_criado.split(" ")[0] }}
                      </td>
                      <td style="width: 650px">
                        <div cols="12" style="width: 100%; display: flex">
                          <div
                            class="cols-12 sm-12 md-6"
                            style="width: 300px; flex-flow: col nowrap"
                          >
                            <div
                              v-for="resposta in item.respostas"
                              :key="resposta.resposta"
                            >
                              <div
                                class="pb-4"
                                v-if="resposta.foto_antes == true"
                                style="
                                  display: grid;
                                  grid-template-columns: 1fr 1fr;
                                "
                              >
                                <div style="float: left" class="pr-2">
                                  <b> Foto Antes: <br /> </b>
                                  Data: {{ resposta.data_hora.split(" ")[0]
                                  }}<br />
                                  Hora: {{ resposta.data_hora.split(" ")[1] }}
                                  <!-- <div v-if="resposta.data_diff > 0" > -->
                                  <v-tooltip
                                    bottom
                                    v-if="resposta.data_diff > 0"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="red"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-exclamation-thick
                                      </v-icon>
                                    </template>
                                    <span
                                      >Diferença entre criação e envio é
                                      superior a 24 horas. ({{
                                        resposta.data_criado
                                      }})</span
                                    >
                                  </v-tooltip>
                                  <br />

                                  <!-- </div> -->

                                  Localização:
                                  <a
                                    :id="`https://maps.google.com/?q=${resposta.geolocalizacao}`"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    :href="`https://maps.google.com/?q=${resposta.geolocalizacao}`"
                                    ><span class="mdi mdi-google-maps"></span
                                  ></a>
                                </div>
                                <div
                                  v-if="resposta.resposta != 'Sem foto antes'"
                                >
                                  <img
                                    v-if="resposta.resposta != 'Sem foto antes'"
                                    width="100"
                                    height="100"
                                    :src="resposta.resposta"
                                    @click="
                                      dialogAntesMetodo(resposta.resposta)
                                    "
                                    style="cursor: pointer"
                                  />
                                </div>
                                <div
                                  v-if="resposta.resposta == 'Sem foto antes'"
                                  class="d-flex align-center"
                                >
                                  <b>
                                    Não foi possível carregar fotos antes nesta
                                    tarefa.
                                  </b>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- == -->
                          <div
                            class="cols-12 sm-12 md-6"
                            style="width: 300px flex-flow:col nowrap"
                          >
                            <div
                              v-for="resposta in item.respostas"
                              :key="resposta.resposta"
                            >
                              <div
                                class="pb-4"
                                v-if="resposta.foto_depois == true"
                                style="
                                  display: grid;
                                  grid-template-columns: 1fr 1fr;
                                "
                              >
                                <div style="float: left" class="pr-2">
                                  <b> Foto Depois: <br /> </b>
                                  Data: {{ resposta.data_hora.split(" ")[0] }}
                                  <br />
                                  Hora: {{ resposta.data_hora.split(" ")[1] }}
                                  <v-tooltip
                                    bottom
                                    v-if="resposta.data_diff > 0"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="red"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-exclamation-thick
                                      </v-icon>
                                    </template>
                                    <span
                                      >Diferença entre criação e envio é
                                      superior a 24 horas. ({{
                                        resposta.data_criado
                                      }})</span
                                    >
                                  </v-tooltip>
                                  <br />
                                  Localização:
                                  <a
                                    :id="`https://maps.google.com/?q=${resposta.geolocalizacao}`"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    :href="`https://maps.google.com/?q=${resposta.geolocalizacao}`"
                                    ><span class="mdi mdi-google-maps"></span
                                  ></a>
                                </div>
                                <div>
                                  <img
                                    width="100"
                                    height="100"
                                    :src="resposta.resposta"
                                    @click="
                                      dialogDepoisMetodo(resposta.resposta)
                                    "
                                    style="cursor: pointer"
                                  />
                                </div>

                                <!-- </div> -->
                              </div>

                              <!-- == -->
                              <div
                                class="pb-4"
                                style="width: 200px"
                                v-if="
                                  resposta.foto_antes != true &&
                                  resposta.foto_depois != true
                                "
                              >
                                <b>DESCARTE</b><br />
                                Motivo: {{ resposta.resposta }}<br />
                                Data: {{ resposta.data_hora.split(" ")[0]
                                }}<br />
                                Hora: {{ resposta.data_hora.split(" ")[1]
                                }}<br />
                                Localização:
                                <a
                                  :id="`https://maps.google.com/?q=${resposta.geolocalizacao}`"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  :href="`https://maps.google.com/?q=${resposta.geolocalizacao}`"
                                  ><span class="mdi mdi-google-maps"></span
                                ></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div>{{ item.dados.comentario }}<br /></div>
                      </td>
                    </tr>
                  </tbody>
                  <template>
                    <div class="text-center">
                      <v-dialog v-model="dialogAntes" width="unset">
                        <v-card class="text-center">
                          <img :src="imageAntes" />
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="error"
                              outlined
                              block
                              text
                              @click="dialogAntes = false"
                            >
                              Fechar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>
                  <template>
                    <div class="text-center">
                      <v-dialog v-model="dialogDepois" width="unset">
                        <v-card class="text-center">
                          <img :src="imageDepois" />
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="error"
                              text
                              block
                              outlined
                              @click="dialogDepois = false"
                            >
                              Fechar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>
                </template>
              </v-data-table>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import relatorioService from "../../../services/relatorioService";
import moment from "moment";
import "@/assets/dot-flashing.css";
export default {
  data: () => ({
    items: [],
    headers: [
      {
        text: "Loja",
        align: "start",
        sortable: false,
      },
      {
        text: "Analista",
        sortable: false,
        align: "start",
      },
      // {
      //   text: 'Marca',
      //   sortable: false,
      //   align: 'start',
      // },
      {
        text: "Tarefa",
        sortable: false,
        align: "start",
      },
      {
        text: "Recebido",
        sortable: false,
        align: "start",
      },
      {
        text: "Resposta",
        sortable: false,
        align: "center",
      },
      {
        text: "Comentario",
        sortable: false,
        align: "start",
      },
    ],
    relatorio: [],
    dialogAntes: false,
    dialogDepois: false,
    imageAntes: "",
    imageDepois: "",
    datimeNow: "",
    loadingData: true,
    dados: [],
  }),
  methods: {
    async popularTabela() {
      this.items = [];
      await relatorioService.API.Actions.getTabelaAcoes(this.$route.query)
        .then((response) => {
          this.items = response.data;
          this.items.forEach((item) => {
            if (!Array.isArray(item.respostas)) {
              item.respostas = Array.of(item.respostas);
            }
            item.respostas.forEach((element) => {
              element.data_hora =
                element.data_hora.split("/")[1] +
                "/" +
                element.data_hora.split("/")[0] +
                "/" +
                element.data_hora.split("/")[2];
              element.data_criado =
                element.data_criado.split("/")[1] +
                "/" +
                element.data_criado.split("/")[0] +
                "/" +
                element.data_criado.split("/")[2];
            });
          });
          this.loadingData = false;
        })
        .catch((error) => {
          this.$store.dispatch("stop_submit");
          const errors = error.response.data.error;
          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
    },
    printScreen() {
      print();
    },
    dialogAntesMetodo(input) {
      this.dialogAntes = true;
      this.imageAntes = input;
    },
    dialogDepoisMetodo(input) {
      this.dialogDepois = true;
      this.imageDepois = input;
    },
    async converterIndices() {
      await relatorioService.API.Actions.converterIndices(this.$route.query)
        .then((response) => {
          this.dados = response.data;
          switch (this.dados.marca) {
            case "Dakota":
              this.dados.marca = "logo_00001.jpg";
              break;
            case "Kolosh":
              this.dados.marca = "logo_00002.jpg";
              break;
            case "Pink Cats":
              this.dados.marca = "logo_00004.jpg";
              break;
            case "Campesí":
              this.dados.marca = "logo_00006.jpg";
              break;
            case "Tanara":
              this.dados.marca = "logo_00007.jpg";
              break;
            case "Mississipi":
              this.dados.marca = "logo_00011.jpg";
              break;
          }
        })
        .catch((error) => {
          this.$store.dispatch("stop_submit");
          const errors = error.response.data.error;
          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
    },
  },
  async mounted() {
    await this.converterIndices();
    this.datimeNow = moment().format("DD/MM/YYYY HH:mm:ss");
    await this.popularTabela();
  },
  computed: {
    dateInicialFormatado() {
      return this.$route.query.dateInicial
        ? moment(this.$route.query.dateInicial).format("DD/MM/YYYY")
        : "";
    },
    dateFinalFormatado() {
      return this.$route.query.dateFinal
        ? moment(this.$route.query.dateFinal).format("DD/MM/YYYY")
        : "";
    },
  },
};
</script>
<style>
.v-data-table {
  max-width: 100%;
}
@media print {
  .v-main,
  .container {
    padding: 0 !important;
  }
  .container > .row > .row {
    width: 100% !important;
  }
  #printPageButton,
  .v-breadcrumbs,
  header,
  .v-footer,
  nav {
    display: none !important;
  }
  .container.fill-height {
    display: block;
  }
  .container > div {
    width: 100% !important;
  }
}
.v-data-table > .v-data-table__wrapper > table {
  border-spacing: 0 0.5rem;
}
.dados {
  font-size: 0.7rem;
}
</style>
