<template>
  <v-container style="display: grid">
    <v-row>
      <v-col>
        <v-card>
          <v-row class="pa-4">
            <v-col cols="12" md="4" sm="6" v-if="$store.getters.userType === 'Admin'">
              <v-autocomplete v-model='filter.trade' label='Empresa de Trade' @input='listarContratos($event)'
                :items='tradeItems' outlined :loading='loadingTrade' :disabled='disabledTrade'></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-autocomplete v-model='filter.contrato' label='Contrato' @input='listarClientes($event) , listarMarcas($event)'
                :items='contratoItems' outlined :loading='loadingContrato' :disabled='disabledContrato'>
              </v-autocomplete>
            </v-col>
            <!-- <v-col cols="12" md="4" sm="6">
              <v-autocomplete v-model='filter.cliente' label='Razão Social' :items='clienteList' outlined
                :loading='loadingCliente' @input='listarMarcas($event)' :disabled='disabledCliente'></v-autocomplete>
            </v-col> -->
            <v-col cols="12" md="4" sm="6">
              <v-autocomplete v-model='filter.cliente' label='Razão Social' :items='clienteList' outlined
                :loading='loadingCliente' :disabled='disabledCliente'></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-autocomplete v-model='filter.marca' label='Marca' :items='marcaItems' outlined :loading='loadingMarca'
                @input='disabledTipo = false, disabledStatus = false, disabledBotao = false, showTable = false' :disabled='disabledMarca'></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete v-model='filter.tipo' label='Tipo' :items='tipoList' outlined :disabled='disabledTipo'
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete v-model='filter.status' label='Status' :items='statusList' outlined :disabled='disabledStatus'
                ></v-autocomplete>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions class="pa-4 justify-end">
            <v-btn :disabled='disabledBotao' color='primary' @click=getRespostasDescartes :loading='loadingTabela'> Gerar Relatório </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="relatorio.length > 0 ">
          <div class="mb-8">
          <v-expansion-panels>
            <v-expansion-panel v-for="item in relatorio" :key="item.id">
              <v-expansion-panel-header>
                <b>{{ item.cliente_nome }}</b> 
                <v-layout justify-end>
                <span class="float-right" v-if="item.status == 'Concluída'" :style="{ color : '#04b81c'}">
                  {{ item.status }}
                </span>
                <span class="float-right" v-if="item.status == 'Em andamento'" :style="{ color : '#0047cc'}">
                  {{ item.status }}
                </span>
                <span class="float-right" v-if="item.status == 'Pendente'" :style="{ color : '#a30000'}">
                  {{ item.status }}
                </span>
                </v-layout>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels v-if="expands != 'Tarefas'  && item.questoes_respostas.length != 0">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <b>Quiz Respondido</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels>
                        <v-expansion-panel v-for="value, name in item.questoes_respostas" :key="name">
                          <v-expansion-panel-header>
                            {{ value.descricao }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <ul style="list-style-type:none">
                              <li v-for="r in value.resposta" :key="r" >
                                <v-col md=2 col=12 class="pa-4" v-if="value.tipo == 0 || value.tipo == 4">
                                {{r}}
                                </v-col>
                                <v-col md=2 col=12 class="pa-4" v-if="value.tipo == 2 || value.tipo == 3">
                                {{r.opcao}}
                                </v-col>
                                <v-col md=2 col=12 class="pa-4" v-if="value.tipo == 1">
                                  <img width="400" :src='r'>
                                </v-col>
                                </li>
                            </ul>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels v-if="expands != 'Tarefas' && item.questoes_descartes.length != 0" >
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <b>Quiz Descartado</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels>
                        <v-expansion-panel v-for="value, name in item.questoes_descartes" :key="name">
                          <v-expansion-panel-header>
                            {{ value.descricao }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <ul style="list-style-type:none">
                              <li v-for="r in value.resposta" :key="r">{{r}}</li>
                            </ul>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels v-if="expands != 'Questões'  && item.tarefas_respostas.length != 0">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <b>Tarefas Realizadas</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels>
                        <v-expansion-panel v-for="value, name in item.tarefas_respostas" :key="name">
                          <v-expansion-panel-header>
                            {{ value.descricao }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <ul style="list-style-type:none">
                              <li v-for="(r, index) in value.resposta.antes" :key="r">
                                <div v-if="index == 0"> 
                                  Fotos antes:
                                </div>
                                <v-col md=2 col=12 class="pa-4">
                                  <img
                                    width="400" 
                                    :src='r'
                                  >
                                </v-col>
                              </li>
                                <!-- <v-divider>
                                </v-divider> -->
                                <li v-for="(r, index) in value.resposta.depois" :key="r">
                                <div v-if="index == 0">
                                  Fotos depois:
                                </div>
                                <v-col md=2 col=12 class="pa-4">
                                  <img
                                    width="400" 
                                    :src='r'
                                  >
                                </v-col>
                                </li>
                            </ul>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels v-if="expands != 'Questões'  && item.tarefas_descartes.length != 0">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <b>Tarefas Descartadas</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels>
                        <v-expansion-panel v-for="value, name in item.tarefas_descartes" :key="name">
                          <v-expansion-panel-header>
                            {{ value.descricao }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <ul style="list-style-type:none">
                              <li v-for="r in value.resposta" :key="r">{{r}}</li>
                            </ul>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import empresaService from '../../../services/empresaService'
  import acoesService from '../../../services/acoesService'
  import relatorioService from '../../../services/relatorioService'
  import contratoService from '../../../services/contratoService'

  export default {
    data: () => ({
      expanded: [],
      singleExpand: false,
      filter: {
        marca: 'Todos',
        cliente: '',
        trade: '',
        tipo: 'Todos',
        contrato: '',
        status:'Todos',
      },
      tradeItems: [],
      marcaItems: [],
      contratoItems: [],
      statusList:['Todos','Concluída','Em andamento', 'Pendente'],
      tipoList: ['Todos','Questões', 'Tarefas'],
      clienteList: ['Todos'],
      loadingTabela: false,
      loadingTrade: true,
      disabledTrade: true,
      loadingMarca: false,
      disabledMarca: true,
      loadingContrato: false,
      disabledContrato: true,
      loadingCliente: false,
      disabledCliente: true,
      disabledTipo: true,
      disabledBotao: true,
      disabledStatus: true,
      showTable: false,
      relatorio: [],
      expanded: [],
      expands:'',
      relatorioHeaders: [{
          text: 'Razão Social',
          align: 'start',
          sortable: false,
          value: 'cliente_nome',
        },
        {
          text: '',
          value: 'data-table-expand'
        },
      ],
    }),
    methods: {
      async listarEmpresas() {
        await empresaService.API.Actions.options().then((response) => {
          response.data.forEach(element => {
            this.tradeItems.push(element)
          })
          this.loadingTrade = false
          this.disabledTrade = false
        })
      },
      async listarMarcas() {
        this.marcaItems = []
        this.loadingMarca = true
        this.filter.cliente = 'Todos'
        await relatorioService.API.Actions.getMarcas(this.filter.cliente, this.filter.contrato).then((response) => {
          response.data.forEach(element => {
            this.marcaItems.push(element)
          })
        })
          this.disabledMarca = false 
          this.loadingMarca = false
      },
      listarContratos() {
        this.contratoItems = []
        this.loadingContrato = true
        this.filter.contrato = []
        contratoService.API.Actions.options(this.filter.trade)
          .then((response) => {
            this.contratoItems = response.data.contratos
            this.contratoItems.forEach((element, key) => {
              this.contratoItems[key].text = element.text + ' - ' + element.total + ' Clientes'
            });

            this.loadingContrato = false
            this.disabledContrato = false
          }).catch(() => {
            this.loadingContrato = false
            this.disabledContrato = true
          })

          
      },
      listarClientes() {
        this.clienteList = ['Todos']
        this.filter.cliente = 'Todos'
        this.loadingCliente = true
        this.filter.cliente = []
        relatorioService.API.Actions.getClientes(this.filter.contrato).then((response) => {
          response.data.clientes.forEach(element => {
            this.clienteList.push(element)
          })
          this.loadingCliente = false
          this.disabledCliente = false
        })
      },


      async getRespostasDescartes() {
        this.showTable = true
        this.loadingTabela =true
        this.expands = this.filter.tipo
        await relatorioService.API.Actions.getRespostasDescartes(this.filter).then((response) => {
          this.relatorio = []
          response.data.forEach(element => {
            this.relatorio.push(element)
          })
          this.loadingTabela = false
        }).catch((error) => {
          this.$store.dispatch('stop_submit')
            const errors = error.response.data.error
            if (typeof (errors) === 'string') {
              this.$store.dispatch('error', errors)
            } else {
              for (const index in errors) {
                this.$store.dispatch('error', errors[index][0])
              }
            }
            this.loadingTabela = false
          })
      },
    },

    async mounted() {
      if (this.$store.getters.userType === 'Admin') {
        await this.listarEmpresas()
      }else if (this.$store.getters.userType !== 'Admin'){
        this.filter.trade = this.$store.getters.userEmpresaId
        this.listarContratos()
        this.disabledContrato = false
      }
    },

    computed: {}
  }

</script>
