<template>
  <v-main>
    <v-container fill-height fluid>
      <v-row class='justify-center'>
        <v-col md='3' sm='12'>
          <v-sheet elevation='6' class='grey lighten-4'>
            <v-form>
              <v-col>
                <v-img :src='require("../assets/logo.png")' contain height='90'/>
              </v-col>
              <v-container class='pb-10'>
                <v-row>
                  <v-col cols='12'>
                    <h4 class='text-center pb-5'>Download do App</h4>
                    
                  </v-col>
                  <v-col cols='12'>
                    
                  </v-col>
                  <v-col cols='12 text-start' class="md-4">
                    <ul>
                      <li v-for="link in links" :key="link">
                        <a @click="receberArquivo(link)">
                        {{link}}
                        </a>
                        <!-- <a href=""> {{link}}</a> -->
                      </li>

                    </ul>
                    <!-- <v-btn block rounded class='primary' @click="listarArquivos()">
                      Baixar App
                    </v-btn> -->
                    <!-- <v-btn block rounded class='primary' href='/app/trade-1.10.apk' @click="listarArquivos()" download>
                      Baixar App
                    </v-btn> -->
                  </v-col>
                  <v-col class="12 text-end">
                     <small>
                      Versão: {{ $store.getters.version }}
                     </small>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import appDownloadService from '../services/appDownloadService'

export default {
  data () {
    return {
      links: [],
    }
  },

  methods: {
    listarArquivos(){
      appDownloadService.API.Actions.list().then((response) => {
        response.data.forEach(element => {
          this.links.push(element)
        });
          })
    },
    receberArquivo(arquivo){
      appDownloadService.API.Actions.find(arquivo).then((response) => {
      this.downloadFile(response, 'Trade-App')
          })
    },
    downloadFile(response, filename) {

      var newBlob = new Blob([response.data], {type: 'application/vnd.android.package-archive'})

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)
        return
      }

      const data = window.URL.createObjectURL(newBlob)
      var link = document.createElement('a')
      link.href = data
      link.download = filename + '.apk'
      link.click()
      setTimeout(function () {
        window.URL.revokeObjectURL(data)
      }, 100)
    },
    },
    mounted(){
      this.listarArquivos()
    }
}
</script>
