import client from '../plugins/apiClient'

export default {
  API: {
    Actions: {
      list: () => {
        client.login()
        return client.get('usuarios')
      },
      create: (objeto) => {
        client.login()
        return client.post('usuario', objeto)
      },
      delete: (id) => {
        client.login()
        return client.delete('usuario/' + id)
      },
      update: (id, objeto) => {
        client.login()
        return client.put('usuario/' + id, objeto)
      },
      find: (id) => {
        client.login()
        return client.get('usuario/' + id)
      },
      trocarSenha: (objeto) => {
        client.login()
        return client.put('trocar-senha', objeto)
      },
      getTipos: () => {
        client.login()
        return client.get('/usuarios-tipo')
      }
    }
  }
}
