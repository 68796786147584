<template>
  <v-main>
    <v-container fill-height fluid>
      <v-row class='justify-center'>
        <v-col md='3' sm='12'>
          <v-sheet elevation='6' class='grey lighten-4'>
            <v-form>
              <v-col>
                <v-img :src='require("../assets/logo.png")' contain height='90'/>
              </v-col>
              <v-container class='pb-10'>
                <v-row>
                  <v-col cols='12'>
                    <h4 class='text-center pb-5'>Trade</h4>
                    <v-text-field
                      hide-details='auto'
                      v-model='user'
                      label='Usuario'
                      v-on:keyup.enter='login'
                      outlined
                      dense
                      filled
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12'>
                    <v-text-field
                      type='password'
                      hide-details='auto'
                      v-model='password'
                      label='Senha'
                      v-on:keyup.enter='login'
                      required
                      outlined
                      dense
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12 text-end'>
                    <v-btn block rounded class='primary' @click='login' :loading='$store.getters.loading'>
                      Entrar
                    </v-btn>
                  </v-col>
                  <v-col class="12 text-end">
                     <small>
                      Versão: {{ $store.getters.version }}
                     </small>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import loginService from '../services/loginService'

export default {
  data () {
    return {
      user: '',
      password: ''
    }
  },

  methods: {
    login () {
      this.$store.dispatch('start_load')
      loginService.API.Actions.login(this.user, this.password)
        .then((response) => {
          this.$store.commit('login', {...response.data, user: this.user})
          loginService.API.Actions.getUser()
            .then((response) => {
              this.$store.dispatch('setUserType', response.data)
              this.$store.dispatch('stop_load')
              let routeName = 'Base';
              let routeQuery = {};
              if(Object.keys(this.$route.query).length){
                try {
                  const data = JSON.parse(atob(this.$route.query.redirect));
                  routeName = data.name;
                  routeQuery = data.query;
                } catch (error) {

                }
              }
              return this.$router.push({
                name: routeName,
                query: routeQuery
              })
            })
        })
        .catch(() => {
          alert('Não foi possivel autenticar')
          this.$store.dispatch('stop_load')
        })
    }
  }
}
</script>
