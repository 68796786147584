import client from '../plugins/apiClient'

export default {
  API: {
    Actions: {
      list: () => {
        // client.login()
        return client.get('AppDownloads')
      },
      find: (id) => {
        // client.login()
        return client.get('AppDownload/' + id, {responseType: 'arraybuffer'})
      }
    }
  }
}
