<template>
  <v-container style="display: grid">
    <v-row>
      <v-col>
        <!-- FILTROS E BOTÕES DE AÇÃO -->
        <v-card>
          <!-- FILTROS -->
          <v-row class="pl-4 pr-4 pt-4">
            <!-- EMPRESA DE TRADE -->
            <v-col
              cols="12"
              md="4"
              sm="6"
              v-if="
                ['Admin', 'Representante'].indexOf($store.getters.userType) > -1
              "
            >
              <v-autocomplete
                v-model="filter.trade"
                label="Empresa de Trade"
                @input="listarContratos($event), listarResponsaveis()"
                :items="tradeItems"
                outlined
                :loading="loadingTrade"
                :disabled="disabledTrade"
              ></v-autocomplete>
            </v-col>
            <!-- CONTRATO -->
            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                v-model="filter.contrato"
                label="Contrato"
                @input="
                  listarClientes($event), listarMarcas($event), listarMeses()
                "
                :items="contratoItems"
                outlined
                :loading="loadingContrato"
                :disabled="disabledContrato"
              >
              </v-autocomplete> </v-col
            ><!-- /CONTRATO -->
            <v-col cols="12" md="4" sm="6">
              <v-autocomplete
                v-model="filter.cliente"
                label="Razão Social"
                :items="clienteItems"
                outlined
                :loading="loadingCliente"
                :disabled="disabledCliente"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" sm="6">
              <v-autocomplete
                v-model="filter.marca"
                label="Marca"
                :items="marcaItems"
                outlined
                :loading="loadingMarca"
                @input="
                  (disabledDataInicial = false),
                    (disabledMes = false),
                    (disabledBotao = false),
                    (disabledPdf = false),
                    (disabledDataFinal = false),
                    (disabledAnalista = false)
                "
                :disabled="disabledMarca"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" sm="3">
              <v-autocomplete
                v-model="filter.tasks_situation"
                label="Situação das tarefas"
                :items="tasks_situation"
                outlined
                :disabled="disabledContrato"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" sm="3">
              <v-autocomplete
                v-model="filter.mes"
                label="Mês da visita"
                :items="mesesItems"
                outlined
                :disabled="disabledMes"
              >
              </v-autocomplete>
            </v-col>
            <!-- ANALISTA -->
            <v-col cols="12" md="2" sm="3">
              <v-autocomplete
                v-model="filter.analista"
                label="Analista"
                :items="analistaFiltroList"
                outlined
                :loading="loadingAnalista"
                :disabled="disabledAnalista"
              ></v-autocomplete>
            </v-col>
            <!-- RECEBIDO DE -->
            <v-col cols="12" md="2">
              <v-menu
                v-model="menuInicial"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dateInicialFormatado"
                    label="Recebido De:"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :disabled="disabledDataInicial"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filter.dateInicial"
                  @input="menuInicial = false"
                  :disabled="disabledDataInicial"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- RECEBIDO ATÉ -->
            <v-col cols="12" md="2">
              <v-menu
                v-model="menuFinal"
                :close-on-content-click="false"
                nudge-left="115"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dateFinalFormatado"
                    label="Recebido Até:"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :disabled="disabledDataFinal"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filter.dateFinal"
                  @input="menuFinal = false"
                  :disabled="disabledDataFinal"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <!-- BOTÕES DE AÇÃO -->
          <v-card-actions class="justify-space-between">
            <div>
              <ul>
                <li class="d-inline-block" v-for="(total, pergunta) in totais">
                  {{ pergunta }}
                  <ul class="d-inline-block">
                    <li
                      class="d-inline-block pr-3"
                      v-for="(total, resposta) in total"
                    >
                      <v-chip
                        class="resposta"
                        :color="total.selected ? 'green' : 'orange'"
                        label
                        :outlined="total.selected ? false : true"
                        :text-color="total.selected ? 'white' : 'black'"
                        @click="selectSubFilter(pergunta, resposta)"
                        >{{ resposta }} ({{ total.total }} -
                        {{
                          ((total.total / items.length) * 100)
                            .toFixed(0)
                            .replace(".", ",")
                        }}%)</v-chip
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div>
              <!-- GERAR RELATÓRIO -->
              <v-btn
                @click="popularTabela()"
                :disabled="disabledBotao"
                color="primary"
                class="mx-2"
                :loading="loadingBotao || loadingPdf"
              >
                Gerar Relatório
              </v-btn>
              <!--  GERAR PDF -->
              <v-btn
                :disabled="disabledPdf"
                color="primary"
                :loading="loadingPdf || loadingBotao"
                @click="downloadPdf"
              >
                Gerar PDF
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="px-4">
          <v-container v-if="showTable == true" class="fill-height" fluid>
            <v-data-table
              :headers="headers"
              :items="filteredItems"
              style="overflow: auto; width: 100%"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr
                    v-for="item in items"
                    :data-atlac-id="item.dados.atlac"
                    v-if="item.visible"
                  >
                    <td>
                      <div style="text-align: center">
                        <b> {{ item.dados.nome_fantasia }}<br /> </b>
                        {{ item.dados.razao_social }}<br />
                        {{ item.dados.logradouro }},
                        {{ item.dados.numero }} Bairro: {{ item.dados.bairro
                        }}<br />
                        {{ item.dados.cidade }} / {{ item.dados.estado }}<br />
                      </div>
                    </td>
                    <td>
                      <div style="text-align: center">
                        {{ item.dados.mes_data.split("-")[1] }} -
                        {{ item.dados.visitas }}
                      </div>
                    </td>

                    <td>
                      <div style="text-align: center">
                        {{ item.dados.analista }}
                      </div>
                    </td>
                    <td>
                      <div style="text-align: center">
                        {{ item.dados.marca }}
                      </div>
                    </td>
                    <td>
                      {{ item.dados.data_criado | dateFormat }}
                    </td>

                    <td v-for="pergunta in perguntas" :key="pergunta">
                      <div
                        v-for="resposta in item.respostas"
                        :key="resposta.acoes_trade_questoes_relatorio_id"
                        style="width: 100%"
                      >
                        <div
                          class="pb-4 pt-4"
                          style="text-align: center"
                          v-if="resposta.pergunta == pergunta"
                        >
                          <div
                            v-if="
                              resposta.tipo == 'Foto' &&
                              resposta.resposta != null &&
                              resposta.descarte != true &&
                              resposta.resposta != 'Sem foto antes'
                            "
                          >
                            <div
                              @click="dialogImgMetodo(resposta, item.respostas)"
                            >
                              <ImageLoader
                                width="100px"
                                height="100px"
                                :src="resposta.resposta"
                                style="display: table-cell; cursor: pointer"
                              />
                            </div>
                          </div>
                          <div
                            v-if="
                              resposta.tipo == 'Foto' &&
                              resposta.resposta == 'Sem foto antes'
                            "
                            class="d-flex align-center"
                            style="width: 100px; margin-left: 1px"
                          >
                            <b>
                              Não foram registradas fotos para essa questão.
                            </b>
                          </div>
                          <div
                            v-if="
                              resposta.tipo == 'Escala' &&
                              resposta.descarte != true &&
                              resposta.resposta != null
                            "
                          >
                            {{ resposta.resposta }}<br />
                          </div>
                          <div
                            v-if="
                              resposta.tipo == 'Múltipla Escolha' &&
                              resposta.descarte != true &&
                              resposta.resposta != null
                            "
                          >
                            {{ resposta.resposta }}<br />
                          </div>
                          <div
                            v-if="
                              resposta.tipo == 'Única Escolha' &&
                              resposta.descarte != true &&
                              resposta.resposta != null
                            "
                          >
                            {{ resposta.resposta }}<br />
                          </div>
                          <div
                            v-if="
                              resposta.tipo == 'Texto' &&
                              resposta.descarte != true &&
                              resposta.resposta != null
                            "
                          >
                            {{ resposta.resposta }}<br />
                          </div>
                          <div
                            v-if="
                              resposta.tipo == 'Foto' &&
                              resposta.resposta != null &&
                              resposta.descarte == true
                            "
                          >
                            <b>DESCARTE</b><br />
                            Motivo: {{ resposta.resposta }}<br />
                          </div>
                          <div
                            v-if="
                              resposta.tipo != 'Foto' &&
                              resposta.descarte == true
                            "
                          >
                            <b>DESCARTE</b><br />
                            Motivo: {{ resposta.resposta }}<br />
                          </div>
                        </div>

                        <!-- <div class="pb-4 pt-4" style="width: 300px;text-align:start"
                          v-if="resposta.pergunta == pergunta.split('|')[0].trim()"> -->
                        <!-- <div
                            v-if="pergunta.split('|')[1].trim() == 'Foto' && resposta.resposta != null && resposta.descarte != true" >
                            <img width="100" height="100" style="display: table-cell; cursor:pointer;" :src='resposta.resposta'
                              @click="dialogImg = true" >
                            <template>
                              <v-dialog v-model="dialogImg" width="500">
                                <v-card>
                                  <img width="500" height="500" :src='resposta.resposta'>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" outlined block text @click="dialogImg = false">
                                      Fechar
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </template>
                          </div> -->
                        <!-- <div
                            v-if="pergunta.split('|')[1].trim() == 'Foto' && resposta.resposta != null && resposta.descarte == true" >
                            <b>DESCARTE</b><br>
                            Motivo: {{resposta.resposta}}<br>
                          </div>
                          <div
                            v-if="pergunta.split('|')[1].trim() != 'Foto' && resposta.descarte != true && resposta.resposta != null" >
                            {{resposta.resposta}}<br>
                          </div>
                          <div v-if="pergunta.split('|')[1].trim() != 'Foto' && resposta.descarte == true " >
                            <b>DESCARTE</b><br>
                            Motivo: {{resposta.resposta}}<br>
                          </div> -->
                        <!-- </div> -->
                      </div>
                    </td>
                    <td v-if="isAdmin">
                      <v-btn
                        icon
                        title="Apagar essa tarefa"
                        @click="showDeleteModal(item.dados.atlac)"
                        ><v-icon> mdi-delete </v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
                <template>
                  <v-dialog
                    v-model="dialogImg"
                    width="80%"
                    @keydown.esc="dialogImg = false"
                    tabindex="0"
                    scrollable
                  >
                    <v-card class="text-center">
                      <v-card-actions class="d-flex flex-row-reverse">
                        <v-icon color="error" @click="dialogImg = false">
                          mdi-close-thick
                        </v-icon>
                      </v-card-actions>
                      <template>
                        <v-carousel
                          v-model="imageDialog"
                          height="auto"
                          style="width: max-content"
                          class="d-flex justify-center align-self-center align-center"
                          :show-arrows="false"
                        >
                          <v-carousel-item
                            v-for="item in imageDialogLista"
                            :key="item[imageDialog]"
                            height="100%"
                          >
                            <ImageLoader :src="item.resposta" />
                          </v-carousel-item>
                        </v-carousel>
                      </template>
                      <div
                        class="pa-4"
                        style="display: flex; justify-content: center"
                      >
                        <b class="text-center">
                          {{ imageDialogPergunta[imageDialog] }}
                        </b>
                      </div>
                      <div class="v-window__prev">
                        <v-btn
                          @click="mudarImagem('anterior')"
                          class="v-btn v-btn--icon v-btn--round theme--dark v-size--default"
                          type="button"
                          aria-label="Visão anterior"
                        >
                          <span class="v-btn__content">
                            <i
                              class="v-icon notranslate mdi mdi-chevron-left theme--dark"
                              aria-hidden="true"
                              style="font-size: 36px"
                            ></i>
                          </span>
                        </v-btn>
                      </div>
                      <div class="v-window__next">
                        <v-btn
                          @click="mudarImagem('proxima')"
                          class="v-btn v-btn--icon v-btn--round theme--dark v-size--default"
                          type="button"
                          aria-label="Próxima visão"
                        >
                          <span class="v-btn__content">
                            <i
                              class="v-icon notranslate mdi mdi-chevron-right theme--dark"
                              aria-hidden="true"
                              style="font-size: 36px"
                            ></i>
                          </span>
                        </v-btn>
                      </div>
                    </v-card>
                  </v-dialog>
                </template>
              </template>
            </v-data-table>
          </v-container>
          <v-row justify="center">
            <v-dialog v-model="deleteTask.visible" scrollable width="500">
              <v-card>
                <v-card-title class="pa-0">
                  <v-toolbar color="#F5F5F5">
                    <v-toolbar-title
                      class="pr-4"
                      style="color: #1a1a1a; font-size: 17px; font-weight: bold"
                    >
                      Confirmar EXCLUSÃO dessa visita?
                    </v-toolbar-title>
                    <slot name="btnTitle" />
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      dark
                      :disabled="deleteTask.loading"
                      @click="deleteTask.visible = false"
                      color="#1a1a1a"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                </v-card-title>
                <v-card-text class="mt-6">
                  <p class="font-weight-black">
                    Esta ação irá realizar a exclusão dos registros no
                    "Relatório - Tabela de Questões" e no "Relatório - Tabela de
                    Tarefas", confirma a exclusão destes registros para esta
                    visita?
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-row no-gutters justify="end">
                    <v-btn
                      class="mx-2"
                      :disabled="deleteTask.loading"
                      @click="deleteTask.visible = false"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      class="mx-2"
                      :loading="deleteTask.loading"
                      color="error"
                      @click="handleDelete"
                      >Confirmar EXCLUSÃO</v-btn
                    >
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import empresaService from "../../../services/empresaService";
import relatorioService from "../../../services/relatorioService";
import contratoService from "../../../services/contratoService";
import clientesService from "../../../services/clientesService";
import ImageLoader from "../../../components/DakotaImageLoader.vue";
import moment from "moment";

export default {
  data: () => ({
    filter: {
      marca: "Todos",
      cliente: "",
      trade: "",
      contrato: "",
      dateInicial: "",
      dateFinal: "",
      analista: "Todos",
      mes: "Todos",
      tasks_situation: 1,
    },
    tasks_situation: [
      {
        value: 1,
        text: "Todos",
      },
      {
        value: 2,
        text: "Realizadas",
      },
      {
        value: 3,
        text: "Descartadas",
      },
    ],
    items: [],
    headers: [
      {
        text: "Loja",
        align: "start",
        sortable: false,
        // value: 'razao_social'
      },
      {
        text: "Mês da visita",
        sortable: false,
        align: "start",
      },
      {
        text: "Analista",
        sortable: false,
        align: "start",
      },
      {
        text: "Marca",
        sortable: false,
        align: "start",
        // value: 'marca',
      },
      {
        text: "Recebido",
        sortable: false,
        align: "start",
      },
    ],
    deleteTask: {
      loading: false,
      visible: false,
      atlac_id: 0,
    },
    menuInicial: false,
    menuFinal: false,
    tradeItems: [],
    marcaItems: [],
    contratoItems: [],
    mesesItems: ["Todos"],
    clienteItems: ["Todos"],
    analistaFiltroList: ["Todos"],
    loadingCliente: false,
    loadingContrato: false,
    loadingMarca: false,
    loadingTrade: true,
    loadingBotao: false,
    loadingAnalista: true,
    disabledAnalista: true,
    disabledBotao: true,
    disabledContrato: true,
    disabledCliente: true,
    disabledMarca: true,
    disabledMes: true,
    disabledTrade: true,
    disabledDataInicial: true,
    disabledDataFinal: true,
    disabledPdf: true,
    loadingPdf: false,
    showTable: false,
    relatorio: [],
    perguntas: [],
    // tipo:[],
    dados: [],
    dialogImg: false,
    imgExpandida: "",
    imageDialogStatus: "",
    imageDialog: 0,
    imageDialogLista: [],
    imageDialogPergunta: [],
    totais: {},
  }),
  methods: {
    async listarEmpresas() {
      await empresaService.API.Actions.options().then((response) => {
        response.data.forEach((element) => {
          this.tradeItems.push(element);
        });
        this.loadingTrade = false;
        this.disabledTrade = false;
      });
    },
    async listarMarcas() {
      this.marcaItems = [];
      this.loadingMarca = true;
      this.filter.cliente = "Todos";
      let context = {};

      if (["Representante"].indexOf(this.$store.getters.userType) > -1) {
        context = {
          params: {
            empresaTradeId: this.filter.trade,
          },
        };
      }

      await relatorioService.API.Actions.getMarcas(
        this.filter.cliente,
        this.filter.contrato,
        context
      ).then(({ data }) => {
        this.marcaItems = [...data].sort((a, b) =>
          a.text.toUpperCase() > b.text.toUpperCase() ? 1 : -1
        );
      });
      this.disabledMarca = false;
      this.loadingMarca = false;
    },
    listarContratos() {
      this.contratoItems = [];
      this.marcaItems = [];
      this.loadingContrato = true;
      this.filter.contrato = [];
      this.filter.marca = [];
      contratoService.API.Actions.options(this.filter.trade)
        .then((response) => {
          this.contratoItems = response.data.contratos;
          this.contratoItems.forEach((element, key) => {
            this.contratoItems[key].text =
              element.text + " - " + element.total + " Clientes";
          });

          this.loadingContrato = false;
          this.disabledContrato = false;
        })
        .catch(() => {
          this.loadingContrato = false;
          this.disabledContrato = true;
        });
    },
    listarMeses() {
      this.mesesItems = [];
      this.loadingMes = true;
      relatorioService.API.Actions.getMeses(this.filter.contrato)
        .then((response) => {
          this.mesesItems = response.data;

          this.loadingMes = false;
          this.disabledMes = false;
        })
        .catch(() => {
          this.loadingMes = false;
          this.disabledMes = true;
        });
    },
    listarResponsaveis() {
      this.analistaFiltroList = ["Todos"];
      clientesService.API.Actions.analista_options(this.filter.trade)
        .then((response) => {
          response.data.forEach((element) => {
            this.analistaFiltroList.push(element);
          });
          this.loadingAnalista = false;
        })
        .catch((error) => {
          this.loadingAnalista = false;
          const errors = error;
          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        });
    },
    listarClientes() {
      this.clienteItems = ["Todos"];
      this.filter.cliente = "Todos";
      this.loadingCliente = true;
      this.filter.cliente = [];
      relatorioService.API.Actions.getClientes(this.filter.contrato).then(
        (response) => {
          response.data.clientes.forEach((element) => {
            if (this.$store.getters.userType == "Admin") {
              element.text = `${element.value} - ${element.text}`;
            }
            this.clienteItems.push(element);
          });
          this.loadingCliente = false;
          this.disabledCliente = false;
        }
      );
    },
    popularTabela() {
      this.showTable = false;
      this.loadingBotao = true;
      this.perguntas = [];
      this.headers = [];
      this.dados = [];
      this.items = [];
      this.totais = {};
      relatorioService.API.Actions.getTabelaQuiz(this.filter)
        .then((response) => {
          response.data.forEach((questao) => {
            // PROCEDIMENTO PARA FILTRAR AS PERGUNTAS E ADD NO data "perguntas"
            questao.respostas.forEach((resposta) => {
              // CONTABILIZA APENAS AS QUESTÕES QUE NÃO SEJA DESCARTE
              if (resposta.tabela_origem == "Respostas") {
                if (
                  ["Interesse e espaço para painel da marca?"].indexOf(
                    resposta.pergunta
                  ) > -1
                ) {
                  // VERIFICA SE A PROPRIEDADE CORRESPONDENTE À 'RESPOSTA.PERGUNTA' EXISTE EM 'THIS.TOTAIS'.
                  // SE NÃO EXISTIR, INICIALIZA COM UM OBJETO VAZIO ANTES DE CRIAR 'RESPOSTA'.
                  // EM JS, ??= SIGNIFICA NULLISH COALESCING ASSIGNMENT" (ATRIBUIÇÃO DE COALESCÊNCIA NULA)
                  // É UMA COMBINAÇÃO DO OPERADOR DE ATRIBUIÇÃO (=) COM O OPERADOR DE COALESCÊNCIA NULA (??)
                  this.totais[resposta.pergunta] ??= {};

                  // VERIFICA SE A PROPRIEDADE CORRESPONDENTE À 'resposta.resposta' EXISTE DENTRO DO
                  // OBJETO 'this.totais[resposta.pergunta]'. SE NÃO EXISTIR,
                  // INICIALIZA COM UM OBJETO CONTENDO 'total' COMO 0 E 'selected' COMO true.
                  // EM JS, ??= SIGNIFICA NULLISH COALESCING ASSIGNMENT" (ATRIBUIÇÃO DE COALESCÊNCIA NULA)
                  // É UMA COMBINAÇÃO DO OPERADOR DE ATRIBUIÇÃO (=) COM O OPERADOR DE COALESCÊNCIA NULA (??)
                  this.totais[resposta.pergunta][resposta.resposta] ??= {
                    total: 0,
                    selected: false,
                  };

                  // INCREMENTA O VALOR DE 'total' DO OBJETO 'this.totais[resposta.pergunta][resposta.resposta]' EM 1.
                  this.totais[resposta.pergunta][resposta.resposta].total += 1;
                }
              }

              // ADICIONA AS PESGUNTAS MESMO SENDO DPLICADAS PARA EMAIS ABAIXO FILTRAR
              this.perguntas.push(resposta.pergunta);

              this.dados.push({
                pergunta: resposta.pergunta,
                tipo: resposta.tipo,
                escala_de: resposta.escala_de,
                escala_ate: resposta.escala_ate,
              });
            });
            // IMPORTANTE POIS DEFINE QUAL LINHA DEVE SER VISIVEL NA HORA DE USAR O SUBFILTRO
            questao.visible = true;
            this.items.push(questao);
          });
          // REMOVER PERGUNTAS DUPLICADAS
          this.perguntas = [...new Set(this.perguntas)];
          // RECARREGA O HEADERS NA SITUAÇÃO DE GERAR UM SEGUNDO RELATÓRIO
          this.headers = [
            {
              text: "Loja",
              align: "start",
              sortable: false,
              // value: 'razao_social'
            },
            {
              text: "Mês da visita",
              sortable: false,
              align: "start",
            },
            {
              text: "Analista",
              sortable: false,
              align: "start",
            },
            {
              text: "Marca",
              sortable: false,
              align: "start",
              // value: 'marca',
            },
            {
              text: "Recebido",
              sortable: false,
              align: "start",
            },
          ];
          // INCLUI AS PERGUNTAS NO CABEÇALHO
          this.perguntas.forEach((_, key) => {
            this.headers.push({
              text: this.perguntas[key],
              align: "center",
              sortable: false,
            });
          });

          if (this.isAdmin) {
            this.headers.push({
              text: "Ações",
              sortable: false,
              align: "end",
            });
          }
          this.showTable = true;
          this.loadingBotao = false;
        })
        .catch((error) => {
          this.loadingBotao = false;
          let errorMessage = "Erro interno, comunique o administrador";
          if (error.response?.data.message) {
            errorMessage = error.response.data.message + "\n";
            if (error.response.data?.errors) {
              errorMessage += Object.values(error.response.data.errors)
                .map((v) => `- ${v}`)
                .join("\n");
            }
          }
          this.$store.dispatch("error", errorMessage);
          this.$store.dispatch("stop_submit");
        });
    },
    selectSubFilter(pergunta, resposta) {
      // Nessesário para que o vue perceba que está havendo mudanças nos filtros
      // Não testei a capacidade de usar o nexttick
      const updatedTotais = { ...this.totais };
      updatedTotais[pergunta][resposta].selected =
        !updatedTotais[pergunta][resposta].selected;
      this.totais = updatedTotais;
    },

    downloadPdf() {
      this.loadingBotao = true;
      relatorioService.API.Actions.getTabelaQuiz({
        type: "pdf",
        ...this.filter,
      })
        .then(({ data }) => {
          open(data.payload, "_blank");
        })
        .catch((error) => {
          this.$store.dispatch("stop_submit");
          console.error(error);
          const errors = error.response.data.error;
          if (typeof errors === "string") {
            this.$store.dispatch("error", errors);
          } else {
            for (const index in errors) {
              this.$store.dispatch("error", errors[index][0]);
            }
          }
        })
        .finally(() => {
          this.loadingBotao = false;
        });
    },
    mudarImagem(direcao) {
      if (this.imageDialogLista.length > 1) {
        if (direcao == "anterior") {
          if (this.imageDialog == 0) {
            this.imageDialog = this.imageDialogLista.length;
          }

          const prevIndex =
            (this.imageDialog - 1) % this.imageDialogLista.length;

          this.imageDialog = prevIndex;
        } else if (direcao == "proxima") {
          const nextIndex =
            (this.imageDialog + 1) % this.imageDialogLista.length;
          this.imageDialog = nextIndex;
        }
      }
    },
    dialogImgMetodo(input, list) {
      this.dialogImg = true;

      let lista = [];
      list.forEach((resposta) => {
        if (resposta.tipo == "Foto" && resposta.resposta) {
          lista.push(resposta);
          this.imageDialogPergunta.push(resposta.pergunta);
        }
      });
      this.imageDialogLista = lista;
      this.imageDialog = lista.indexOf(input);
    },
    moment: function () {
      return moment();
    },
    showDeleteModal(id_atlac) {
      this.deleteTask.visible = true;
      this.deleteTask.atlac_id = id_atlac;
    },
    handleDelete() {
      this.$store.dispatch("start_submit");
      this.deleteTask.loading = true;
      this.showTable = false;
      this.totais = [];

      relatorioService.API.Actions.visitasDelete({
        atlacId: this.deleteTask.atlac_id,
      })
        .then((response) => {
          this.$store.dispatch("success", response.data.message);
        })
        .catch((error) => {
          let errorMessage = "Erro interno, comunique o administrador";
          if (error.response?.data.message) {
            errorMessage = error.response.data.message + "\n";
            if (error.response.data?.errors) {
              errorMessage += Object.values(error.response.data.errors)
                .map((v) => `- ${v}`)
                .join("\n");
            }
          }
          this.$store.dispatch("error", errorMessage);
        })
        .finally(() => {
          this.$store.dispatch("stop_submit");
          this.deleteTask.loading = false;
          this.deleteTask.visible = false;
          this.popularTabela();
        });
    },
  },
  mounted() {
    let self = this;
    window.addEventListener("keyup", function (event) {
      if (event.keyCode === 27) {
        self.dialogImg = false;
      }
    });

    this.filter.dateInicial = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);
    this.filter.dateFinal = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);

    if (["Trade"].indexOf(this.$store.getters.userType) > -1) {
      this.filter.trade = this.$store.getters.userEmpresaId;
      this.listarContratos();
      this.listarResponsaveis();
    } else {
      this.listarEmpresas();
    }
  },
  computed: {
    dateInicialFormatado() {
      return this.filter.dateInicial
        ? moment(this.filter.dateInicial).format("DD/MM/YYYY")
        : "";
    },
    dateFinalFormatado() {
      return this.filter.dateFinal
        ? moment(this.filter.dateFinal).format("DD/MM/YYYY")
        : "";
    },
    filteredItems() {
      return this.items.filter((item) => {
        let isVisible = true;

        for (let resposta of item.respostas) {
          const pergunta = resposta.pergunta;
          const resp = resposta.resposta;

          if (this.totais[pergunta]) {
            const allFalse = Object.values(this.totais[pergunta]).every(
              (value) => !value.selected
            );

            if (
              resposta.tabela_origem == "Respostas" &&
              !allFalse &&
              (!this.totais[pergunta][resp] ||
                !this.totais[pergunta][resp].selected)
            ) {
              isVisible = false;
              break;
            }
          }
        }
        return isVisible;
      });
    },
    isAdmin() {
      return this.$store.getters.userType === "Admin";
    },
  },
  components: { ImageLoader },
  watch: {
    dialogImg: function (newVal) {
      if (!newVal) {
        setTimeout(() => {
          this.imageDialogPergunta = [];
          this.imageDialogLista = [];
          this.imageDialogStatus = [];
        }, 1000);
      }
    },
  },
};
</script>
<style>
.v-data-table {
  max-width: 100%;
}

.cara,
.cara .v-window__container,
.cara .v-carousel__item {
  height: auto !important;
}

.v-window__container {
  height: 0;
}

.v-card > :last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

.resposta {
  cursor: pointer;
}
</style>
