import client from '../plugins/apiClient'

export default {
  API: {
    Actions: {
      list: function (options, filter = null) {

        let param = {
          page: options.page,
          itemsPerPage: options.itemsPerPage
      }

        client.login()
        param = Object.assign({}, param, filter)

        return client.get('acoes_trade_app', {
          params: param
        })
        // return client.get('acoes_trade_app')
      },
      status:() =>{
        client.login()
        return client.get('acaoTradeStatus')
      },
      request: (id) => {
        client.login()
        return client.get('acao_trade_app/' + id)
      },
      analista_options: (id) => {
        client.login()
        return client.get('analista-options/' + id)
      },
      update: (objeto) => {
        client.login()
        return client.put('/acao_trade_login', objeto)
      },
      descarte: (objeto) => {
        client.login()
        return client.put('/acao_trade_app_descarte', objeto)
      },
      descarteMultiplo: (objeto) => {
        client.login()
        return client.put('/acao_trade_app_descarte_multiplo', objeto)
      },
      responsavelMultiplo: (objeto) => {
        client.login()
        return client.put('/acao_trade_app_responsavel_multiplo', objeto)
      },
      reabrir: (objeto) => {
        client.login()
        return client.put('/reabrir', objeto)
      },
      visitas: (objeto) => {
        client.login()
        return client.post('/visitas', objeto)
      },
    }
  }
}
