import Vue from "vue";
import VueRouter from "vue-router";
import Base from "../views/Base.vue";
import Login from "../views/Login.vue";
import AppDownload from "../views/AppDownload.vue";
import store from "../store/index";
import acessos from "../modules/acessos/router";
import acoes from "../modules/acoes/router";
import clientes from "../modules/clientes/router";
import contratos from "../modules/contratos/router";
import empresas from "../modules/empresas/router";
import opcoes from "../modules/opcoes/router";
import questoes from "../modules/questoes/router";
import tarefas from "../modules/tarefas/router";
import usuarios from "../modules/usuarios/router";
import relatorio from "../modules/relatorio/router";
import tabelaDeAcoesPrint from "../modules/relatorio/views/tabelaDeAcoesPrint";
import tabelaDeQuizPrint from "../modules/relatorio/views/tabelaDeQuizPrint";

Vue.use(VueRouter);

var apps = { BASE: 0, AUTH: 1 };

var routes = [
  {
    path: "",
    name: "Base",
    component: Base,
    meta: {
      permissoes: ["Admin", "Trade", "Representante"],
    },
    children: [],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/download",
    name: "AppDownload",
    component: AppDownload,
  },
  {
    path: "/tabela-quiz-print",
    name: "Exibição de Tabela de Quiz",
    component: tabelaDeQuizPrint,
  },
  {
    path: "/tabela-tarefas-print",
    name: "Exibição de Tabela de Tarefas",
    component: tabelaDeAcoesPrint,
  },
];

routes[apps.BASE].children = routes[apps.BASE].children.concat(acessos);
routes[apps.BASE].children = routes[apps.BASE].children.concat(acoes);
routes[apps.BASE].children = routes[apps.BASE].children.concat(clientes);
routes[apps.BASE].children = routes[apps.BASE].children.concat(contratos);
routes[apps.BASE].children = routes[apps.BASE].children.concat(questoes);
routes[apps.BASE].children = routes[apps.BASE].children.concat(tarefas);
routes[apps.BASE].children = routes[apps.BASE].children.concat(usuarios);
routes[apps.BASE].children = routes[apps.BASE].children.concat(empresas);
routes[apps.BASE].children = routes[apps.BASE].children.concat(opcoes);
routes[apps.BASE].children = routes[apps.BASE].children.concat(relatorio);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name !== "Login" &&
    to.name !== "AppDownload" &&
    !store.getters.isAuthenticated
  ) {
    let query = {}
    if(!['Base', 'Home'].includes(to.name)){
      query['redirect'] = btoa(JSON.stringify({ name: to.name, query: to.query }));
    }
    next({
      name: "Login",
      query,
    });
  } else {
    if (
      to.meta.permissoes === undefined ||
      to.meta.permissoes.includes(store.getters.userType)
    ) {
      next();
    } else {
      store.dispatch("error", "Você não pode acessar essa area");
      next({ name: "Base" });
    }
  }
});

export default router;
