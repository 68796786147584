<template>
  <v-container>
    <v-card>
      <v-form @submit.prevent="submit" class="pa-4">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="senhaAtual" autofocus label="Senha Atual" outlined required
              class="obrigatorio" type="password" :error-messages="senhaAtualErrors"
              @input="$v.senhaAtual.$touch()" >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="senhaNova" label="Nova Senha" outlined required class="obrigatorio"
              :error-messages="senhaNovaErrors" :type="senhaStatus ? 'text' : 'password'"
              :append-icon="senhaStatus ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="senhaStatus = !senhaStatus" @input="$v.senhaNova.$touch()"
              >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="senhaConfirmacao" label="Confirmação da Nova Senha" outlined required
              class="obrigatorio" :error-messages="senhaConfirmacaoErrors"
              :type="senhaStatus ? 'text' : 'password'"
              :append-icon="senhaStatus ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="senhaStatus = !senhaStatus" @input="$v.senhaConfirmacao.$touch()"
              >
            </v-text-field>
          </v-col>
        </v-row>
        <v-col md=12 col=12>
          <small>
            <span class="legenda">
              Campo Obrigatório
            </span>
          </small>
        </v-col>
           <v-col md=12>
            <v-divider></v-divider>
          </v-col>
        <v-row>
          <v-col cols="12" md="6" class="d-none d-sm-flex">
            <router-link to= />
            <v-btn color='secondary' outlined>
              Voltar
            </v-btn>
            </router-link>
          </v-col>
          <v-col class="text-right">
            <v-btn @click="limpar" class="mr-4">
              Limpar
            </v-btn>
            <v-btn color="primary" type="button" @click="submit" :loading="$store.getters.loading"
              :disabled="$v.$invalid">
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<style>
  .obrigatorio label::after {
    content: "*";
    color: red
  }
</style>
<script>
import {
  validationMixin
} from 'vuelidate'
import usuarioService
  from '../../../services/usuarioService'
import {
  required,
  sameAs
} from 'vuelidate/lib/validators'
export default {
  mixins: [validationMixin],

  data: () => ({
    name: 'dakota-opcoes-form',
    senhaAtual: '',
    senhaNova: '',
    senhaConfirmacao: '',
    senhaStatus: false
  }),

  validations () {
    return {
      senhaAtual: {
        required
      },
      senhaNova: {
        required
      },
      senhaConfirmacao: {
        required,
        senhasConferem: sameAs('senhaNova')
      }
    }
  },
  computed: {
    senhaAtualErrors () {
      const errors = []
      if (!this.$v.senhaAtual.$dirty) {
        return errors
      } else {
        !this.$v.senhaAtual.required && errors.push('É necessário informar a senha')
        return errors
      }
    },

    senhaNovaErrors () {
      const errors = []
      if (!this.$v.senhaNova.$dirty) {
        return errors
      } else {
        !this.$v.senhaNova.required && errors.push('É necessário informar a nova senha')
        return errors
      }
    },

    senhaConfirmacaoErrors () {
      const errors = []
      if (!this.$v.senhaConfirmacao.$dirty) {
        return errors
      } else {
        !this.$v.senhaConfirmacao.required && errors.push('É necessário confirmar a senha')
        !this.$v.senhaConfirmacao.senhasConferem && errors.push('As senhas não conferem')
      }
      return errors
    }
  },
  methods: {
    submit () {
      const senha = {
        senha: this.senhaAtual,
        senha_nova: this.senhaNova,
        senha_confirmacao: this.senhaConfirmacao
      }
      this.$store.dispatch('start_load')
      usuarioService.API.Actions.trocarSenha(senha)
        .then((response) => {
          this.$store.dispatch('success', response.data.message)
          this.$store.dispatch('stop_load')
          this.$router.push({ name: 'Base' })
        }).catch((error) => {
          this.$store.dispatch('stop_load')
          const errorArray = error.response.data.errors
          for (const error in errorArray) {
            this.$store.dispatch('error', errorArray[error][0])
          }
        })
    },

    limpar () {
      this.$v.$reset()
      this.senhaAtual = ''
      this.senhaNova = ''
      this.senhaConfirmacao = ''
    }
  }
}
</script>
