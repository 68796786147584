import store from '../store/index'
import router from '../router/index'
const axios = require('axios')

var client = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

client.login = function () {
  this.defaults.headers.common.Authorization = store.getters.bearerToken
}

client.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      store.commit('logout')
      router.push('/login')

    }
    return Promise.reject(error)
  }
)

export default client
