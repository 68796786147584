import index from './views/index'
import form from './views/form'

export default [
  {
    path: 'empresas',
    name: 'Empresas',
    component: index,
    meta: {
      permissoes: ['Admin'],
      breadcrumbs: [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Base'
          }
        },
        {
          text: 'Empresa de Trade',
          exact: true,
          to: {
            name: 'Empresas'
          }
        }
      ]
    }
  },
  {
    path: 'empresas/formulario',
    name: 'empresasForm',
    component: form,
    meta: {
      permissoes: ['Admin'],
      breadcrumbs: [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Base'
          }
        },
        {
          text: 'Empresa de Trade',
          exact: true,
          to: {
            name: 'Empresas'
          }
        },
        {
          text: 'Cadastro de Empresas',
          exact: true,
          to: {
            name: 'empresasForm'
          }
        }
      ]
    }
  },
  {
    path: 'empresas/formulario/:id',
    name: 'empresasFormEdit',
    component: form,
    meta: {
      permissoes: ['Admin'],
      breadcrumbs: [
        {
          text: 'Home',
          exact: true,
          to: {
            name: 'Base'
          }
        },
        {
          text: 'Empresa de Trade',
          exact: true,
          to: {
            name: 'Empresas'
          }
        },
        {
          text: 'Edição de Empresa',
          exact: true,
          to: {
            name: 'empresasFormEdit'
          }
        }
      ]
    }
  }
]
