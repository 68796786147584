import Vue from 'vue'

Vue.filter('usuarioTipo', function (value) {
  if (value === false) {
    value = 'Inativo'
  } else {
    value = 'Ativo'
  }
  return value
})

export default Vue
