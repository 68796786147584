import client from '../plugins/apiClient'

export default {
  API: {
    Actions: {
      list: () => {
        client.login()
        return client.get('questoes')
      },
      options: () => {
        client.login()
        return client.get('questoes-options')
      },
      create: (objeto) => {
        client.login()
        return client.post('questao', objeto)
      },
      delete: (id) => {
        client.login()
        return client.delete('questao/' + id)
      },
      update: (id, objeto) => {
        client.login()
        return client.put('questao/' + id, objeto)
      },
      find: (id) => {
        client.login()
        return client.get('questao/' + id)
      }
    }
  }
}
